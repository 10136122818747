const doge = `
           _                _
          / /.           _-//
         / ///         _-   /
        //_-//=========     /
      _///        //_ ||   ./
    _|                 -__-||
   |  __              - \\\\   \\
  |  |#-       _-|_           |
  |            |#|||       _   |  
 |  _==_                       ||
- ==|.=.=|_ =                  |
|  |-|-  ___                  |
|    --__   _                /
||     ===                  |
 |                     _. //
  ||_         __-   _-  _|
     \\_______/  ___/  _|
                   --*
`
const tiger = [
"       (\"`-'  '-/\") .___..--' ' \"`-._          ",
"         ` *_ *  )    `-.   (      ) .`-.__. `)",
"         (_Y_.) ' ._   )   `._` ;  `` -. .-'   ",
"      _.. `--'_..-_/   /--' _ .' ,4            ",
"   ( i l ),-''  ( l i),'  ( ( ! .-'            ",
"",
].join("\n")

export {
  doge,
  tiger,
}
