[[
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!::::::::::::::::!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::::::!!..::::!!!!!!!!!!!!!!++++!!!!!!!!::::::!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::::::::::::::++::..::!!!!!!!!!!++++++++!!!!!!!!!!::!!::!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::++!!..::::::::......!!!!++++++!!++!!!!::!!!!",
"!!++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::::::::::::::::++**....::..          ..!!!!!!!!++++!!!!!!!!",
"++++++!!++!!++++++!!!!!!!!!!!!!!!!!!!!::::::!!::::::::::::::::!!$$!!..::..            ..!!++++++++!!!!!!!!",
"!!++++!!!!!!++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::::::**$$........            !!++++++++++!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::..::$$**......::....      ::++++++++++++++!!",
"++++++++!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::....ee$$++  ....::::::::::::!!++++++++++!!!!",
"++!!++++++++!!!!++!!!!!!!!!!!!!!!!!!!!!!::::::::......::::::::..  ++$$$$!!..::::::::!!!!!!!!!!++++++++!!::",
"++!!++!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!::::::......  ..::::::::..  !!$$$$**..::::::::!!!!!!!!!!++++++!!!!::",
"!!++++++++++++++!!!!!!!!!!!!!!!!!!!!::::....        ..::::::::....!!$$$$++....::::!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::      ..::::..::::..::..::!!$$$$......::::::::::::::!!::::::::::::",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::..::......      ..  ......!!ee**  ......::::::::::::::::::::::::::",
"++!!++++++++!!++!!++!!!!!!!!!!!!!!!!!!::::::::::::              ....::::..........::::::::::::::::!!::::::",
"++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::..            ..............::::::::::::::::::::::::::",
"++++!!++!!++!!++!!++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::..        ......::......::::::::::::::::!!::!!::!!",
"!!!!!!++!!++++++!!++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::..    ........::::::::::::::::::::::::::::::::!!",
"!!++!!++!!++++++!!!!!!++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::..    ......::::::::::::::::::::::!!::::::!!!!",
"!!!!!!++++!!++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::!!!!::::..    ......::::::::::::::!!::::!!!!::!!!!!!",
"!!!!++!!++++!!++++++++!!++!!!!!!!!!!!!!!!!!!++!!!!::::::!!!!!!::..    ....::::::::::::::::::::!!!!!!!!!!!!",
"!!!!!!++++++++++++++++!!++!!!!!!!!!!!!!!!!!!++++!!!!::::::!!!!!!::........::::::::::::::::!!!!!!::::!!!!!!",
"++++++++++++++++++!!!!++!!++!!!!!!!!!!!!!!++!!++++!!!!::::::!!!!!!::......::::::::::!!::!!!!::!!!!::!!!!!!",
"++++++++++++++++++++++!!!!!!++!!++!!!!!!!!++++++++++!!::::::!!!!!!!!::....::::::::::!!::!!!!!!!!!!!!!!!!!!",
"++++!!++++++++++++++++++++++++++!!!!!!++!!!!++++++++!!!!!!::!!!!!!!!!!::....::!!::::!!::::!!::!!!!!!!!!!!!",
"++!!++++++++++++++++++++++++++!!!!!!!!++!!++!!++++++++!!!!!!::!!!!!!!!!!....::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++!!++++++++++++++++++++!!++++!!!!!!++++++++++++!!!!!!!!!!!!!!!!::..!!!!!!!!!!!!::::::!!!!!!!!!!!!",
"!!++++++++++++++++++++++++++++!!!!!!++++!!!!!!++++++++++++!!!!!!!!!!!!!!!!..!!!!!!!!!!::::::::!!!!!!!!!!!!",
"++++++!!++++++++++++++++++++++++!!++!!!!!!!!!!++++++**++++!!!!!!::!!!!!!!!::::!!!!!!!!!!::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!++!!!!!!!!++++++++++++!!!!!!!!!!!!!!::::!!!!!!!!!!::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++++!!++++!!!!!!!!!!++++**++++!!!!!!!!!!!!!!!!::!!!!!!!!::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!++!!!!!!!!!!++++++++++!!!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!++!!!!!!!!!!!!!!::::!!++++++++++!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!++!!++!!!!!!!!!!!!!!!!!!!!::::++++++!!!!!!!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++!!!!!!!!!!!!!!!!!!::..!!++++!!!!!!!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!++!!!!!!!!!!!!!!!!::  ..!!++!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!..  ..!!!!!!!!::::!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++!!++!!!!!!!!!!!!!!!!::    ::!!!!!!::..::!!::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::    ::!!!!..    ....::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!::..  ..::..          ::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::                ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!..    ..........::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::....::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++**++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"************++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++++++++++++++**++++++++++++++++++++++!!!!!!::::::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++**********++++++++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********************++**++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********************************++++++++++++++++!!++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"**++++**++**++++******************++++++++++++++++++++++++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++**********************++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"****++******************++********++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++**********++++++++++********++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::::!!!!!!..::!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::::!!++++..::::!!!!!!!!!!!!!!++!!!!!!!!!!::::::!!",
"++++++++++++++++!!!!++++!!++!!!!!!!!!!!!::::::::::::::::::::!!**ee....::!!!!!!!!!!!!++!!!!!!++!!!!!!!!::!!",
"++++++!!++++++++!!!!++!!!!!!!!!!!!!!!!!!::::::::::::::::::::!!ee$$!!..::::..........::!!!!++++++!!!!!!!!!!",
"++++++++++!!!!++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::**$$**..::..            ..!!++++++++++!!!!!!",
"!!!!!!++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::::!!$$$$::....              ::++++++++++!!!!!!",
"++!!!!!!++!!++++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::::**$$ee..........          !!++++++++++!!!!",
"!!++++!!!!!!++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::....!!$$$$++  ............    ::++++++++++++!!",
"++!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::..::::::::::::..ee$$@@!!  ....::::::::::::!!++++++++++!!",
"++!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::....::::::::::....**$$$$$$::....::::::!!!!!!!!++++++++++!!",
"!!!!++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::....      ::::::::::....++$$$$$$++..::::::!!!!!!!!!!++++++++!!::",
"!!!!++!!!!++++!!++!!!!!!!!!!!!!!!!!!!!::..      ..::::!!!!!!::::..++$$$$$$::..::::::!!!!!!!!!!!!!!!!!!::::",
"!!++++++++++++++!!!!!!!!!!!!!!!!!!!!::::........::....::::....::::++$$@@ee........::::::::::::::::::!!::::",
"!!!!++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::          ........!!++!!........::::::::::::::::::::::::",
"++!!!!++++++!!!!!!!!!!!!!!!!!!!!!!!!::!!::::::::::::..          ......::..::::....::::::::::::::::::::::::",
"++!!++++++++!!++!!++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::..        ......::::::::..::::::::::::::::!!::::::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::..      ......::::::::::::::::::::::::::::::::::",
"++!!++++++++!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::..    ........::::::::::::::::::::::!!::::::!!",
"++!!!!++++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::..    ....::::::::::::::::::::::::::::::!!",
"!!!!!!!!++++++++++!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!::::........::::::::::::::::::::!!::::::!!!!",
"!!!!++!!!!!!++++!!++!!++!!!!!!!!!!!!!!!!!!!!!!++!!!!::::::::!!!!::::......::::::::::::::::::::!!!!::!!!!!!",
"!!!!++!!++++++++++!!++!!++!!!!!!!!!!!!!!!!!!++++++!!!!::::::::!!!!::::......::::::::::::::::::::!!!!!!!!!!",
"!!!!!!++!!++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++++!!::::::!!::!!!!::::....::::::::::::!!!!::!!!!::!!!!!!",
"++++!!!!++++++++++!!!!++!!++!!!!++!!!!!!!!!!++++++++++!!!!::!!!!!!!!!!::....::!!!!::::::!!!!!!!!!!!!!!!!!!",
"++++!!!!++++++++++++++!!++!!++!!++!!!!!!!!!!!!++++++++++!!::!!!!::!!!!!!::..::!!!!!!!!::!!!!::::!!!!!!!!!!",
"++!!++!!++++++++++++++++++!!++++!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!::!!!!!!..::!!!!!!!!::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!++++++++++!!!!!!::::!!!!!!::::!!!!!!!!!!!!::!!!!!!!!!!!!!!",
"++++++++++!!++++++++++++++++++!!++++!!!!!!!!!!!!++++++++++++!!!!!!::!!!!!!::::!!!!!!!!!!!!::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!++!!!!!!!!!!!!++++++++++!!!!!!!!!!!!!!!!::!!!!!!!!!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++!!++!!!!!!++++!!!!++!!!!!!!!!!++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!!!!!::!!!!!!!!!!::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!::!!++++++!!++!!!!!!!!!!!!::!!!!!!!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!::::::++++++++!!!!!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::..::++++++!!!!!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!++!!!!!!!!!!!!!!!!::....!!++++!!!!!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::  ..!!!!!!!!!!::!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!::..  ..!!!!!!!!..::!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!::    ..!!!!::    ..::..::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::    ::::..          ::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::..                ..::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::      ..  ......::::!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..  ..::::::..::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!::::::....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++++++++**++++++++++++++++!!++++++!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++++++**++++++****++++++++++++++++++++!!!!!!!!!!!!!!::::::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"**************++++**++******++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++**++++****++++++++++++++++++++!!++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++**********************++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++****++********************++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++****++****++++****************++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"****++**++****++++++**++**++**********++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++****++++++++++++**++++++**++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"**********++**********++++++++++**++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++**++++**++++++**++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++!!!!!!!!!!!!::!!::::::::::::::!!++**ee++..::!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::",
"++++++++++++++++++!!++++!!++!!!!!!!!!!!!::::::::::::::::::::++ee$$ee..::!!!!!!!!!!!!++++!!!!!!!!!!!!::::::",
"++++++++++++++++!!!!!!!!!!++!!!!!!!!!!!!::::::::::::::::::::++$$@@$$::::!!!!!!!!::::!!!!!!!!++++++!!!!!!!!",
"!!++++!!++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::!!$$$$@@!!..::::          ..!!++!!++++++!!!!!!",
"++++++++++!!!!++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::::**$$$$ee..::..              !!++++++++++!!!!",
"!!!!!!++!!++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::::!!$$$$$$!!..::..            ..++++++++++!!!!",
"++!!!!!!++!!++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::::**$$$$ee............        !!++++++++!!!!",
"!!++++!!!!!!++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::::::!!$$$$$$ee........::::::..  ::!!++++++++++",
"++!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::......::!!!!::::::::$$$$@@@@++  ..::::::::::::!!!!++++++++!!",
"++!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::....  ..::!!!!::::::::::$$$$$$$$$$....::::::::!!!!!!!!++++++++!!",
"!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::..    ..::!!!!!!!!!!::::::$$$$$$$$$$....::::::!!!!!!!!!!!!!!!!!!::",
"!!!!!!!!!!++++!!++++!!!!!!!!!!!!!!!!::........::::..::::!!::::!!!!$$@@@@$$ee......::::::::::::::::::!!::::",
"!!++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::!!::..  ......!!!!::!!++**ee!!  ......::::::::::::::::::::::",
"!!!!++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::!!::    ..............::..........::::::::::::::::::::::",
"!!!!++!!++++!!!!!!!!!!!!!!!!!!!!!!!!::!!::::::::::::::::..      ........::::......::::::::::::::::::::::::",
"++!!++++++++!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::..        ......::::::::::::::::::::::::::::::::",
"!!!!++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::..    ........::::::::::::::::::::::::::::::::",
"!!!!++++!!!!!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!::::....  ......::::::::::::::::::::::::::::!!",
"++!!++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!::::..    ....::::::::::::::::::::::::::::!!",
"++!!++++++++++++++!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!!!::........::::::::::::::::::!!::::::::!!",
"!!!!++!!!!!!++++!!++!!++!!!!!!!!!!!!!!!!!!!!!!++!!!!!!::::::::!!!!!!::......::::::::::::::::::!!!!::!!!!!!",
"!!!!++!!++!!++++!!!!++!!++!!!!!!!!!!!!!!!!!!++++++++!!!!::::!!::!!!!::::....::::::::::::::::::::::!!!!!!!!",
"!!++!!++!!++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!::::!!!!!!::..::::::::::::!!!!::!!::!!!!!!!!",
"++!!++!!++++++++++++!!++!!++!!!!++!!!!!!!!!!!!++++++++++!!!!!!!!::::!!!!::..::!!!!!!!!::!!!!!!::!!!!!!!!!!",
"!!++!!++++++++++++++++!!++!!++!!++!!!!!!!!!!!!++++++++++++!!!!!!::!!!!!!!!....!!!!!!!!!!!!!!::::!!!!!!!!!!",
"++++++++++++++++++++++!!++!!++++!!!!!!!!!!!!!!!!++++++++++++!!!!::::::!!!!::::!!!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++!!++++++++!!++!!!!!!!!!!!!!!!!++++++++++!!!!::::!!!!!!!!::!!!!!!!!!!::::!!::!!!!!!!!!!",
"++++++++++!!++++++++++++++++++!!++++!!!!!!!!!!!!!!++++++++++!!!!!!::!!!!!!!!::!!!!!!!!!!!!::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!++!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!!!!!::!!!!!!!!!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++!!++!!!!!!++++!!!!++!!!!!!!!!!!!++++++++!!!!!!!!!!!!!!!!::!!!!!!!!!!::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::!!++++++++!!!!!!!!++!!!!::!!!!!!!!::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++++!!!!!!!!!!!!!!!!!!::::::++++++++!!!!!!!!++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!++++!!++!!!!!!!!!!!!!!!!!!!!!!::....!!++++!!!!!!::!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!::::  ..!!++!!!!!!::!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!++!!!!!!!!!!!!!!!!!!::    ..!!!!!!!!::::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::    ..!!!!!!..  ::::..::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!::    ..::..          ..!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!..                  ::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::          ..    ::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::..  ..........::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::!!::....::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"******++**++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++******++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++++++**++**++++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++****************++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"**++******************************++++++++++++++++++++++!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++**++++********++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++******++******************++++++++++++++++++!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++******++++****************++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++**++****++++++****++********++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"********++****++++++++++++++**++++****++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++++++++++++++++++++**++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"********************++++++++++++**++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++**++++++++++++++**++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++!!!!++!!!!!!!!!!!!::!!::::::::::::::++ee$$$$$$++::!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++!!++++!!++!!!!!!!!!!!!::::::::::::::::::++ee@@$$@@**::!!!!!!!!!!!!++++!!!!!!!!!!!!::::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::!!ee@@@@@@ee..::!!!!!!!!!!!!!!!!!!++++++!!!!!!!!",
"!!++++!!++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::!!**$$$$$$$$::::!!::..  ......!!!!++++++++!!!!!!",
"++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::!!ee$$$$$$!!..::::            ::++++++++++!!!!",
"!!!!!!++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::::++$$$$$$ee..::::              !!++++++++!!!!",
"++!!!!!!++!!++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::!!$$$$$$@@++..::......        ..++++++++!!++",
"!!++++!!!!!!++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!::ee@@@@$$$$::......::::::..    !!++++++++++",
"!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::..::!!!!++++!!!!::::**@@@@@@@@$$::..::::::::::::::!!++++++++++",
"++!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::..::!!++**++!!!!!!!!::++@@@@$$@@@@ee....::::::!!!!!!!!!!++++++!!",
"!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::..  ..::!!++++++++++++!!!!**@@@@@@$$@@$$....::::::::!!!!!!!!!!!!!!::",
"!!!!!!!!!!++++!!++!!!!!!!!!!!!!!!!!!::....::::::..  ..::::!!++++$$@@@@@@@@@@**....::::::::::::::::::!!::::",
"!!++++++!!++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::..    ..!!++ee$$ee**ee$$$$::......::::::::::::::::::::::",
"!!!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::....::**!!........::::::......::::::::::::::::::::::",
"!!!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!::!!::::::::::::::::::!!::........  ..::::....::::::::::::::::::::::::",
"++!!++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::..    ........::::::::::::::::::::::::::::::::",
"!!!!++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::..    ......::::::::::::::::::::::::::::::::",
"!!++++++++!!!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!!!::....  ......::::::::::::::::::::::::::::!!",
"!!!!!!++++++++++!!++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!!!::::    ....::::::::::::::::::::::::::::!!",
"!!!!!!++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!!!++!!::::::::::!!!!::::......::::::::::::::::::::::::::::!!",
"!!!!!!!!!!!!++++!!++!!++!!!!!!!!!!!!!!!!!!!!!!++++!!!!::::::::::!!!!::......::::::::::::::::::!!!!::::!!!!",
"!!!!++!!++!!++++++!!++++++!!!!!!!!!!!!!!!!!!++++++++++!!::::!!::::!!!!::....::::::::::::::::::::::!!!!!!!!",
"!!++!!++!!++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++++++++!!!!!!::::::!!!!::..::::!!::::::!!::::::!!!!!!!!!!",
"++!!!!!!++++++++++++!!++!!++!!!!++!!!!!!!!!!!!++++++++++!!!!!!!!::::!!!!::..::!!!!!!!!::::!!!!::::!!!!!!!!",
"!!++!!++++++++++++++++!!++!!++!!++!!!!!!!!!!!!!!++++++++++!!!!!!::::!!!!!!....::!!!!!!!!!!::::::!!!!!!!!!!",
"++++!!++++++++++++++++!!++!!++++!!!!!!!!!!!!!!!!++++++++++++!!!!::::::!!!!::::::!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!++!!!!!!!!!!++++++++++!!!!!!::!!!!!!::::!!!!!!!!!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!!!!!!!::!!!!!!!!!!!!::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!++!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!++!!::!!!!!!!!!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++!!++!!!!!!++++!!!!!!!!!!!!!!!!!!!!++++**++!!!!!!!!++!!::::!!!!!!!!!!::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::!!++++++!!!!!!!!++!!::::!!!!!!!!::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!::..::!!++++++!!!!!!!!++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!::..  ::!!++!!!!!!::!!++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!::    ..!!!!!!!!::!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!++!!!!!!!!!!!!!!!!!!::..    ..!!!!::  ..::::..::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::..    ..::..          ::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!++!!!!!!!!!!!!!!!!!!::                    ::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::      ............::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::..  ....::::....::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++****++**++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!::!!::....::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++**++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++!!!!++++!!!!!!!!::..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++********++++++++++++++++++++++++!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********************************++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"**********************************++++++++++++++++++++++!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++**++************++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++******++******++******************++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++******++++**++****++********++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++**++****++++++++++++++++**++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++****++++++++++++++++++++++**++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++++++++++++++++++++**++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"********************++++++++++++**++++++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++++++++++++++**++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::!!::::::::::::!!**$$$$$$$$$$::::!!!!!!!!!!!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++!!++!!!!++!!!!!!!!!!!!::::::::::::::::!!**$$$$$$@@$$::!!!!!!!!!!!!++++!!!!!!!!!!!!!!::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::++$$$$@@@@@@!!::!!!!!!!!!!!!!!!!++++++++!!!!!!!!",
"!!++++!!++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::!!ee$$$$@@$$++::!!!!!!::::::::::!!++++++++!!!!!!",
"++++++++++!!!!++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::++$$$$$$@@ee..::::..          ::++++++++++!!!!",
"!!!!!!++!!++!!++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::!!!!!!ee@@@@@@$$::::::              ::++++++++++!!",
"++!!!!!!!!!!++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!**$$$$@@@@**..::::              !!++++++!!++",
"!!++!!!!!!!!++++++!!!!!!!!!!!!!!!!!!!!::::::::::!!++++++!!!!!!++$$@@@@@@$$!!..::::......      ::++++++++++",
"!!!!++++!!++!!!!!!!!!!!!!!!!!!!!!!::::::::::::++******++++!!!!!!$$@@@@@@@@@@!!..::::::::::....::++++++++++",
"!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::....!!******++++**++!!++$$@@@@$$@@$$$$::..::::::!!!!!!!!!!++++++++",
"!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::    ::!!!!::!!!!++!!!!++**$$$$@@@@$$$$$$++..::::::!!!!!!!!!!++++!!!!",
"!!!!!!!!!!++++!!++!!!!!!!!!!!!!!!!!!::..::::::::..  ..::::++++ee$$@@@@@@$$@@$$::..::::::::::::::::!!!!::::",
"!!++!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::......!!**$$@@$$eeee$$@@ee......::::::::::::::::::::::",
"!!!!++!!++!!++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::++eeee!!......::++::......::::::::::::::::::::::",
"++!!!!!!++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!++..        ..::::....::::::::::::::::::::::::",
"++!!++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::..    ........::::::::::::::::::::::::::::::",
"!!!!++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::..  ........::::::::::::::::::::::::::::::",
"!!++!!++++!!++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!::::..    ....::::::::::::::::::::::!!::::!!",
"!!!!!!++++++++++!!++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!!!!!::..  ....::::::::::::::::::::::::::::!!",
"!!!!!!++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!!!++!!!!::::::::::!!!!::......::::::::::::::::::::::::::::!!",
"!!!!!!!!!!!!++++!!++!!++!!!!!!!!!!!!!!!!!!!!!!++!!++!!!!::::::::!!!!!!........::::::::::::::::::::::::!!!!",
"!!!!++!!!!!!++++++!!++++!!!!!!!!!!!!!!!!!!!!++++++++++!!!!::!!::::!!!!::....::::::::::::::::::::::!!!!!!!!",
"!!++!!++!!++++++++++!!++++!!!!!!!!!!!!!!!!!!!!++++++++++!!!!!!::::::!!!!::..::::!!::::::::::::::::::::!!!!",
"++!!++!!++++++++++!!!!++!!++!!!!++!!!!!!!!!!!!++++++++++++!!!!!!::::!!!!::..::::!!!!!!::::!!!!::::!!!!!!!!",
"!!++!!++++++++++++++++!!++!!++!!++!!!!!!!!!!!!!!++++++++++!!!!!!::::!!!!!!..::::!!!!!!!!!!::::::::::!!!!!!",
"++++!!++++++++++++!!++!!++!!++++!!!!!!!!!!!!!!!!!!++++**++++!!!!::::::!!!!::::::!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++!!++++++++!!!!!!++!!!!!!!!!!!!++++++++!!!!!!::!!!!!!::::::!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!!!!!::::!!!!!!!!!!::::::::!!!!!!!!",
"++++++++++++++++++++!!++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++**++++!!!!!!!!!!!!::::!!!!!!!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++!!++++!!!!!!++!!!!!!!!!!!!!!!!::::!!++++++!!!!!!!!++!!::!!!!!!!!!!!!::::!!!!!!!!!!!!",
"++++++++++++++++++++++!!++++++++!!!!!!!!!!!!!!!!!!!!::..::!!++++!!!!!!!!++!!::::!!!!!!!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!::..  ::!!++!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::    ::!!!!!!!!::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!..    ::!!!!!!..!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!++!!!!!!!!!!!!!!!!!!!!::      ::!!..    ....  ::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::                    ..::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::      ....::....  ::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::..  ......::....::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::....::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**********++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::....::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++**++++++++++++++++++++++++!!++!!!!!!!!!!!!!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++!!!!++++!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++**++++****++++++++++++++++++++!!!!!!++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++********************++++**++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++****************************++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"**++********************************++++**++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************++****++************++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++******++******++**++**************++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++******++++**++++++++++******++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++**++**++****++++++++++++++++++++**++**++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++++++++++++++++**++++**++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++++++++++++++++++++**++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"********************++++++++++++**++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++++++++++++++**++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++!!!!!!++!!!!!!!!!!!!!!::!!::::::::::!!**$$$$@@@@@@@@ee::!!!!!!!!!!!!!!!!!!!!!!!!::::::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::::::::::++$$$$@@@@@@@@ee::!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++!!++++!!++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::!!**$$@@@@@@@@ee::::!!!!!!++!!++!!++++++++!!!!!!!!",
"!!++++!!++++++++!!++!!!!!!!!!!!!!!!!!!::::::::::::::::::::!!ee@@@@@@@@$$::::!!!!!!!!!!!!!!++++++++++++!!!!",
"!!++++++++!!!!++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!!!!!**$$$$@@@@$$::::!!!!::........::!!++++++++!!!!",
"!!!!!!++!!++!!++!!!!!!!!!!!!!!!!!!!!!!::::::::::::!!!!++++!!++$$@@@@@@@@**::!!::            ::++++++++++!!",
"++!!!!!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::!!++****++++++!!$$$$$$@@@@$$::::::              !!++++++++++",
"!!++!!!!!!!!++++!!!!!!!!!!!!!!!!!!!!::::::!!++******++++++!!!!ee@@@@@@@@@@**..::::..          ..!!++++++++",
"!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::....**eeeeee**++****++!!$$@@@@@@@@@@@@++..::::::::..      !!++++++++",
"!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::......!!++!!!!++++++++++**$$@@@@@@$$@@@@@@++..::::::::::::::!!++++++++",
"!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::..::::!!::  ..::::++++**$$@@@@@@@@@@@@@@$$::::::::!!!!!!!!!!!!++++++",
"!!!!!!!!!!++++!!++!!!!!!!!!!!!!!!!!!::::::::::!!::....::!!**$$$$@@$$@@@@@@@@@@$$::::::::::::!!!!!!!!!!!!!!",
"!!++!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::!!ee$$@@@@@@@@@@@@@@@@++....::::::::::::::::::::::",
"!!!!++!!++!!++++!!!!!!!!!!!!!!!!!!!!::!!::::::::::::::::!!ee$$$$$$++!!!!++eeee::....::::::::::::::::::::::",
"++!!!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!eeee::........::::......::::::::::::::::::::::",
"++!!++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::!!::    ........::::::::::::::::::::::::::::::",
"!!!!++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!::::............::::::::::::::::::::::::::::::",
"!!++!!++++!!++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!::::..  ......::::::::::::::::::::::::::::!!",
"!!!!!!++++++++++!!++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!::::..  ....::::::::::::::::::::::::::::!!",
"!!!!!!++!!++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!!!++!!!!::::::::::!!::::......::::::::::::::::::::::::::::!!",
"!!!!!!!!!!!!++++!!++!!++!!!!!!!!!!!!!!!!!!!!!!++++++++!!::::!!::::!!!!..  ....::::::::::::::::::::::::::::",
"!!!!++!!++!!++++++!!++++++!!!!!!!!!!!!!!!!!!!!!!++++++!!!!::!!::::!!!!::....::::::::::::::::::::::::!!!!!!",
"!!++!!++!!++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++++++++!!!!!!::::::!!!!::..::::::::::::::::::::::::!!!!!!",
"++!!++!!++++++++++++!!++!!++++!!++!!!!!!!!!!!!!!++++++++++!!!!!!::::!!!!::..::::!!!!::::::::::::::::!!!!!!",
"++++++++++++++++++++++!!++++++++++!!!!!!!!!!!!!!!!++++++++++!!!!::::!!!!!!..::::!!!!!!!!::::::::::::!!!!!!",
"++++!!++++++++++++!!++!!!!!!++++!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!::::!!!!..::::!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!!!..::::!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!++++++!!!!!!!!!!!!::::::!!!!!!!!::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::++++++++!!!!!!!!!!::::::!!!!!!!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!!!::..::++++++!!!!!!!!++::::::!!!!!!!!!!::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::....::++++!!!!!!!!++::::::!!!!!!!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..  ..::!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!::::    ..::!!!!!!::!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!::::      ::::::..::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!++!!!!!!!!!!!!!!!!!!!!!!::::      ..            ::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::!!::      ........    ::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!::..  ..............::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::....::::......::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::....::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!",
"**++++**++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++****++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++************++++++++++++++++++++++++++++++++!!!!++++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************************++++++++++++++++++++++!!!!++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************************++++****++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++********************************++++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"**++**********************************++**++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************++****++************++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++******++****++**************++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++******++++++++++++++++++****++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++****++++++++++++++++++++++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++++++++++++++++**++++**++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++++++++++++++**++++**++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"********************++++++++++++**++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++++++++++++++++++++++++++++++++++++++!!::..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++!!!!!!++!!!!!!!!!!::::::!!::::::::::!!**$$$$@@@@@@@@@@!!::!!!!!!!!!!!!!!!!!!::!!::::::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::!!ee$$@@@@@@@@$$::::!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++!!++++!!++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::!!**$$@@@@@@@@@@!!::!!!!!!!!++++++!!++!!!!!!!!!!::",
"!!++++!!++++++++!!++!!!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!++$$@@@@@@@@@@!!::!!!!!!!!!!!!++++++++++++!!!!!!",
"!!++++++++!!!!++++++!!!!!!!!!!!!!!!!!!::::::::!!!!++++++++!!ee@@$$@@@@@@++::!!!!!!!!::::::!!!!++++++++++!!",
"!!!!!!++!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!::::!!++******++++!!**$$$$@@@@@@ee::::!!::        ..::!!++++++++!!",
"++!!!!!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!::!!++eeeeee******++!!**@@@@@@@@@@@@++::::..            ::++++++++++",
"!!++!!!!!!!!++++!!!!!!!!!!!!!!!!!!::::..!!eeeeeeee******++++ee@@@@@@@@@@@@$$::::::..          ..!!++++++++",
"!!!!++++++!!!!!!!!!!!!!!!!!!!!!!!!!!....::!!++::!!++++++++**$$$$@@@@@@@@@@@@**!!::::....        ::++++++++",
"!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::..::::::::..  ..::!!++ee$$$$@@@@@@$$@@@@@@**::::::::::....  ::!!++++++",
"!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::....!!**ee$$$$@@@@@@@@@@@@@@@@++::::::::::::::!!!!!!++++",
"!!!!!!!!!!++++!!++!!!!!!!!!!!!!!!!!!::::::::::::::::::!!**$$@@$$$$@@@@@@@@@@@@@@**..::::::!!!!!!!!!!!!!!!!",
"!!++!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!++$$$$@@$$@@@@@@@@@@@@@@!!..::::::::::::::::::::::",
"!!!!++!!++!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::**$$@@@@ee++!!**ee@@**....::::::::::::::::::::::",
"++!!!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::++ee!!........::!!::....::::::::::::::::::::::",
"++!!!!++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::....  ......::::::......::::::::::::::::::",
"!!!!++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::!!::::..........::::::::....::::::::::::::::::",
"!!++!!++++!!++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!++!!::::::::::::::::::..........::::::::::::::::::::::::::::",
"++!!++++++++++++!!!!++++++!!!!!!!!!!!!!!!!!!!!++!!!!!!::::::::::::::........::::::::::::::::::::::::::::::",
"!!!!!!++!!++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!!!++++!!!!::::::::::::::......::::::::::::::::::::::::::::::",
"++!!++!!!!!!++++++++!!++!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!::!!::::::!!::  ....::::::::::::::::::::::::::::",
"!!!!++!!++!!++++++!!++!!++!!!!!!!!!!!!!!!!!!!!++++++++++!!::::::::::!!!!......::::::::::::::::::::::::!!!!",
"!!++!!++!!!!++++++++++++++!!!!!!!!!!!!!!!!!!!!++++++++++++!!!!::::::!!!!::....::::::::::::::::::::::::!!!!",
"++!!++!!++++++++++++!!++!!!!++!!++!!!!!!!!!!!!!!!!++++++++++!!!!::::!!!!::..::::!!!!::::::::::::::::!!!!!!",
"!!++++++++++++++++++++!!!!!!++++++!!!!!!!!!!!!!!!!!!++++++++!!!!::::::!!!!..::::!!!!!!::::::::::::::!!!!!!",
"++++!!++++++++++++!!++!!!!!!++++!!!!!!!!!!!!!!!!!!!!++++++++++!!!!::::!!!!..::::!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!!!..::::!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::++++++++!!!!!!!!!!::::::!!!!!!!!::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::..::++++++!!!!!!!!!!::::::!!!!!!!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++!!++++!!!!++!!!!!!!!!!!!!!!!!!::....::!!++!!!!::!!!!::::::!!!!!!!!::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::  ..::!!!!!!::!!!!::::::!!!!!!!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::    ..::::!!::!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!::::    ..::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!::::      ::..  ......::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::      ......      ..::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::..  ..........    ..::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::....::::::......::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::....::::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::..::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!",
"********++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++****++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**********++++++++++++++**++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++************++++++++++++++++++++!!++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************************************++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++************************************++**++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"************************************++****++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++++******++****++**************++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++**++******++****++++****++******++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++******++++++++++++++**++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++****++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"************++++++++++++++++++**++++**++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++++++++++**++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"**********************++++++++++**++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++++++++++++++++++++++++++++++++++++++!!!!..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::!!::::::::!!::**$$$$@@@@@@@@@@++::!!!!!!!!!!!!!!!!::::::::::::::",
"++++++++++++++++++++!!!!!!!!!!!!!!::!!::::::::::::::!!!!!!ee$$@@@@@@@@@@!!::!!!!!!!!!!!!!!!!!!!!!!::::::::",
"++!!++++!!++++++!!!!!!!!!!!!!!!!!!!!::::::::::!!::!!!!!!!!**$$@@@@@@@@@@!!::!!!!!!++!!!!++++!!!!!!!!!!!!::",
"!!++++!!++++++++!!++!!!!!!!!!!!!!!!!!!::::::::!!!!++++++++++$$@@@@@@@@@@!!::!!!!!!!!!!!!++++++++++!!!!!!!!",
"!!++++++++!!!!++++++!!!!!!!!!!!!!!!!!!::::::!!++****++++++++$$$$@@@@@@@@++::!!!!!!!!!!!!!!++++++++++++!!!!",
"!!!!!!++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::!!++**eeee******++**$$@@@@@@@@@@**::!!!!!!......::!!++++++++++++!!",
"++!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!::::::**$$eeeeee******++ee@@@@@@@@@@@@$$!!!!::..          ::!!++++++++!!",
"!!++!!!!!!!!++++!!!!!!!!!!!!!!!!!!::....!!++!!::!!++!!++**$$@@@@@@@@@@@@@@ee!!::..            ..!!++++++++",
"!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!::..::!!!!..    ::!!++**$$@@$$@@@@@@@@@@@@**::::..            !!++++++++",
"!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!::....::**ee$$@@@@@@@@@@$$@@@@$$++::::::::..      ::!!++++++",
"!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::**ee@@$$@@@@@@@@@@@@@@@@$$!!..::::::::::..::!!++++++",
"!!!!!!!!!!++++!!++!!!!!!!!!!!!!!!!!!::::::::::::::::::!!ee$$@@$$@@@@@@@@@@@@@@@@**..::::::!!!!!!!!!!!!!!!!",
"!!++!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!ee@@@@@@@@@@@@@@@@@@@@++..::::::::::::::::::::::",
"!!!!++!!++!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::**$$@@$$**++**$$@@ee::....::::::::::::::::::::",
"++!!!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::!!::::++**......::!!**!!......::::::::::::::::::::",
"++!!!!++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::..  ......::......::..::::::::::::::::::",
"!!!!++++++++!!!!!!!!!!!!!!++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::........::::::::::..::::::::::::::::::",
"!!++!!++++!!++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!++!!::::::::::::::::::........::::::::..::::::::::::::::::::",
"++!!++++++++++++!!++++++++!!!!!!!!!!!!!!!!!!!!++++!!!!::::::::::::::::......::::::::::::::::::::::::::::::",
"!!!!!!++!!++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!!!++++!!!!::::::::::::!!......::::::::::::::::::::::::::::::",
"++!!++!!!!!!++++++++!!++!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!::!!::::::!!::......::::::::::::::::::::::::::::",
"!!!!++!!++!!++++++!!++++!!!!!!!!++!!!!!!!!!!!!++++++++++!!!!!!::::::!!!!......::::::::::::::::::::::::!!!!",
"++++!!++!!!!++++++++++++++!!!!!!++!!!!!!!!!!!!++++++++++++!!!!::::::!!!!::....::::::::::::::::::::::::!!!!",
"++!!++!!++++++++++++!!++!!!!++!!++!!!!!!!!!!!!!!!!++++++++++!!!!::::!!!!::..::::!!::::::::::::::::::!!!!!!",
"!!++++++++++++++++++++!!!!++++++++!!!!!!!!!!!!!!!!!!++++++++!!!!::::::!!!!..::::!!!!!!::::::::::::::!!!!!!",
"++++++++++++++++++!!++!!!!++++++!!!!!!!!!!!!!!!!!!!!!!++**++++!!!!::::!!!!..::::!!!!!!::::::::::::::!!!!!!",
"++++++++++++++++++++++++!!!!++++!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!!!..::!!!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::!!++++++!!!!!!!!!!..::::!!!!!!!!::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::....!!++++!!!!!!!!::::::::!!!!!!!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++!!++++!!!!++!!!!!!!!!!!!!!!!!!::..  ..!!!!!!!!::!!::::::::!!!!!!::!!::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::    ..::!!!!::!!::::::::!!!!!!!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..    ..::::::!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::..    ..::....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!::..      ..........::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::!!::      ......      ..::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::  ....::....  ..::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::....::::::..::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::....::::::::::::::!!!!::!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!::::..::::::::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!",
"********++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++****++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**********++++++++++++++**++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++************++++++++++++++++++++!!++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************************************++++**++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++****************************************++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"************************************++****++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++++****************************++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++**++******++****++++****++**++**++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++++++++++++++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************++++++++++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"************++******++++++++++**++++++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++++++++++**++++++++++++++++++++++++++++++::..::::!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"**********************++++++++++**++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++++++++++++++++++++++++++++++++++++++!!....::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::!!::::::::!!::++$$$$@@@@@@@@@@++::!!!!!!!!!!!!!!!!::::::::::::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::::::!!!!!!ee$$@@@@@@@@@@!!::!!!!!!!!!!!!!!!!!!!!!!::::::::",
"++!!++++!!++++++!!!!!!!!!!!!!!!!!!!!::::::::::!!::!!!!!!!!**$$@@@@@@@@@@!!::!!!!!!++!!!!++++!!!!!!!!!!!!::",
"!!++++!!++++++++!!++!!!!!!!!!!!!!!!!!!::::::::!!!!++++++++++$$@@@@@@@@@@!!::!!!!!!!!!!!!++++++++++!!!!!!!!",
"!!++++++++!!!!++++++!!!!!!!!!!!!!!!!!!::::::!!++****++++++++$$$$@@@@@@@@++::!!!!!!!!!!!!!!++++++++++++!!!!",
"!!!!!!++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::!!++**eeee******++**$$@@@@@@@@@@**::!!!!!!......::!!++++++++++++!!",
"++!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!::::::**$$eeeeee******++ee@@@@@@@@@@@@$$!!!!::..          ::!!++++++++!!",
"!!++!!!!!!!!++++!!!!!!!!!!!!!!!!!!::....!!++!!::!!++!!++**$$@@@@@@@@@@@@@@ee!!::..            ::!!++++++++",
"!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!::..::!!!!..    ::!!++**$$@@$$@@@@@@@@@@@@**::::..            !!++++++++",
"!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!::....::**ee$$@@@@@@@@@@$$@@@@$$++::::::::..      ::!!++++++",
"!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::**ee@@$$@@@@@@@@@@@@@@@@$$!!..::::::::::..::!!++++++",
"!!!!!!!!!!++++!!++!!!!!!!!!!!!!!!!!!::::::::::::::::::!!ee$$@@$$@@@@@@@@@@@@@@@@**..::::::!!!!!!!!!!!!!!!!",
"!!++!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!ee@@$$@@@@@@@@@@@@@@@@++..::::::::::::::::::::::",
"!!!!++!!++!!++++!!++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::**$$@@$$**++**$$@@ee::....::::::::::::::::::::",
"++!!!!!!++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::!!::::++**......::!!**!!......::::::::::::::::::::",
"++!!!!++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::..  ......::......::..::::::::::::::::::",
"!!!!++++++++!!!!!!!!!!!!!!++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::........::::::::....::::::::::::::::::",
"!!!!!!++++!!++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!++!!::::::::::::::::::........::::::::..::::::::::::::::::::",
"++!!++++++++++++!!++++++++!!!!!!!!!!!!!!!!!!!!++++!!!!::::::::::::::::......::::::::::::::::::::::::::::::",
"++!!++++!!++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!!!++++!!!!::::::::::::!!......::::::::::::::::::::::::::::::",
"++!!++!!!!!!++++++++!!++!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!::!!::::::!!::......::::::::::::::::::::::::::::",
"!!!!++!!++!!++++++!!++++!!!!!!!!++!!!!!!!!!!!!!!++++++++!!!!!!::::::!!!!......::::::::::::::::::::::::!!!!",
"++++!!++!!!!++++!!++!!++++!!!!!!++!!!!!!!!!!!!!!++++++++++!!!!::::::!!!!::....::::::::::::::::::::::::!!!!",
"++!!++!!++++++++++++!!++!!!!++!!++!!!!!!!!!!!!!!!!++++++++++!!!!::::!!!!::..::::!!::::::::::::::::::!!!!!!",
"++++++++++++++++++++!!!!!!++++!!++!!!!!!!!!!!!!!!!!!++++++++!!!!::::::!!!!..::::!!!!!!::::::::::::::!!!!!!",
"++++++++++++++++++!!++!!!!++++++!!!!!!!!!!!!!!!!!!!!!!++**++++!!!!::::!!!!..::::!!!!!!::::::::::::::!!!!!!",
"++++++++++++++++++++++++!!!!++++!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!!!..::::!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::!!++++++!!!!!!!!!!..::::!!!!!!!!::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::....!!++++!!!!!!!!::::::::!!!!!!!!::::::::::!!!!!!!!",
"++++++++++++++++++++++!!++++!!!!++!!!!!!!!!!!!!!!!!!::..  ..!!!!!!!!::!!::::::::!!!!!!!!::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::    ..::!!!!::!!::::::::!!!!!!!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..    ..::::::!!::::::::!!!!!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::..    ..::....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!::..      ..........::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::!!::      ......      ..::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::  ....::....  ..::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::....::::::..::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::....::::::::::::::!!!!::!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!::::..::::::::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!",
"****++**++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..::::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++****++++****++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**********++++++++++++++**++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++************++++++++++++++++++++!!++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++********************************++**++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++****************************************++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"************************************++****++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++++****************************++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++**++******++****++++****++******++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++++++++++++++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************++++++++++++++++++++++++**++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"************++****++++++++++++**++++++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************++++++++++++++++++++++++++++++++++++++++++++::..::!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"********************++++**++++++**++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++++++++++++++++++++++++++++++++++++++!!....::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++!!++!!!!!!!!!!!!!!!!::::::::::::::::::!!!!++$$@@@@@@@@@@@@ee::!!!!!!!!!!!!!!!!!!::::::::::!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::::::::!!!!!!++$$@@@@@@@@@@@@++::!!!!!!!!!!!!!!!!::::::::::::::",
"++!!++++!!++++!!!!!!!!!!!!!!!!!!!!::::::::::!!!!!!++++++++ee@@@@@@@@@@@@!!::!!!!!!!!!!!!!!!!!!::::::::::::",
"!!++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::!!++******++++**$$@@@@@@@@$$::::!!!!!!!!!!!!++!!!!!!!!!!::::::",
"!!++++++++++++++++++!!!!!!!!!!!!!!!!!!::!!!!++********++++ee$$@@@@@@@@$$!!::!!!!!!!!!!!!++++++!!!!!!!!!!::",
"++!!!!++++++++!!!!!!!!!!!!!!!!!!!!!!!!::!!**eeee**********$$@@@@@@@@@@$$!!::!!!!!!!!!!!!++++++++++++++!!!!",
"++!!!!++++!!++++!!!!!!!!!!!!!!!!!!::::::++ee++++++++++**ee@@@@@@@@@@@@@@**::!!!!!!!!!!!!!!++++++++++++++!!",
"!!++!!!!++!!++++!!!!!!!!!!!!!!!!!!::....!!!!    ..::++**$$@@@@@@@@@@@@@@$$++!!::::      ..::!!++++++++++!!",
"!!++++++++++!!!!!!!!!!!!!!!!!!!!!!::..::::::..  ..::++ee$$@@@@@@@@@@@@@@@@ee!!::            ..!!++++++++++",
"!!++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!::::::++ee$$@@@@@@@@@@@@@@@@@@++::..            ::!!++++++++",
"!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::++ee$$@@@@@@@@@@@@@@@@@@ee::::....          !!++++++++",
"!!!!!!!!!!++!!!!++!!!!!!!!!!!!!!!!!!::::::::::::::::::++$$@@@@@@@@@@@@@@@@@@@@**..::::::::..    !!!!++++++",
"!!++++++!!++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!ee@@$$$$@@@@@@@@@@@@$$!!::::::::::::::::!!!!++!!",
"!!!!++!!++!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::!!::!!::::::**$$@@@@@@@@@@@@@@@@++..::::::::::::::::::::::",
"++!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::!!::::++$$ee++++**$$@@$$::..::::::::::::::::::::::",
"++!!!!++++++!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::  ....!!**++........::::::::::::::::::",
"!!!!++++++++++++!!++!!!!!!++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::........::..........::::::::::::::::::",
"!!!!!!++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++!!::::::::::::::::::........::::::::..::::::::::::::::::::",
"!!!!++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++!!!!::::::::::::::::......::::::::::::::::::::::::::::::",
"!!!!++++!!++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!++++++!!!!::::::::::::!!......::::::::::::::::::::::::::::::",
"++!!++!!++!!++++++++++++++!!!!!!!!!!!!!!!!!!!!++++++++!!!!::::::::::!!::....::::::::::::::::::::::::::::::",
"!!!!!!!!++++++++++!!++++++!!!!!!++!!!!!!!!!!!!!!++++++++!!!!!!::::::!!::......::::::::::::::::::::::::::::",
"++++!!++!!++++++++++!!++++!!++++++!!!!!!!!!!!!!!++++++++++!!!!::::::::!!::....::::::::::::::::::::::::!!!!",
"++!!!!!!++++++++++++!!++!!++++!!++!!!!!!!!!!!!!!!!++++++++++!!!!::::::!!::..::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!++!!!!!!!!!!!!!!!!!!++++++++!!!!!!::::!!::..::::!!::::::::::::::::::!!!!!!",
"++++++++++++++++++++++!!!!++++++!!!!!!!!!!!!!!!!!!!!++++++++++!!!!::::!!::::::::!!!!::::::::::::::::!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!::..::::!!!!!!::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::++++++++!!!!!!!!::..::!!!!!!!!!!::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::..::!!++++!!!!::!!::::::!!!!!!!!::::::::::::!!!!!!!!",
"++++++++++++++++++++++!!++++!!!!++!!!!!!!!!!!!!!!!!!::..  ::!!!!!!!!::!!::::::!!!!!!!!::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::    ..::!!!!::::::::::!!!!!!!!!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..    ..::::::::::::::::!!!!!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::      ........::::::::::!!!!!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!::..      ....::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::    ..............::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::  ....::....  ..::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::....::::::..::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::..::::::::::::::!!!!::!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++**++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..::::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!",
"**********++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++++++****++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!",
"++**********++++++++++++++**++++++++++++++++++!!++++++++!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++************++++++++++++++++++++!!++++++!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************************************++**++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************************************++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"************************************++****++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++++******************************++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++**++******++****++++****++******++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************++++++++++++++++++++++++++++++++++++++++++!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"eeeeee******++******++++++++++++++++++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****ee**********++****++++++++++++++++++++++++++++++++++++++!!!!..::::!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"******eeee****************++++++**++++++++++++++++++++++++++!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************************++++++++++++++++++++++++++++++++!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::::::::!!!!!!**$$@@@@@@@@@@@@@@++::!!!!!!!!!!!!!!!!::!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::::!!!!!!++++$$@@@@@@@@@@@@ee::!!!!!!!!!!!!!!!!::::::::::!!!!",
"++++++++!!++++!!!!!!!!!!!!!!!!!!!!::::::::::!!::!!++++++++$$@@@@@@@@@@@@++::::!!!!!!!!!!!!!!!!::::::::::::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::!!++******++++$$@@@@@@@@@@@@!!::!!!!!!!!!!!!!!!!::::::::::::::",
"++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!::!!!!++********++**$$@@@@@@@@@@$$::::::!!!!!!!!!!!!!!!!!!!!!!::::::",
"++++!!++++++++!!!!!!!!!!!!!!!!!!!!!!!!::!!**eeee********$$@@$$@@@@@@@@$$!!::!!!!!!!!++!!++++++!!!!!!!!::::",
"++++++!!!!!!++++!!!!!!!!!!!!!!!!!!::::::++ee++++++++++ee$$@@@@@@@@@@@@@@++::!!!!!!!!!!++++++++++++++++!!!!",
"++++++!!!!++++++!!!!!!!!!!!!!!!!!!::::..!!::    ..::++$$@@$$@@@@@@@@@@@@**!!!!!!!!!!!!!!++++++++++++++!!!!",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!::..::::::    ..!!++$$@@@@@@@@@@@@@@@@@@**!!::::......::!!++++++++++++!!",
"++++++++++!!++!!!!!!++!!!!!!!!!!!!::..::::::!!::::++**$$@@@@@@@@@@@@@@@@@@ee!!::..          ::!!++++++++!!",
"++!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!**$$@@@@@@@@@@@@@@@@@@@@++::..            ::++++++++++",
"++!!!!!!!!++!!++++!!!!!!!!!!!!!!!!!!::::::::::::::::!!**$$@@@@@@@@@@@@@@@@@@ee::....            !!++++++++",
"++++++++!!++++++!!++!!!!!!!!!!!!!!!!!!::::::::::::::::::++$$@@@@$$@@@@@@@@@@@@++..::::......    ::!!++++++",
"++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::!!ee$$@@@@@@@@@@@@@@$$!!..::::::::::::::!!!!++++",
"++++!!!!++!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!::!!::::**$$@@@@@@@@@@@@@@++..::::::::::::::::!!!!!!",
"++!!++++++++!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::!!::::::**$$ee**ee@@@@$$::..::::::::::::::::::::::",
"++++++++++++!!!!!!++!!!!!!++!!!!!!!!!!!!!!!!!!::::::::::::::!!::::!!......++ee!!........::::::::::::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::..::::::::::........::..........::::::::::::::::::",
"!!!!++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::......::::::::..::::::::::::::::::::",
"!!!!!!++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++!!::::::::::::::::::::....::::::::::::::::::::::::::::::",
"++!!!!!!++++++++++++++!!++++++!!!!!!!!!!!!!!!!++++++!!!!::::::::::::!!::....::::::::::::::::::::::::::::::",
"!!!!++!!++++++++++!!!!++++!!++!!++!!!!!!!!!!!!++++++++!!!!::::::::::!!::......::::::::::::::::::::::::::::",
"++!!!!++!!++++++++++!!++!!!!++++++!!!!!!!!!!!!!!++++++++!!!!!!::::::::!!......::::::::::::::::::::::::::::",
"++!!!!++++++++++++++!!!!++!!++!!++!!!!!!!!!!!!!!++++++++++!!!!!!::::::!!::..::::::::::::::::::::::::!!::!!",
"++++++++++++++++++++++!!++!!!!!!++!!!!!!!!!!!!!!++++++++++++!!!!!!::::!!::..::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++!!!!++!!++!!!!!!!!!!!!!!!!!!!!++++**++!!!!!!::::!!::::::::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!!!++++++++++!!!!!!!!!!::::::::!!!!::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!::..::!!!!!!!!::::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::++++++++!!::::!!::::::!!!!!!!!::::::::::::!!!!!!!!",
"++++++++++++++++++++++!!!!++!!!!++++!!!!!!!!!!!!!!!!::..::!!++++!!!!::!!::::::!!!!!!!!::::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..  ::!!!!!!!!::!!::::::!!!!!!!!!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!..    ..::!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!::!!      ..::::..::::::::::::!!!!::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!::..    ....  ..::::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::..    ....::....::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::..  ....::........::::::!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::....::::::......::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::..::::::::::::::!!!!::!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++**++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..::::::::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!",
"**********++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++++++**++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!",
"++**********++++++++++++++**++++++++++++++++++!!++++++++!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++++**++++****++++++++++++++++++++++++++++!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************************************++**++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************************************++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"************************************++****++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!",
"********++++******++**********************++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++**++******++++**++++**************++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++******++++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!",
"******************++++++++++++++++++++++++++++++++++++++++++!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"ee**********++******++++++++++++++++++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"eeeeeeee**************++++++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"eeeeeeeeee****************++++++**++++++++++++++++++++++++++!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**eeee****ee****************++++++++++++++++++++++++++++++!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::::::!!!!!!ee$$@@@@@@@@@@@@@@$$++::::::!!!!!!!!!!::!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!::::::::!!::!!!!!!!!++**$$@@@@@@@@@@@@@@ee::::!!!!!!!!!!!!!!!!::!!!!!!!!",
"++++++++++++++!!!!++!!!!!!!!!!!!!!::!!!!::::!!::!!++++++++$$@@@@@@@@@@@@@@!!::!!!!!!!!!!!!!!::::::::::::!!",
"++++++++++++++++!!++!!!!!!!!!!!!!!!!!!::::::!!++******++**$$@@@@@@@@@@@@$$::::!!!!!!!!!!!!!!::::::::::::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::!!!!++********++ee$$@@@@@@@@@@@@**::!!!!!!!!!!!!!!!!!!!!::::::::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::++eeee**ee****$$@@$$@@@@@@@@@@**::!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!::::!!ee**++++++**ee$$@@@@@@@@@@@@@@**::::!!!!!!!!++++++++++++++!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!::::!!::  ..::!!**$$@@$$@@@@@@@@@@@@$$!!!!!!!!++++++++++++++++++++!!!!",
"++++++++++++++++!!!!!!!!!!!!!!!!!!::::..::..  ..::++ee$$$$@@@@@@@@@@@@@@@@**!!!!!!!!!!!!!!++++++++++++++!!",
"++++++++++++!!++!!!!!!!!!!!!!!!!!!::..::::::..::!!++$$$$@@@@@@@@@@@@@@@@@@ee++!!::..    ..::!!++++++++++!!",
"++!!++++++!!!!++!!!!!!!!!!!!!!!!!!::..::::::::::!!++$$$$@@@@@@@@@@@@@@@@@@@@**::..            ::++++++++++",
"++++++++++++!!++++!!++!!!!!!!!!!!!::::::::::::::::!!ee@@$$$$@@@@@@@@@@@@@@@@ee::::              !!++++++++",
"++++++++!!++++!!!!++++!!!!!!!!!!!!!!::::::::::::::::::**$$@@$$@@@@@@@@@@@@@@@@++::....          ::!!++++++",
"++++++++++!!!!!!!!++!!!!!!!!!!!!!!!!!!::::::!!::::::::::!!ee$$$$@@@@@@@@@@@@@@$$::::::::::..    ::!!++++++",
"++++++!!++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::++$$@@@@@@@@@@@@@@@@**::::::::::::::::!!!!!!++",
"++!!!!++++++++++!!++++++++!!!!!!!!!!!!!!!!::::::::!!!!!!::::::!!ee@@@@@@@@@@@@@@ee::::::::::::::::::::!!::",
"++++++++++++++++!!++++++!!!!!!!!!!!!!!!!!!!!::::::::::!!!!!!!!::!!$$@@@@@@@@@@@@!!..::::::::::::::::::::::",
"!!++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::!!!!::::!!++!!!!**$$**......::::::::::::::::::::",
"!!!!++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::!!::::......::::........::::::::::::::::::::",
"!!!!!!++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!::......::::::::..::::::::::::::::::::",
"++!!!!!!++!!++++++++++++++++++!!!!!!!!!!!!!!++++!!!!::::::::::::::::!!......::::::::::::::::::::::::::::::",
"!!!!!!++++++++++++++++++++!!!!!!!!!!!!!!!!!!++++++++!!::::::::::::::!!::....::::::::::::::::::::::::::::::",
"++!!!!++++++++++++++++++++!!++!!!!!!!!!!!!!!!!++++++++!!!!!!::::::::::!!....::::::::::::::::::::::::::::::",
"++!!!!++++++++++++++!!++++!!!!!!++!!!!!!!!!!!!++++++++++!!!!!!::::::::!!::..::::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++!!!!!!++!!!!!!!!!!!!!!++++++++++!!!!!!::::::!!::..::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++++++++++++!!!!!!::::!!::..::::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!++++++++++!!!!!!::!!!!::..::::!!::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++!!++++++!!!!!!!!!!!!!!!!!!++++++++++!!!!::!!!!::::::!!!!!!::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!::!!::::::!!!!!!!!::::::::::::::!!!!!!",
"++++++++++++++++++++++!!!!++!!!!++!!!!!!!!!!!!!!!!!!::!!++++++++!!!!::!!::::::!!!!!!!!::::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::!!++++!!!!!!::!!::::::!!!!!!!!!!::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::....::!!!!!!!!::!!::::::::!!!!!!::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!::..    ..!!!!::::::::::::::::!!!!::!!!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!::      ..::::....::::::::::!!::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::        ..  ..::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..      ......::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..  ..::....      ..::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::....::::::........::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::..::....::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..::::::::::!!!!!!!!::::!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::..::!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!",
"******++**++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::..::!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!",
"++******++**++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++++++++++++****++++++++++++++++++++++++++!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!",
"******************++++++******++**++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************++++************++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"**********************************++++****++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++******++**********************++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************++++**++++************++++++++++++++++++++++!!!!::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************++++++++++++++****++++++++++++++++++++++++!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"******************++++++++++++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************++++++++++++++++++++++++++++++++++++++++++!!!!::::::::!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"eeeeee******************++++++++++++++++++++++++++++++++++++!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"eeeeeeeeee******************++++++++++++++++++++++++++++++!!!!..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::::!!!!!!!!$$$$@@@@@@@@@@@@@@@@$$++::::::::::!!!!::!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!::::::::!!::!!!!!!!!!!ee@@@@@@@@@@@@@@@@@@++::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!::!!!!::::!!::!!!!++++++$$@@@@@@@@@@@@@@ee::!!!!!!!!!!!!!!::::::!!!!!!!!",
"++++++++++++++++!!++!!!!!!!!!!!!!!::!!::::::!!!!!!++**++++$$$$@@@@@@@@@@@@++::!!!!!!!!!!!!!!::::::::::::!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!::::!!!!!!!!++****++**$$@@@@@@@@@@@@$$::::!!!!!!!!!!!!!!!!::::::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!::::!!!!!!++**ee****$$$$@@@@@@@@@@@@ee::!!!!!!!!!!!!!!!!!!!!!!::::::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!::!!!!**eeee****ee$$@@@@@@@@@@@@@@ee!!::!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!::!!****++++++ee$$@@$$@@@@@@@@@@@@ee!!!!!!!!++++++++++++!!++!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::!!  ..::!!ee@@@@@@@@@@@@@@@@@@$$++!!!!!!!!++++++++++++++++++!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!::..::..  ..::++ee$$$$@@@@@@@@@@@@@@$$**++!!!!!!!!!!!!++++++++++++++!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!::....::::....!!**$$@@$$@@@@@@@@@@@@@@@@$$**!!!!..      ..!!++++++++++++",
"++++++++++++++++++!!++!!!!!!!!!!!!::..::::!!::::!!**$$@@@@$$@@@@@@@@@@@@@@@@ee!!::            ::!!++++++++",
"++++++++++++++!!++++++!!!!!!!!!!!!::..::::::::::::!!$$@@@@@@@@@@@@@@@@@@@@@@@@!!::..            !!++++++++",
"++++++!!++++++++++++++!!!!!!!!!!!!::::::::::::::::::!!ee$$$$@@$$@@@@@@@@@@@@@@**::::....        ..!!++++++",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::!!ee@@$$$$@@@@@@@@@@@@$$!!::::::::::..  ..!!++++++",
"++!!!!++++++++++!!++++++++!!!!!!!!!!!!::!!!!!!::::!!::::::::**$$@@@@@@@@@@@@@@@@ee::::::::::::::::!!!!++++",
"++++++++++++++++!!++++!!++!!!!!!!!!!!!!!::::::::::!!!!::::::::++$$@@@@@@@@@@@@@@$$::::::::::::::::::!!!!!!",
"!!++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::!!!!!!!!::::!!$$$$@@@@@@$$@@!!..::::::::::::::::::::::",
"!!++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!::::::::::::!!!!!!::::++$$$$$$@@$$**....::::::::::::::::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::!!!!::::::..::!!!!..........::::::::::::::::::",
"++++++++++++++++++++++++++++!!++!!!!!!!!!!++++!!!!::::::::::::::::!!::......::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++++!!!!::::::::::::::::::::....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!++!!!!!!++!!!!!!++++++!!!!::::::::::::::::::....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!!!++!!!!!!!!!!++++++++++!!!!::::::::::::!!....::::::::::::::::::::::::::::::",
"!!++++++++++++++++++++++++++!!++++++!!!!!!!!!!++++++++++!!!!!!::::::::!!::....::::::::::::::::::::::::::::",
"++++++++++++++!!++++++++++++++++++!!!!!!!!!!!!++++++++++++!!!!!!::::::!!::..::::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++!!++++++!!!!!!!!!!!!!!++++++++++!!!!!!!!::!!!!::..::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++!!++!!++++++++!!!!!!!!!!!!++++++++++++!!!!!!::::!!::..::::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!++++++++++++!!!!::::!!!!::::::!!!!!!::::::::::::::!!::!!",
"++++++++++++++++++++++++++++!!!!++++!!!!!!!!!!!!!!!!++++++++++++!!::::!!!!..::::!!!!!!::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++++++++!!!!!!::!!::::::::!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!::!!++++++++!!!!::!!!!::::::!!!!!!::::::::::!!::!!!!!!",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!::..::!!++!!!!::::!!::::::::!!!!!!!!!!!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!..  ..::!!!!!!::!!::::::::!!!!!!::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::      ::::::..::....::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!..      ....    ..::::::::::::!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::        ........::..::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::    ..::....      ..::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::  ..::::..........::!!!!!!!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..::::..::::::::::!!::::!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::..::::::::::!!!!!!!!::!!::!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::..::::!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!",
"++****++++++++++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!::..::!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!",
"********++++**++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!..::!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!",
"**************++++++++++**++++++++++++++++++++++++++++++!!!!!!!!!!::!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!",
"**************++++++++++++**++++++++**++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!++",
"****************++++**++****++++++++++++**++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************++**++**********++****++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!",
"******************++****++++****++******++++++++++++++++++++++!!!!::::!!!!!!!!!!::!!::!!!!!!!!!!!!!!!!!!!!",
"********++****++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******************++++++++++++++****++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"********++********++++++++++++++++++++****++++++++++++++++++++!!!!::::::!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"************++**++**++++++++++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++++++**++++++++++++++++++++++++++++++++++++++!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"eeee**ee************++**++++++++++++++++++++++++++++++++++++!!::::::::::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++!!!!!!!!!!::::!!::::::::!!!!!!++$$$$@@@@@@@@@@@@@@@@@@ee!!::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::::!!::!!!!!!!!!!ee@@@@@@@@@@@@@@@@@@ee!!::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@$$!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::::::!!!!!!!!++++++$$$$@@@@@@@@@@@@**::!!!!!!!!!!!!!!!!::::::::!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::::!!!!!!!!++**++++$$@@@@@@@@@@@@$$++::!!!!!!!!!!!!!!!!::::::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!++********$$@@@@@@@@@@@@$$!!!!!!!!!!!!!!!!!!!!::::::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!****ee**ee$$@@@@@@@@@@@@@@ee!!::!!!!!!!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!**ee******$$@@@@@@@@@@@@@@@@ee!!!!!!!!!!++!!!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!::::::++++::::!!**$$@@@@@@@@@@@@@@@@$$++!!!!!!!!++++++++++++++++!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::  ..::++ee$$$$@@@@@@@@@@@@@@$$**!!!!!!++++++++++++++++++++++!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!::..::::  ..!!++$$$$$$@@@@@@@@@@@@@@@@ee++!!!!::::::::!!++++++++++++++",
"++++++++++++++++++++++!!!!!!!!!!!!::::..::::::::!!**$$@@@@@@@@@@@@@@@@@@@@$$**!!::          ..!!++++++++++",
"++++++++++++++++++++++!!!!!!!!!!!!::..::::!!!!!!!!++@@@@$$@@@@@@@@@@@@@@@@@@ee!!::            ..!!++++++++",
"++++++++++++++!!++++++++!!!!!!!!!!::::::::::!!::!!::!!$$@@$$@@@@@@@@@@@@@@@@@@++::::            ::++++++++",
"++++++++++++++++++++!!++!!!!!!!!!!!!::::::::::::::::::!!**$$@@@@@@@@@@@@@@@@@@$$::::..::..      ..!!++++++",
"++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::::++$$$$$$@@@@@@@@@@@@@@**::::::::::::::::!!++++++",
"++++++++++++++++++++++!!++++!!!!!!!!!!::::::!!!!!!!!::::::::!!ee@@@@@@@@@@@@@@@@$$::::::::::::::!!!!!!++++",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::!!!!!!::::::::ee@@@@@@@@@@@@@@ee::::::::::::::::::::!!!!",
"!!++++++++++++++++++++++!!++!!!!!!!!!!!!!!::::::::::!!!!!!!!::::::ee$$$$@@@@@@$$::::::::::::::::::::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!::::ee@@$$$$ee!!......::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!++!!::::::::::::::!!!!!!::::!!::!!::....::::..::::::::::::::::::",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!++++!!::::::::::::::::::!!::::..::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!++!!!!++++++!!::::::::::::::::::!!::....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!++++++!!!!::::::::::::::::::....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!++++!!!!!!!!!!!!++++++!!!!::::::::::::::!!::....::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!++++++++++!!!!!!::::::::!!::..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++++++++!!!!!!::::::!!::..::::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++++++++!!!!!!!!::::!!::..::::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++++++++++!!!!!!::::!!!!..::::::::::::::::::::::::!!::!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++++++++++!!!!!!::::!!!!..::::!!!!!!::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++++++++++!!!!!!::!!!!::::::!!!!!!::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!++++++++++!!!!::!!!!..::::!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::!!!!++++!!!!!!::!!!!..::::!!!!!!!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::!!++!!!!!!::!!::::::::!!!!!!!!::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::  ..::!!!!!!::!!::..::::::::!!!!!!!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::      ..::::......::::::::::::!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!..        ..  ..  ::::..::::::::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::..        ..............::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::    ........        ..!!::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::....::..............::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..::::::::::::::::::::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::..::::::::::::!!!!!!::::!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::..::::!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!",
"****++**++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!..::!!!!!!!!!!!!::!!::::!!!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!::::!!!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::!!!!!!!!!!!!!!!!::::::::!!!!!!!!!!!!++",
"****************++++++++++++++++++++++**++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!",
"****************++++++++++++++**++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!::!!::::::!!!!!!!!!!!!!!!!",
"******************++++**++++++++++++++**++++++++++++++++++++!!!!!!::::!!!!!!::!!::!!::::!!!!!!!!!!!!!!!!!!",
"**************++++++++++++++++++**++++++++++++++++++++++++++++!!!!::::!!!!::!!::::::::::!!!!!!!!!!!!!!!!!!",
"++****************++++++++++++++++**++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!::::!!!!!!!!++!!!!!!!!",
"******************++++++++++++++++++++****++++++++++++++++++++!!!!::::::!!!!!!!!::!!!!::!!!!!!!!!!!!!!!!!!",
"************++**++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!",
"**************++++++****++++++++++++**++++++++++++++++++++++!!!!!!::::::::!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++********++++++++++++++++++++++++++++++++++++!!!!::..::::::!!::::::!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++!!!!!!!!!!::::::::!!::::!!!!!!ee@@@@@@@@@@@@@@@@@@@@@@@@**!!::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::::!!::!!::!!!!++$$$$@@@@@@@@@@@@@@@@@@**::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@ee!!::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!::!!::::::!!!!!!!!!!!!**$$@@@@@@@@@@@@@@$$!!!!!!!!!!!!!!!!!!::::!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!++++++$$$$@@@@@@@@@@@@**::!!!!!!!!!!!!!!!!::::::::!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!++++++**@@@@@@@@@@@@@@@@!!::!!!!!!!!!!!!!!::::::::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++******ee@@@@@@@@@@@@@@$$!!::!!!!!!!!!!!!!!!!::::::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!****ee**ee@@@@@@@@@@@@@@@@$$!!!!!!!!!!!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!!!********ee$$@@@@@@@@@@@@@@@@$$!!!!!!!!!!++++++++++!!!!!!!!!!::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!::::!!!!::::++ee$$@@@@@@@@@@@@@@@@$$++!!!!!!++++++++++++++++++!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!::::..  ..!!**$$$$@@@@@@@@@@@@@@@@$$**++!!!!++++++++++++++++++++++!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::::..  ::++**$$@@$$@@@@@@@@@@@@@@@@$$++!!!!::......::++++++++++++++",
"++++++++++++++++++++++!!!!!!!!!!!!!!::..::::::::++ee$$@@$$@@@@@@@@@@@@@@@@$$**!!::          ..!!++++++++++",
"++++++++++++++++++++++++++++!!!!::::::::::!!!!::!!ee$$@@@@$$@@@@@@@@@@@@@@@@ee!!::            ..!!++++++++",
"++++++++++++++++++++!!++++!!!!!!!!::..::::!!::::::!!**$$@@$$@@$$@@@@@@@@@@@@@@++::::            ::++++++++",
"++++++++++++++++++++++++++++!!!!!!::::::::::::::!!::::!!ee@@$$@@@@@@@@@@@@@@@@$$!!::::::..      ::!!++++++",
"++++++++++++++++++++++++!!++!!!!!!!!::::::::::::::::::::::++$$@@@@@@@@@@@@@@@@@@**::::::::::::::!!!!!!++++",
"++++++++++++++++++++++++!!++!!!!!!!!::::::::::::::::::::::::++$$@@@@@@@@@@@@@@@@$$::::::::::!!::::!!!!!!++",
"!!++++++++++++++++++!!++++++!!!!!!!!!!::::::++!!!!!!::::::::::++$$@@@@$$@@@@@@@@++::::::::::!!!!::::::::!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::!!::::::::::!!$$@@@@@@@@@@ee::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!!!!!!!!!::::!!$$$$$$@@$$!!..::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!::++$$ee++::::::::..::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!++!!::::::::::::::!!!!::::::::..::::..::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!!!!!++++!!!!::::::::::::::::!!!!::..::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!++++!!!!!!!!++++++!!!!::::::::::::::::::::::..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++!!!!!!++++++++!!::::::::::::::::!!::....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++!!!!!!!!++++++++!!!!::::::::::::::!!::..::::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!++++++++++!!!!!!!!::::::::!!::..::::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!++++++++++!!!!!!::::::!!!!!!..::::::::::::::::::::::::::::!!",
"++++++++++!!++++++++++++++++++++++++!!!!!!!!!!++++++++++++!!!!!!!!::::!!!!..::::!!::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++++++++++!!!!!!::::!!!!::::::!!!!!!::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!++!!!!!!++++++++++++!!!!::::!!!!..::::!!!!!!!!::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++++++++!!!!::::!!!!::::::!!!!!!!!::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++++++++!!!!!!::!!!!..::::::!!!!!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++++++!!!!::::!!::....::!!!!!!!!!!::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::++++!!!!::::!!::::::::!!!!!!::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::..  ..::!!!!::........::::::::::::::::!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..      ..::..  ..........::::::::!!::!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::            ....  ........::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::..      ..::::..        ..::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::..  ....::::......::..::::::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!......::::....::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::....::::::::::!!::!!!!!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::....::::::!!!!!!!!!!::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::....::::!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!",
"******++**++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!!!!!!!::::::::::!!!!!!!!!!++",
"**************++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::!!!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!!!!!::!!::::::::::!!!!!!!!!!!!",
"******************++++++++++++++++++++++++++++++++++++++!!++!!!!::::::!!!!!!::!!::!!::!!::!!::!!!!!!!!!!!!",
"**************++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::!!!!::!!::::::::::::::!!!!!!!!!!!!!!",
"**************++**++++++++++++++++++++++++++++++++++++++++++!!!!::::::::!!!!!!!!::::::::::!!!!!!!!!!!!!!!!",
"******************++++++++++++++++++++****++++++++++++++++++++!!!!::::::!!!!!!!!::::::::::!!!!!!!!!!!!!!!!",
"************++**++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::::!!!!::::!!!!!!!!!!!!!!!!!!!!!!",
"**************++++++****++++++**++++**++++++++++++++++++++++!!!!!!::::::::!!::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++********++++++++++++++++++++++++++++++++++++!!!!::::::::!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++!!!!!!!!!!::::::::!!::::::!!**$$@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::::!!::!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@$$++::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::!!!!::!!!!!!!!$$@@@@@@@@@@@@@@@@@@$$++::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!!!!!ee@@@@@@@@@@@@@@@@$$!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@**::!!!!!!!!!!!!!!!!::::!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++++**@@@@@@@@@@@@@@$$!!::!!!!!!!!!!!!!!!!::::::::::!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++++++ee@@@@@@@@@@@@@@$$!!::!!!!!!!!!!!!!!!!::::::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++******$$$$@@@@@@@@@@@@$$!!!!!!!!!!!!!!!!!!!!!!::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!::!!!!!!!!********ee$$@@@@@@@@@@@@@@ee!!::!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::::!!********ee$$$$@@@@@@@@@@@@@@ee!!!!!!!!!!++++++++++!!++!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!::::!!++!!!!**$$@@@@@@@@@@@@@@@@@@ee++!!!!!!++++++++++++++++++++!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!::....::++ee@@@@@@@@@@@@@@@@@@@@$$**++!!!!!!!!!!!!!!++++++++++++!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::::    !!++ee@@@@$$@@@@@@@@@@@@@@@@$$++!!!!..      ..!!++++++++++++",
"++++++++++++++++++++++++++++!!!!::::::::::::..++**$$$$@@$$@@@@@@@@@@@@@@@@$$**!!::            ::++++++++++",
"++++++++++++++++++++!!++++!!!!!!!!::::::::::!!::++$$$$@@@@@@@@$$@@@@@@@@@@@@$$!!::..            !!++++++++",
"++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!::::!!**$$@@@@@@@@@@@@@@@@@@@@@@**::::....        ::++++++++",
"++++++++++++++++++++++++!!++!!!!!!::::::::::::::::::::++ee@@$$@@@@@@@@@@@@@@@@$$!!::::::::......::++++++++",
"++++++++++++++++++++++++!!!!!!!!!!::::::::::::::::::::::!!ee$$$$@@@@@@@@@@@@@@@@**::::::::::!!::!!!!!!++++",
"++++++++++++++++++++++++++++!!!!!!!!::::::::::::::::::::::::**@@@@@@@@@@@@@@@@@@ee::::::::::::!!::!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!::::::::::::::::**@@$$@@@@@@@@@@$$!!::::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!::::::::::::**$$$$@@@@@@@@**::::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!++!!!!!!!!!!::::::::::!!!!!!::::::::::**$$$$$$@@**::..::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!!!!!!!!!::::::**$$$$++::..::::..::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::!!!!!!!!::::::::!!::..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!++!!!!!!!!++++!!::::::::::::::!!!!!!!!::::..::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!!!++++++!!!!::::::::::::::::!!!!::....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!!!++++++++!!!!::::::::::::::::::!!::..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++!!!!!!++++++++!!!!::::!!::::::::::!!::..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++!!!!!!++++++++++!!!!!!!!::::::!!!!!!::..::::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++!!!!!!++++++++++++!!!!!!::::::::!!!!::..::::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!++++++++++++!!!!!!!!::::!!!!::..::::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!++!!++++++++++++!!!!!!!!::!!!!::..::::!!!!!!::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!++++++++++++!!!!!!::::!!!!!!....::!!!!!!!!::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++++++++++++!!!!::!!!!!!..::::::!!!!!!::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++++++++++++!!!!::!!!!::....::!!!!!!!!!!::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++++++++!!!!::::!!::::..::!!!!!!!!::::!!!!!!::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++++++!!!!::!!::::....::::::!!::!!::!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!!!::::..........::::::::::::::!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::    ..::!!::  ....  ........::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::            ........  ........::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::..          ....::....      ..::::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::..    ....::::......::::..::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::!!::  ....::::::::::::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::......::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::....::::::!!!!!!!!!!::::::::!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::..::::::!!!!!!!!!!!!::::::::::!!!!!!!!!!++",
"********++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::..::::!!!!!!!!!!!!!!::::::::::!!!!!!!!!!!!",
"******++**++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::!!!!!!!!!!::!!::::::::::!!!!!!!!!!!!",
"******++**++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::!!!!!!::!!::!!::!!::!!::!!!!!!!!!!!!",
"********++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!::::::::::::::::!!!!!!!!!!!!",
"************++++**++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::!!!!!!!!::::::::::::!!!!!!!!!!!!!!",
"************++****++++++++++++++++++++++**++++++++++++++!!++!!!!!!::::::!!!!!!!!::::::::::::!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::::!!!!::::::!!!!!!!!!!!!!!!!!!!!",
"++************++++++****++++++****++++++++++++++++++++++++++!!!!!!!!::::::::::::::::!!::!!!!!!!!!!!!!!!!!!",
"**********************++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!::::::::::::::!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++!!!!!!!!!!::::::::!!::::::!!ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!::!!::!!::!!++$$@@$$@@@@@@@@@@@@@@@@@@@@**++::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::!!!!::::!!!!ee$$$$@@@@@@@@@@@@@@@@$$**::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!!!!!!!$$@@@@@@@@@@@@@@@@$$**::::::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!!!!!ee$$$$@@@@@@@@@@@@ee::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@@@$$++::!!!!!!!!!!!!!!!!::::!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!!!++++$$@@@@@@@@@@@@ee!!::!!!!!!!!!!!!!!!!::::::::::::",
"++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!++++++$$@@@@@@@@@@@@**::!!!!!!!!!!!!!!!!!!::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!::!!::!!!!!!++**++**@@@@@@@@@@@@@@++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++!!++!!!!!!!!!!!!::!!!!!!++****ee$$@@@@@@@@@@@@@@++!!!!!!!!++++++!!++++!!!!!!!!!!::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**eeee$$$$@@@@@@@@@@@@@@**++!!!!!!++++++++++++++++++!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++++++$$$$$$@@@@@@@@@@@@@@ee++!!!!++++++!!++++++++++++!!++!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::!!**$$@@@@@@@@@@@@@@@@@@$$**!!!!!!::::::::!!++++++++++++!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!....::++ee$$@@$$@@@@@@@@@@@@@@@@ee++!!..          ::!!++++++++++",
"++++++++++++++++++++!!++++!!!!!!!!!!!!::......!!**$$$$$$@@@@@@@@@@@@@@@@@@$$++!!::            ::++++++++++",
"++++++++++++++++++++++++++++!!!!!!!!!!::..::::!!**$$$$@@@@@@@@@@@@@@@@@@@@@@ee!!::..            !!++++++++",
"++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::++ee@@@@@@@@@@@@@@@@@@@@@@$$++::::::....      ::!!++++++",
"++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!::::::!!**$$@@$$$$@@@@@@@@@@@@@@ee::::::::::::::::!!!!++++++",
"++++++++++++++++++++++++!!++!!!!!!::::::::::!!::!!::::::++ee@@$$@@$$@@$$@@@@@@@@++::::::::!!!!!!!!!!!!++!!",
"++++++++++++++++++++++++!!++!!!!!!::::::::::::::::::::::::!!ee@@@@@@@@@@@@@@@@@@++::::::::!!::!!::!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!::::::::::::::::::::::::::ee@@@@@@@@@@@@@@$$::::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!++!!!!!!::::::::!!!!::::::::::::::::ee@@$$@@@@@@$$!!::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!::::::::::::::**$$@@@@@@**....::..::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!::::::::::!!++!!!!::::::::::ee@@$$++......::::..::::::::::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::!!!!!!!!!!!!::::::**!!::....::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!++!!::::::::::::!!!!!!!!!!::::::..::::::::::::..::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!++++!!::::::::::::!!!!!!!!!!::::....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++!!!!++++++!!::::!!::::::::::!!::::!!....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++!!!!!!++++++!!!!::::!!::::::::!!!!!!::..::::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!++++++++!!!!::::!!::::::!!!!!!::..::::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++!!!!++++++++++!!!!!!!!::::::!!!!!!::....::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++!!++++++!!++++++++++++!!!!!!!!::::++!!!!::....::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!++!!++++++++++!!!!!!!!::::++!!!!....::!!!!!!::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!++++++++++++!!!!!!!!::!!!!!!....::::!!!!!!::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++!!++++!!!!!!!!++++++++++!!!!!!::::!!!!!!::..::::!!!!!!!!::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++++++++++++!!!!::!!!!::::..::::!!!!!!::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++++++++!!!!!!::!!!!::::..::::!!!!!!::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++++++!!!!!!::::!!::::::..::::!!::::::::!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++++!!!!::::..  ......::::::::!!::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!::........  ......::::::::!!::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::    ..::!!::....::....      ....::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::            ....::........    ..::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..  ..    ....::::::....::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::    ....::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::    ....::::::::::::::!!::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!::::......::::!!!!!!!!!!!!::::::::::!!!!!!!!!!++",
"++**++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::....::::!!!!!!!!!!!!!!::::::::::!!!!!!!!!!!!",
"******++++++++**++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!....::::!!!!!!!!!!!!!!::::::::::!!!!!!!!!!!!",
"****++++**++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!!!::!!::!!!!::::::::::!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!!!!!::::::::::::!!::!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::::!!!!!!::::::::::::::::!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::!!!!!!!!::!!::::::::::!!!!!!!!!!!!",
"************++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::!!!!::::!!!!!!::!!!!!!!!!!!!!!",
"++**********++++++++**++++++++++**++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"********++****++++****++++++++++++++++++++++++++++++++++!!++!!!!!!!!::::!!::::::::::::::!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++!!!!!!!!!!!!::!!::!!!!::::!!ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::::::::!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!::!!::::::::::::!!**$$$$@@@@@@@@@@@@@@@@@@@@@@$$**::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::!!++$$@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::!!!!!!!!ee$$@@@@@@@@@@@@@@@@@@**::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!!!++$$$$@@@@@@@@@@@@@@ee::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**@@@@@@@@@@@@@@$$!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@**!!!!!!!!!!!!!!!!!!::::::::::!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@$$++::!!!!!!!!!!!!!!::::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@$$!!!!!!!!!!!!!!!!!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::!!!!!!!!!!++++++$$@@@@@@@@@@ee!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++++ee@@@@@@@@@@@@ee++!!!!!!!!++++++++++!!!!!!!!!!!!::",
"++++++++++++++++++++++++!!!!++!!!!!!!!!!!!!!!!!!!!****ee$$@@@@@@@@@@@@$$++!!!!++++++++++++++++++++++++!!!!",
"++++++++++++++++++++++++!!++++!!!!!!!!!!!!!!!!++****ee$$$$@@@@@@@@@@@@@@**++!!!!!!!!!!::::!!++++++++++!!!!",
"++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!++++++ee$$$$@@@@@@@@@@@@@@$$**!!!!..          ::!!++++++++!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::++**$$@@@@@@@@@@@@@@@@@@@@ee!!::              ::++++++++!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::..::**ee$$@@@@@@@@@@@@@@@@@@@@$$!!!!..              !!++++++++",
"++++++++++++++++++++++++!!++!!!!!!!!!!!!::....!!**$$$$@@@@@@@@@@@@@@@@@@@@@@**!!!!::..          !!++++++++",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::..::::::!!$$$$@@@@@@@@$$@@@@@@@@@@@@$$++::::::::::......!!!!++++++",
"++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!::::**@@@@@@@@@@@@@@@@@@@@@@@@ee::::::::::!!!!::!!!!++++++",
"++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!::::::!!ee$$@@@@@@@@@@@@@@@@@@$$!!::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!::::::::::++$$@@@@@@@@@@@@@@@@$$::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!::..::::::!!::::::::::::::++$$@@$$@@@@@@@@@@**..::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!::::::::::::::::::::::::::::!!$$$$$$@@@@@@$$::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!::::::::::::::::::::::::::::!!ee@@@@@@@@!!....::::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!!!!!::::::::::!!ee$$$$**....::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!::::::::!!!!++!!!!!!::::::::!!$$**::::::::::::..::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!::::..::::++!!!!!!!!::::::::!!::..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!!!++!!::::::::::::!!!!!!!!!!::::::::..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!++++!!::::::::::!!!!!!!!!!!!!!::::..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!++++++!!::::::::::!!::!!!!!!!!!!::..::::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!++++++!!::::::::::::::!!!!!!!!::..::::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++!!++!!++!!++++++!!!!::::::!!::::::!!!!!!::....::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++!!!!++++++++++!!!!::!!!!::!!++++!!!!....::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++!!!!++++++++++++!!!!!!!!::::++++!!!!....::::::!!!!::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++!!++++!!++++++++++++!!!!!!!!!!::!!!!!!!!::..::::!!!!!!!!::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++++!!!!++++++++++++!!!!!!!!::::!!!!!!::::::::!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!++++++++++++!!!!!!!!::!!!!!!::::::::!!!!!!!!::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!++++++++++++!!!!!!::!!!!!!::::::::::!!::!!::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++++++++!!!!::!!!!........::::::::!!::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++++++++++!!!!::::............::::::::::!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++++++!!::......::::..........::::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++++!!::....::::::::::..........::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..::::!!::....::::::::::....      ..::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::          ....::::::::::......::::::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::  !!++    ::..::::::::::::::::::::::::!!::::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!  ....::::::::::::::::::::::::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::..  ....::::::::::!!!!!!!!::!!!!::::::::!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::..  ..::::!!!!!!!!!!!!!!::::!!::::::::!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::..::::::!!!!!!::!!!!!!::!!::::::::::!!!!!!!!",
"****++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::..::!!!!!!!!!!!!::::::!!!!::!!::::::!!!!!!!!",
"********++**++++++++++++++++++++++++++++++++++++++!!!!++!!!!::::::!!!!!!!!!!!!::!!!!!!!!!!::::::!!!!!!!!!!",
"********++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!::::!!!!!!!!!!::!!::!!::::::!!!!!!!!!!",
"**********++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::!!!!::!!::!!::::::::!!!!!!!!!!",
"++**********++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::!!::!!!!::::::::::!!!!!!!!!!!!",
"************++++++**++**++++++++++**++++++++++++++++++++!!!!!!!!::::::::!!::::::!!!!::::::::::!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++!!!!!!!!!!!!::!!::!!!!::!!!!ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::::::::!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!::!!::::::::::::!!ee$$@@@@@@@@@@@@@@@@@@@@@@@@$$**::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::!!!!**$$$$@@@@@@@@@@@@@@@@@@@@ee!!::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::!!!!!!++$$@@@@@@@@@@@@@@@@@@@@**::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!!!**$$@@$$$$@@@@@@@@@@ee::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@@@@@$$!!::!!!!!!!!!!!!::!!!!!!::!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@**::!!!!!!!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@@@$$++!!!!!!!!!!!!!!!!::::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@$$!!!!!!!!!!!!!!!!!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::!!!!!!!!!!!!++++$$@@@@@@@@@@$$!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::!!!!!!!!++++**@@@@@@@@@@@@ee++!!!!!!!!++++++++++!!!!!!!!!!!!::",
"++++++++++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!++****$$$$@@@@@@@@@@$$++!!!!++++++++++++++++++++++++!!!!",
"++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!++**ee$$$$$$@@@@@@@@@@@@ee++!!!!!!!!::::::::!!++++++++!!!!",
"++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!++****ee$$$$@@@@@@@@@@@@@@$$**!!!!..          ..!!++++++++!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@ee!!!!..            ..!!++++++!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::!!**$$$$@@@@@@@@@@@@@@@@@@@@$$++!!::              !!!!++++++",
"++++++++++++++++++++++++!!++!!!!!!!!!!!!!!....++ee$$$$@@@@@@@@@@@@@@@@@@@@@@ee!!!!::::..        ::++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!::..::++$$@@$$@@@@@@@@$$@@@@@@@@@@@@$$++::::::::::......::++++++++",
"++++++++++++++++++++++++++++!!!!!!!!!!::..::::::!!ee@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::!!::::!!!!++++++",
"++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!::::++$$@@@@@@@@@@@@@@@@@@@@@@$$!!::::::::::!!!!!!!!!!++!!",
"++++++++++++++++++++++++++++!!++!!!!::::!!!!!!!!::::::!!**$$@@@@@@@@@@@@@@@@@@@@!!::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!::..::!!!!!!!!!!::::::!!++$$@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!::::::!!!!!!::::::::::::::!!$$@@@@@@@@@@@@@@!!..::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!::::::::::::::::::::::::::::!!$$$$$$@@@@@@ee::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!++!!!!::::::::::!!::::::::::::::::::$$@@@@@@$$::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!::::::::::::::!!$$$$$$++..::::::::..::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!::::::::++$$ee::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!::::::::!!++++!!!!!!!!!!::::::!!::..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::::!!!!!!!!!!!!!!::::::....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!++!!::::::::::!!!!!!!!!!!!!!::::::....::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!!!++++!!::::::::::::::!!!!!!!!!!!!::..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!++!!++++++++!!::!!::::::::::!!!!!!!!!!::....::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++!!++++++++!!::!!!!::::::::!!!!!!!!::....::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++!!!!++++++++!!!!::!!!!::::!!!!++!!!!....::::::!!!!::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++!!++++++++++++!!!!!!!!!!::!!!!!!!!!!::::::::!!!!!!!!!!::::::::::::::",
"++++++++++++++++++++++++++++++++++++++!!!!++++++++++++!!!!!!!!!!::!!!!!!!!::::::::::!!!!!!!!::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!::::::::::::!!!!!!::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++++++!!++++++++++++++!!!!!!::::!!!!!!::::::::::::::!!!!::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!++++++++++++!!!!!!!!::!!!!......::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++++++++!!!!!!::::::..........::::::::::::::!!::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++++++++++!!::......::::::::....::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++++++++!!::....::::::::::::..........::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++!!!!::..::::..::::::......      ::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::!!....::::::::::::::......::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::  !!**++  ::::::::::::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::$$$$!!....::::::::::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::ee$$::..::::::::::::!!!!!!!!::::::::::::::!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::!!**  ::..::::::!!!!!!!!!!!!!!::::::::::::!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::!!  ::::::::::!!!!::!!!!!!!!!!::::::::::!!!!!!!!",
"++****++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::..::::!!!!!!!!!!!!!!!!!!!!!!::!!::::::::!!!!!!",
"++++****++**++++++++++++++++++++++++++++++!!++++!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!::::!!!!!!!!!!::!!::!!::::::::!!!!!!!!",
"****++****++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::::::::::::!!::!!::!!!!::::::::!!!!!!!!",
"++**********++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++::::::::::::!!::!!!!::!!!!::::::!!!!!!!!!!",
"************++++++++++++++++++++++**++++++++++++++++++!!!!!!!!**..::::::::::::::!!!!::::::::::::!!!!!!!!!!"]
,[
"++++++++++++++++++++++++!!!!!!!!!!!!::!!!!!!!!!!!!!!ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::::::::!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!::!!!!::!!::!!!!!!ee@@@@@@@@@@@@@@@@@@@@@@@@@@ee++::::::::::::::!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!::!!!!!!!!!!!!ee$$@@@@@@@@@@@@@@@@@@@@$$**!!::::::::::!!::!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::!!!!!!**$$$$@@@@@@@@@@@@@@@@@@**::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::!!!!::!!++$$@@$$@@@@@@@@@@@@@@ee!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!::!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@$$!!!!!!!!!!!!!!!!::!!!!!!::!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@@@**!!!!!!!!!!!!!!!!::::::::::::!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@$$++!!!!!!!!!!!!!!!!!!::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::++$$@@@@@@@@@@$$++!!!!!!!!!!!!!!!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::!!!!!!!!!!!!!!!!$$@@@@@@@@@@$$++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@@@$$**!!!!!!!!++++++++++++!!!!!!!!!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++**$$@@@@@@@@@@$$**++!!!!++++++!!!!++++++!!++++!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++**$$@@@@@@@@@@@@@@ee**++++++::......::!!++++++++!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!**ee$$@@@@@@@@@@@@@@@@$$**++!!::            ::++++++++!!",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!****$$@@@@@@@@@@@@@@@@@@@@ee++!!::              !!++++++!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++**ee@@@@@@@@@@@@@@@@@@@@@@$$++!!!!..            ::++++++++",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!**ee$$@@@@@@@@@@@@@@@@@@@@@@@@ee!!!!!!::....      ::++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++$$@@$$@@@@@@@@$$@@@@@@@@@@@@@@**!!::::::::::..::::!!++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!::!!ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$!!::::::::::!!!!!!!!++++++",
"++++++++++++++++++++++++++++++!!!!!!!!::::..::!!ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$++::::::::!!!!!!!!!!!!++!!",
"++++++++++++++++++++++++++++++++!!!!!!::..::!!::::!!ee@@@@$$@@@@@@@@@@@@@@@@@@@@!!::::!!::::!!::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::!!!!::::!!!!!!++$$@@@@@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!::::!!::!!ee$$@@@@@@@@@@@@@@@@++..::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!..::!!!!!!!!!!!!::::::!!ee@@@@@@@@@@@@@@$$::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!++!!::..::!!!!!!::::!!!!::::::!!**$$@@@@@@@@@@++..::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!::::::::::::::!!!!::::::::::::++@@@@$$$$ee::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!!!!!::::::::::::**$$$$$$::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!::::::::::**$$!!..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!++!!!!!!!!::::::::::::....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!::....::!!!!!!!!!!!!!!!!!!::::::....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!++!!::::::::!!!!!!!!!!!!!!!!!!::::....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++++++!!::::::::::!!!!!!!!!!!!!!!!!!......::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++++++++!!::::::::::!!!!!!!!!!!!!!!!::....::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++!!++++++++!!!!!!!!::::!!!!!!!!!!!!!!::....::::::!!!!::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!++!!++++++++!!!!!!!!!!!!::::!!++!!!!!!::..::::::!!!!!!!!!!::::::::::::",
"++++++++++++++++++++++++++++++++++++++!!++++++++++!!!!!!!!!!::::++!!++!!!!::::::::::::!!!!!!!!::::::::::!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!!!::::::::::::!!!!!!!!::::::::::!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::!!!!!!!!::::::::::::::!!!!!!!!::::::::!!",
"++++++++++++++++++++++++++++++++++++++++++!!++++++++++++!!!!!!!!::!!!!!!..  ....::::::::::!!!!::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!++++++++++++++!!!!!!::::!!::............::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!++++++++++++!!!!!!::..::......::::::::..::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!++!!++++++++++++++!!!!::  ..::::::::::::::::........::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++++++!!!!::....::::::::::::::......    ::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++++!!!!....::::::::::::::......::....::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++++..::::::::::::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::++ee**..::::::::::::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!++!!!!::..++$$$$++..::::::::::::::!!!!!!::!!::::::::::::!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++++++!!!!!!..++$$$$!!::::::::::::!!!!!!!!!!!!::!!::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::$$$$::::::::::::!!!!!!!!!!!!!!!!::!!::::::::!!!!!!",
"++++**++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::!!$$::::::!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!!!!!",
"++++++**++**++++++++++++++++++++++++++++++++!!!!!!!!!!::::++::!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::..::::!!::!!!!!!!!!!!!!!!!!!!!::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::::::!!!!!!::!!::!!!!!!::::::!!!!!!!!",
"++**++++****++++++++++++++++++++++++++++++++++!!++!!!!!!!!::++::::::::::::::!!::!!!!!!!!!!::::::!!!!!!!!!!",
"**********++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::**++..::::::::::!!!!!!!!::!!::::::::!!!!!!!!!!"]
,[
"++++++++++++++++++++++++!!!!!!::::::::::::::!!!!!!!!**@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::::::::::!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!::!!::!!!!::!!!!!!ee$$@@@@@@@@@@@@@@@@@@@@@@$$**!!::::::::::::::!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!::!!!!!!!!!!::!!!!ee@@@@@@@@@@@@@@@@@@@@@@$$++::::::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!::!!::::!!!!!!ee$$$$@@@@@@@@@@@@@@@@ee++::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!::!!**$$$$$$@@@@@@@@@@@@$$++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!::++$$$$@@@@@@@@@@@@@@**!!!!!!!!!!!!!!!!::!!::!!::!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::!!!!!!::!!$$@@@@@@@@@@@@@@$$++!!!!!!!!!!!!!!!!::::::::::::!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@$$!!!!!!!!!!!!!!!!!!!!::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@@@**!!!!!!!!!!!!!!!!!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@**++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::!!!!!!!!!!!!!!$$@@@@@@@@@@****!!!!++!!++++++++++++!!!!!!!!!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@ee**++!!++++++++!!!!++++++!!++++!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@$$**++++++!!::......::!!++++++++!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++**$$@@@@@@@@@@@@$$**++!!              ::++++++++!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++****$$@@@@@@@@@@@@@@$$ee++!!..            ..!!++++++!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!**ee$$@@@@@@@@@@@@@@@@@@$$++!!::..            !!++++++++",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++ee$$$$@@@@@@@@@@@@@@@@@@$$**++!!!!::::..      !!++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++ee$$$$@@@@@@@@$$@@@@@@@@@@@@$$++!!::::::::::::::::++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!::!!**$$$$@@@@@@@@@@@@@@@@@@@@$$@@@@ee!!::::::::!!!!!!!!!!++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!::++ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@ee::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!::++$$$$@@@@@@@@$$@@@@@@@@@@@@@@@@@@ee::::::::::!!!!::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!::..::**$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$++::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!::..::!!::!!**$$@@@@@@$$@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::!!!!::!!::++ee@@@@@@@@@@@@@@@@@@@@!!::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!::::!!!!!!::::!!!!!!**$$@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!::!!!!**@@$$@@@@@@$$$$!!::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!::!!::++$$@@$$@@$$ee..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!::::!!!!!!::!!!!!!!!::::::::++$$$$$$$$::..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!::::::::!!::!!!!!!::::::::::::!!$$$$!!..::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!::::::::::::::!!  ..::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!::::!!!!++++++!!!!!!!!::::::::::  ..::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!++!!!!::::::!!!!!!!!!!!!!!::!!::::::..  ..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!++++::::::::!!!!!!!!!!!!!!!!!!!!!!::  ....::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++!!++!!::::::::!!!!!!!!!!!!!!!!!!!!::....::::::::::!!::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++++++!!::::::::::!!!!!!!!!!!!!!!!!!::..::::::::::!!!!!!!!::::::::::::",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++++++++!!!!!!++++++!!!!!!!!::::::::!!++++!!!!::::::::::::::::!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++++++++++!!++++++++++!!!!!!!!!!::::!!!!!!!!!!::..::::::::::::::!!!!!!::::::::!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!..    ..::::::::::::!!::::::::::!!",
"++++++++++++++++++++++++++++++++++++++!!++++++++++++!!!!!!!!::::::!!!!::  ..........::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::......::::::::..::::::::::!!::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!++++++++++++++!!!!!!::..  ....::::::::::::::::::::..::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!++++++++++++++!!!!!!  ....::::::::::::::::::....  ..::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!++++++++++++++!!!!::......::::::::::::::....::......::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!++++++++++++!!::..::::::::::::::::::::..::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++++++++!!..::::::::::::::::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!++!!!!!!!!++++**..::::::::::::::::::::!!::::::::::::::::!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++++++!!!!::::**$$ee..::::::::::::::!!!!!!!!!!!!::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::$$$$ee..::::::::::!!!!!!!!!!!!!!!!::!!::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::$$$$ee..::::!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!!!",
"++++++**++**++++++++++++++++++++++++++++++!!!!!!!!::::++$$ee::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::ee**::!!::!!::!!!!!!!!!!!!!!!!!!!!::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!**::!!::::::!!::!!!!!!::!!!!!!!!!!::::::!!!!!!!!",
"++**++++**++++++++++++++++++++++++++++++++++++!!!!!!!!!!::**!!::::::!!::::::!!!!!!::!!!!!!::::::!!!!!!!!!!",
"********++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::++ee::::::::::!!::!!!!!!!!!!!!::::::::!!!!!!!!!!"]
,[
"++++++++++++++++++!!++!!!!!!!!::::::::!!!!!!++++++!!++$$$$@@@@@@@@@@@@@@@@@@@@@@@@ee++!!::::::::::!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!::::!!!!::!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@@@$$++::::::::::::::::!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!::::!!!!::::!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@**!!::::::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@**!!::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@$$@@@@@@@@@@@@ee!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@$$++!!!!!!!!!!!!!!!!::!!::!!::!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::!!!!!!::++$$$$@@@@@@@@@@@@ee!!!!!!!!!!!!!!!!!!::::::::::::!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$@@@@@@@@@@@@**!!!!!!!!!!!!!!!!!!!!::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@$$++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@$$@@@@$$++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@$$**++!!!!++++++++++++++++!!!!!!!!!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@$$**++++++++++++++!!!!++++++!!++++!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@@@$$**++++!!::........::!!++++++++!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@@@$$ee++++::            ..!!++++++!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@$$**++::              !!++++++++!!",
"++++++++++++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@$$**++!!::            ..++++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@$$++++!!::::::..    ..!!++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@$$@@@@@@@@@@@@ee++!!::::::::::::::!!++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++ee$$@@@@$$@@@@@@@@@@@@@@@@@@$$++!!::::::::!!!!!!!!++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@++::::::::::!!!!!!!!!!!!++!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$++::::::::!!::!!::::::!!::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!::..++$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::..::::!!**@@@@@@@@@@@@@@@@@@@@@@@@$$::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!++$$@@@@@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!++!!!!!!::!!!!!!!!!!!!::!!ee@@$$@@$$@@@@@@$$$$::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!**$$@@@@@@$$@@$$++..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!::!!!!++$$$$@@$$@@**::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!::!!!!!!!!!!!!!!!!!!::::!!!!ee@@$$$$::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!::::::::!!**$$!!....::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!::::::::::::::!!......::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!::::!!!!!!++++!!!!!!::::::::::::..  ....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!::::::!!++++!!!!!!!!!!!!!!::::::    ..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::..::!!!!!!!!!!!!!!!!!!!!!!!!::......::::::::::::!!::::::::::::::::",
"++++++++++++++++++++++++++++++++!!++++!!::::::!!!!!!!!++!!!!!!!!!!!!!!::....::::::::::::!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::!!!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::!!!!!!!!!!!!....::::::::::::::!!!!!!::::::::!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!!!      ..::::::::::::!!::::::::::!!",
"++++++++++++++++++++++++++++++++++!!!!++++++++!!!!!!!!!!!!::::::!!!!!!..        ....::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::..::!!::................::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..........::::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!..  ....::::::::::::::::::::....  ::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!++++++++++++++!!!!!!......::::::::::::::::::::::....  ..::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!++++++++++++++!!!!....::::::::::::::::::::....::::....::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!++++++++++++!!::..::::::::::::::::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++++++!!::::::::::::::::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!++!!++**::::::::::::::::::::::!!!!!!!!!!::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!::++ee$$::::::::::::::::!!!!!!!!!!!!!!::!!::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!..ee$$$$::::::::::!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!!!",
"++++++++++**++++++++++++++++++++++++++++++!!!!!!::..**$$$$::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!..!!@@$$!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..**$$!!!!!!::!!::!!::!!!!!!!!!!!!!!!!!!::::::!!!!!!!!",
"++**++++**++++++++++++++++++++++++++++++++++++!!!!::::::$$**!!::::::::!!::!!!!!!!!::!!!!!!::::::!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::++$$!!::::::::::!!::!!!!!!!!!!!!::::::::!!!!!!!!!!"]
,[
"++++++++++++++++++!!++!!!!!!!!::::::::!!!!!!++++++!!++$$$$@@@@@@@@@@@@@@@@@@@@@@@@ee++!!::::::::::!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!::::!!!!::!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@@@$$++::::::::::::::::!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!::::!!!!::::!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@**!!::::::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@**!!::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@$$@@@@@@@@@@@@ee!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@$$++!!!!!!!!!!!!!!!!::!!::!!::!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::!!!!!!::++$$$$@@@@@@@@@@@@ee!!!!!!!!!!!!!!!!!!::::::::::::!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$@@@@@@@@@@@@**!!!!!!!!!!!!!!!!!!!!::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@$$++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@$$@@@@$$++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@$$**++!!!!++++++++++++++++!!!!!!!!!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@$$**++++++++++++++!!!!++++++!!++++!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@@@$$**++++!!::........::!!++++++++!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@@@$$ee++++::            ..!!++++++!!!!",
"++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@$$**++::              !!++++++++!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@$$**++!!::            ..++++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@$$++++!!::::::..    ..!!++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@$$@@@@@@@@@@@@ee++!!::::::::::::::!!++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++ee$$@@@@$$@@@@@@@@@@@@@@@@@@$$++!!::::::::!!!!!!!!++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@++::::::::::!!!!!!!!!!!!++!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$++::::::::!!::!!::::::!!::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!::..++$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::..::::!!**@@@@@@@@@@@@@@@@@@@@@@@@$$::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!++$$@@@@@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!++!!!!!!::!!!!!!!!!!!!::!!ee@@$$@@$$@@@@@@$$$$::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!**$$@@@@@@$$@@$$++..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!::!!!!++$$$$@@$$@@**::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!::!!!!!!!!!!!!!!!!!!::::!!!!ee@@$$$$::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!::::::::!!**$$!!....::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!::::::::::::::!!......::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!::::!!!!!!++++!!!!!!::::::::::::..  ....::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!::::!!!!++++!!!!!!!!!!!!!!::::::    ..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::..::!!!!!!!!!!!!!!!!!!!!!!!!::......::::::::::::!!::::::::::::::::",
"++++++++++++++++++++++++++++++++!!++++!!::::::!!!!!!!!++!!!!!!!!!!!!!!::....::::::::::::!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::!!!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::!!!!!!!!!!!!....::::::::::::::!!!!!!::::::::!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!!!      ..::::::::::::!!::::::::::!!",
"++++++++++++++++++++++++++++++++++!!!!++++++++!!!!!!!!!!!!::::::!!!!!!..        ....::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::..::!!::................::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..........::::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!..  ....::::::::::::::::::::....  ::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!++++++++++++++!!!!!!......::::::::::::::::::::::....  ..::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!++++++++++++++!!!!....::::::::::::::::::::....::::....::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!++++++++++++!!::..::::::::::::::::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++++++!!::::::::::::::::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!++!!++**::::::::::::::::::::!!!!!!!!!!!!::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!::++ee$$::::::::::::::::!!!!!!!!!!!!!!::!!::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!..ee$$$$::::::::::!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!!!",
"++++++++++**++++++++++++++++++++++++++++++++!!!!::..**$$$$::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!..!!@@$$!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..**$$!!!!!!::!!::!!::!!!!!!!!!!!!!!!!!!::::::!!!!!!!!",
"++****++**++++++++++++++++++++++++++++++++++++!!!!::::::$$**!!::::::::!!::!!!!!!!!::!!!!!!::::::!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::++$$!!::::::::::!!::!!!!!!!!!!!!::::::::!!!!!!!!!!"]
,[
"++++++++++++++!!!!!!!!!!!!!!::::::!!!!++++**++++++++++!!!!++++ee$$@@@@@@@@@@@@$$ee++::::::::::::::!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!::::::!!++++++++++++++!!!!++**ee$$@@@@@@@@@@@@ee++!!::::::::::::::::!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!::::::::::!!++++++++**++!!!!++**$$@@@@@@@@@@@@ee++::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!::!!++++++++++++!!!!++ee@@@@@@@@@@@@ee!!::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!::!!::!!!!++**++**++!!!!**$$$$@@@@@@@@ee!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!!!!!!!!!!!!!::::::!!++++++++++!!!!$$$$@@@@@@@@$$++!!!!!!!!!!!!!!!!!!::::!!::!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!::::!!!!!!!!!!!!!!**$$$$@@@@@@@@**!!!!!!!!!!!!!!!!::::::::::::!!::::!!!!",
"++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!**$$$$@@@@@@@@++!!!!!!!!!!!!!!!!!!::::::::::::::::::!!",
"++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!**$$@@@@@@@@$$++!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@$$@@@@ee++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!",
"++++++++++++++++++++++++++!!!!!!!!!!::!!!!!!!!!!!!!!++$$@@$$@@@@ee++!!!!!!!!++++++++++++++++!!!!!!::::::::",
"++++++++++++++++++++++++!!++!!!!!!::::!!!!!!!!!!!!!!++$$@@@@@@@@ee++!!++++++++++++++++++++++++++!!!!::::::",
"++++++++++++++++++++++++++!!++!!!!::!!!!!!!!!!!!!!!!!!$$@@@@@@@@$$**++++++++!!!!::::!!!!!!++++++++!!!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@ee**++!!::          ..!!++++++++!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@$$**++!!              ..!!++++++++!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@$$++++::              !!++++++++++!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!@@@@@@@@@@@@ee++!!::::....      ::++++++++++!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!@@@@@@@@@@@@$$++!!!!::::::::..::!!++++++++++!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**@@@@@@@@@@@@@@ee++!!!!!!::!!!!!!!!++++++++++!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@@@$$++::!!::::!!!!!!!!!!++++++!!!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++$$@@$$@@@@@@@@@@@@@@++::::::::!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@@@@@@@$$!!::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!$$$$@@@@@@@@@@@@@@@@@@**::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@@@@@@@@@@@@@!!::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!++@@@@@@@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!::!!++@@@@@@@@@@@@@@@@@@@@@@@@::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!$$$$@@@@@@@@@@@@$$@@@@@@++::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@$$!!::::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!::::..!!**$$@@@@@@@@@@$$$$::::::::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!::..::::::++$$@@$$@@@@$$++  ......::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!ee@@$$$$$$........::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!::::!!!!::!!!!::!!**$$$$$$............::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!::::!!::!!$$ee  ........::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!::::::!!::!!!!  ......::::::::::::::!!::!!::::::::::::!!!!",
"++++++++++++++++++++++++++++++!!::::::!!!!++!!!!!!::!!!!::::::..  ..::::::::::::!!!!!!!!!!::::::::::::::!!",
"++++++++++++++++++++++++++++++!!::::::!!!!++!!!!!!!!::::::::::::....::::::::::::::!!!!!!!!::::::::::::::!!",
"++++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!::!!!!::::..::::::::::::::!!!!!!!!!!::::::::::!!::",
"++++++++++++++++++++++++++++++!!!!::::!!!!++++!!!!!!!!!!!!!!!!!!::........::::::::::!!!!!!!!::::::::!!::!!",
"++++++++++++++++++++++++++++++!!!!!!::::!!!!++!!!!!!!!!!!!!!!!!!!!::      ....::::::::::!!::::::::::::!!!!",
"++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!..  ..........::::::::!!::::::::::::!!!!",
"++++++++++++++++++++++++++++++!!!!++!!..::::!!!!!!++!!!!!!!!!!!!!!..............::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++!!++++!!!!::::::::!!!!!!!!!!!!!!!!::................::::::::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!::::::::::::!!!!!!!!::..  ................::::::::::!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::..........::..::::::::......::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::............::::::::::::::::::..  ::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::..  ....::::::::::::::::::::::....::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!......::::::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!++++++++++++!!!!!!!!::....::::::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::::::::::::::!!::!!::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::..::::::::::::!!::!!!!!!!!::::::::::::!!!!!!!!!!!!",
"++++++++++**++++++++++++++++++++++++++!!++++++++++++!!::::::::::::::!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!++**++::::::!!!!!!!!!!!!!!!!!!!!!!!!!!::!!!!!!!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!ee$$**::::!!!!!!!!!!::!!!!!!!!!!!!::!!::!!::!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!++$$$$**::!!!!!!!!::::::::::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!"]
,[
"++++++++++++++!!!!!!!!!!!!!!::::::!!!!++++**++++++++++!!!!++++ee$$@@@@@@@@@@@@$$ee++::::::::::::::!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!::::::!!++++++++++++++!!!!++**ee$$@@@@@@@@@@@@ee++!!::::::::::::::::!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!::::::::::!!++++++++**++!!!!++**$$@@@@@@@@@@@@ee++::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!::!!++++++++++++!!!!++ee@@@@@@@@@@@@ee!!::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!::!!::!!!!++**++**++!!!!**$$@@@@@@@@@@ee!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!!!!!!!!!!!!!::::::!!++++++++++!!!!$$$$@@@@@@@@$$++!!!!!!!!!!!!!!!!!!::::!!::!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!::::!!!!!!!!!!!!!!**$$$$@@@@@@@@**!!!!!!!!!!!!!!!!::::::::::::!!::::!!!!",
"++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!**$$$$@@@@@@@@++!!!!!!!!!!!!!!!!!!::::::::::::::::::!!",
"++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!**$$@@@@@@@@ee++!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@$$@@@@ee++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!",
"++++++++++++++++++++++++++!!!!!!!!!!::!!!!!!!!!!!!!!++$$@@$$@@@@ee++!!!!!!!!++++++++++++++++!!!!!!::::::::",
"++++++++++++++++++++++++!!++!!!!!!::::!!!!!!!!!!!!!!++$$@@@@@@@@ee++!!++++++++++++++++++++++++++!!!!::::::",
"++++++++++++++++++++++++++!!++!!!!::!!!!!!!!!!!!!!!!!!$$@@@@@@@@$$**++++++++!!!!::::!!!!!!++++++++!!!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@ee**++!!::          ..!!++++++++!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@$$**++!!              ..!!++++++++!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@$$++++::              !!++++++++++!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!@@@@@@@@@@@@ee++!!::::....      ::++++++++++!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!@@@@@@@@@@@@$$++!!!!::::::::..::!!++++++++++!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**@@@@@@@@@@@@@@ee++!!!!!!::!!!!!!!!++++++++++!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@@@$$++::!!::::!!!!!!!!!!++++++!!!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++$$@@$$@@@@@@@@@@@@@@++::::::::!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@@@@@@@$$!!::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!$$$$@@@@@@@@@@@@@@@@@@**::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@@@@@@@@@@@@@!!::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!++@@@@@@@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!::!!++@@@@@@@@@@@@@@@@@@@@@@@@::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!$$$$@@@@@@@@@@@@$$@@@@@@++::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@$$!!::::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!::::..!!**$$@@@@@@@@@@$$$$::::::::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!::..::::::++$$@@$$@@@@$$++  ......::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!!!ee@@$$$$$$........::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!::::!!!!::!!!!::!!**$$$$$$............::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!::::!!::!!$$ee  ........::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!::::::!!::!!!!  ......::::::::::::::!!::!!::::::::::::!!!!",
"++++++++++++++++++++++++++++++!!::::::!!!!++!!!!!!::!!!!::::::..  ..::::::::::::!!!!!!!!!!::::::::::::::!!",
"++++++++++++++++++++++++++++++!!::::::!!!!++!!!!!!!!::::::::::::....::::::::::::::!!!!!!!!::::::::::::::!!",
"++++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!::!!!!::::..::::::::::::::!!!!!!!!!!::::::::::!!::",
"++++++++++++++++++++++++++++++!!!!::::!!!!++++!!!!!!!!!!!!!!!!!!::........::::::::::!!!!!!!!::::::::!!::!!",
"++++++++++++++++++++++++++++++!!!!!!::::!!!!++!!!!!!!!!!!!!!!!!!!!::      ....::::::::::!!::::::::::::!!!!",
"++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!..  ..........::::::::!!::::::::::::!!!!",
"++++++++++++++++++++++++++++++!!!!++!!..::::!!!!!!++!!!!!!!!!!!!!!................::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++!!++++!!!!::::::::!!!!!!!!!!!!!!!!::................::::::::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!::::::::::::!!!!!!!!::..  ................::::::::::!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::..........::..::::::::......::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::............::::::::::::::::::..  ::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::..  ....::::::::::::::::::::......::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!......::::::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!++++++++++++!!!!!!!!::....::::::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::::::::::::::!!::!!::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::..::::::::::::!!::!!!!!!!!::::::::::::!!!!!!!!!!!!",
"++++++++++**++++++++++++++++++++++++++!!++++++++++++!!::::::::::::::!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!++**++::::::!!!!!!!!!!!!!!!!!!!!!!!!::!!!!!!!!!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!ee$$**::::!!!!!!!!!!::!!!!!!!!!!!!::!!!!!!::!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!++$$$$**::!!!!!!!!::::::::::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!"]
,[
"++++!!!!!!!!!!!!::::::::::::::::::!!!!++++++++++++++++++++++++++++++++**$$@@$$**!!::::::::!!::!!!!!!!!!!!!",
"++++!!++!!!!!!!!!!!!::::::::!!::::!!!!++++++++++++++++++++++++++++++++**ee**!!::::::::::::::!!!!!!!!!!!!!!",
"++!!++++!!!!!!!!!!!!::::::::::::::!!!!++++++++++++++++++++++!!++++++++!!!!::::::::::::::!!::!!!!!!!!!!!!!!",
"++++++!!++!!!!!!!!!!::::::!!!!::::!!++++++++++++++++++++++!!!!!!++++::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++!!!!!!!!!!::::::::::::::!!++++++++++++++++++++!!!!!!++!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!!!!!!!::!!::::::::!!++++++++++++++++++!!!!++++!!!!::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!!!!!!!!!!!!!!!::::!!++++****++++++++++++++**++!!!!!!!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!!!!!!!!!!!!!::!!::!!++++++++++++++!!!!++eeee!!!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++!!!!!!!!!!!!!!!!::!!++++**++++++++!!++ee$$++!!!!!!!!!!!!!!!!::!!::::::::::::::!!!!!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++****++++++!!!!**$$$$!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!",
"++++++++++++++++!!!!!!!!!!!!!!!!::!!++++++++++!!!!!!ee$$$$!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!",
"++++++++++++++++!!++!!!!!!!!!!!!::!!!!++++++++!!!!++$$$$$$!!!!!!!!!!!!!!!!++++!!++!!!!!!!!::::::::!!!!!!!!",
"++++++++++++++++!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!++@@@@$$!!!!!!!!!!++++++++++++++!!++!!!!!!::::::::!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!::!!!!!!!!!!!!**$$$$$$!!!!!!++++++!!!!!!!!++++++!!++++!!!!!!!!!!!!!!::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@++!!!!++!!::..      ::!!++++++++++!!!!!!!!!!::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$$$ee!!++++::            ..!!++++++++!!!!!!!!!!::::",
"++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!++$$$$$$@@**++++::              ::++++++++!!!!!!!!!!::::",
"++++++++++++++++++++++++!!!!!!!!!!::!!!!!!!!!!!!!!!!ee@@@@@@$$**++++::....        ..++++++++++!!!!!!::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@**++!!::::::::......!!++++++++++!!!!!!::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@$$++!!!!!!::!!::!!!!!!++++**++!!!!::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@ee!!!!!!!!!!!!!!!!!!++++++++::::!!::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++@@@@@@@@@@ee!!::::!!!!!!!!!!!!!!!!!!!!::::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++@@@@@@@@@@**::::::::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!**@@@@@@@@@@::::::::::::::::::::::::::::::::::::!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@ee::......::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@@@!!........::::::::::::::::::::::::::::!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++!!$$@@@@@@@@$$++::::::::::::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!ee$$@@@@@@ee!!::::::::::::::::::::::::::::::::::::!!!!::!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!**$$@@$$@@**....::::::::::::::::::::::::::::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!++$$$$$$@@ee......::::::::::::::::::::::::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!$$$$$$@@$$::......::::::::::::::::::::::::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!ee@@@@@@$$$$..........::::::::::::::::::::::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!ee$$@@$$@@ee......::..::::::::::::::::::::::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!::::::**@@$$@@ee......::::::::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!......::**$$@@$$..::::::::::::::::!!!!!!::!!::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!::....::::::++$$$$..::::::::::::::::!!!!!!!!!!!!::::::::::::!!::!!!!!!",
"++++++++++++++++++++++++++++++!!!!::....::::::!!!!!!ee..::::::::::::::::!!!!!!!!!!::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!!!::!!..  ..::::::::::::::!!!!!!!!!!::::::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!::          ......::::!!!!!!!!!!::!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!::::::            ....::!!!!!!!!!!::::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!..::!!!!!!!!!!!!!!!!!!!!::::            ..::::::::!!::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!::..::::::!!!!!!!!!!!!::!!::::..          ..::::!!::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!::::::::::!!!!!!!!::!!!!!!::::::            ..::::::::::::::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!::::::!!!!++!!!!!!!!!!!!!!::::::..  ....    ..::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!::::!!!!++!!!!!!!!!!!!!!!!::::..........    ..::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!::..::!!!!!!!!!!!!!!!!::::::..........          ..::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!++!!::::::!!!!!!!!!!!!!!::..................        ::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++!!::::::::::!!!!!!::..........::..::::........::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++!!!!::::::::::::..........::::::::::......::..::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!::..  ......::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++**++++++++++++++++++++++++!!!!!!!!!!::......::::::::::::::::::::::::!!::!!!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::::::::::::::!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::::::::!!!!!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!::::::::::!!!!!!!!!!!!::!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"!!!!!!!!!!!!::!!::::::::::::::!!::!!++++++++++++++++++++++++++++++++++ee$$ee++!!::::::::!!!!!!!!!!!!!!!!!!",
"!!!!!!!!!!!!::::::::!!::::::!!::!!!!++++++++++++++++++++++++++++++++++++**!!::::::::::::!!!!!!!!!!!!!!!!!!",
"++!!!!!!!!!!!!!!::!!::::::::::::!!!!++++++++++++++++++++++++++++++++++::::::::::::!!::!!!!!!!!!!!!!!!!!!!!",
"++!!++!!!!!!!!!!::::::::::::::::!!!!++++++++++++++++++++++!!!!++++++::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++!!!!!!!!!!!!::::::::::::::!!++++++++++++++++++++++!!!!!!++++::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++!!!!!!!!!!::::::::::::::!!!!++++++++++++++++++++!!!!++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++!!!!!!!!!!!!!!::!!!!!!::!!!!++++**++++++++++++++++++!!!!::!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++!!!!!!!!!!!!!!!!!!::!!::!!++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++!!!!!!!!!!::!!::::::!!++++++**++++++++!!++++!!::!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!::",
"++++++++++!!++!!!!!!!!!!!!!!::::!!++++****++++++!!!!**ee!!!!!!!!!!!!!!!!!!!!::::::::::::::::::!!!!!!!!!!::",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!++**++++++++!!!!++$$**!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!++**++++++++!!!!ee$$**!!!!!!!!!!!!!!!!!!++!!!!!!!!!!::::::::!!!!!!!!!!::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++++++++!!!!$$@@**!!!!!!!!!!!!++++++++++++++!!!!!!!!!!::::::::!!::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++!!!!!!++$$$$ee!!!!!!!!++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::",
"++++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!++@@$$ee!!!!++++++!!!!::::::!!++!!++++++!!!!!!!!!!!!!!::::",
"++++++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!!!**$$$$$$++!!++!!::            ::++++++++++!!!!!!!!!!::::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$$$ee!!++!!                !!++++++!!!!!!!!!!!!!!::::",
"++++++++++++++++++++!!++!!!!!!!!::!!!!!!!!!!!!!!++$$$$@@$$**++++::              !!++++++++++!!!!!!::::::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$$$$$$$ee++++!!::::::..    ::++++++++++++!!!!::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$@@@@@@$$**++!!!!!!!!!!::::!!++++++++++!!!!!!::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@$$++!!::!!!!!!!!!!!!!!++++++!!::::::::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$$$@@@@@@**!!::!!!!!!!!!!!!!!!!!!!!!!::::::::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@++::::::::::!!!!!!::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@$$::::::::::::::::::::::::::::::::::::::!!::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::**$$@@@@@@++....::..::::::::::::::::::::::::::!!!!::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++$$@@$$@@$$::..::....::::::::::::::::::::::::::!!::!!::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@ee::::::::::..::::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++!!++++!!!!!!!!!!!!!!$$@@@@@@++::::::::::::::::::::::::::::::::::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!ee$$@@ee::......::::::::::::::::::::::::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!**$$$$$$::......::::::::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!++$$@@$$**........::::::::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!$$$$@@@@++..........::::::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!ee@@$$@@$$!!..::..::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!$$$$$$@@$$!!..::::::::::::::::::::::::::::::::::::::!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!::++$$@@$$$$!!..::::::::::::::::!!!!::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!::::::++$$$$@@!!::::::::::::::::!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::......::++$$$$++::::::::::::::::::!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!::......::::!!!!$$ee::::::::::::::::::!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!::::::::::::!!!!!!!!$$!!      ....::::::!!!!!!!!!!::!!::!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!::::::::!!!!!!::!!::::!!          ..::::::!!!!!!!!::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!::::              ..::::!!!!!!!!!!::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!::..            ....::::::!!::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!....::::!!!!!!!!!!!!!!!!::::..            ..::::::::::!!::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!....::!!!!!!!!!!::::::::::::..              ..::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!::..::::::!!!!!!!!::::::::::::::..              ..::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!::::::::::::                ..::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!::..::!!!!!!!!!!!!!!!!::::::::..                  ..::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!::::::!!!!!!!!!!!!::::..................      ..::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!++!!::::::::!!!!!!!!::............::::..........::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!++!!!!::::::::::!!::..........::::::::::..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++**++++++++++!!++++++!!!!!!!!::::............::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!::........::::::::::::::::::::!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!::..::::::::::::::!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!::::::::::::::::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++**++++++++++++++++++++++++++++!!!!::::::::::::::!!!!!!!!!!!!::!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"!!!!!!!!!!!!::!!::::::::::::!!!!!!!!++++++++++++++++++++++++++++++++++ee++!!::::::::::::!!!!!!!!!!!!!!!!!!",
"!!!!!!!!!!!!!!::!!::!!::::::!!!!!!!!++++++++++++++++++++++++++++++++!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++!!!!!!!!!!!!!!::!!::::::::!!!!!!!!++++++++++++++++++++++++++++++++::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++!!!!!!!!!!!!::::::::::::::::!!++!!++++++++++++++++++++++++++++++!!::::::::::!!::::!!!!!!!!!!!!!!!!!!!!!!",
"++++!!!!!!!!!!!!::::::::::::::!!!!++++++++++++++++++++++!!!!!!++!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++!!!!!!!!!!!!::::::::::::!!!!++++++++++++++++++++!!!!!!++!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++!!!!!!!!!!::::::::!!::::!!!!++++++**++++++++++!!++++++!!!!::::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!::",
"++++++++!!!!!!!!!!!!!!!!!!::!!!!++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!::::",
"++++++!!++++!!!!!!!!!!!!!!::::!!++++++++**++++++++++++!!::::!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!!!::::",
"++++++++++!!!!!!!!!!!!!!::!!::!!++++++****++++++!!++++::::!!!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!::::::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!++++**++++++++!!!!$$++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!!!!!!!!!::::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!++**++**++++++!!**$$++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::!!!!!!!!!!::::",
"++++++++++++++++!!!!!!!!!!!!::::!!++**++++++!!!!ee$$++!!!!!!!!!!!!!!++++++++!!++++!!!!!!!!::::!!!!!!::::::",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!++++++++++!!!!$$$$**!!!!!!!!++++++++++++++++++++++!!!!!!!!!!!!!!::::::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$**!!!!!!++++++!!!!::::!!++++!!++++!!!!!!!!!!!!::::::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$$$!!!!++!!..          ..!!!!++++++++!!!!!!!!!!::::::::",
"++++++++++++++++++!!!!!!!!!!!!::::!!!!!!!!!!!!++$$$$$$**!!++!!              ..++++++++!!!!!!!!!!::::::::::",
"++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$@@$$++++++..              !!++++++++++++!!!!::::::::::",
"++++++++++++++++++++++!!!!!!!!!!::!!!!!!!!!!!!++$$$$$$$$$$++++!!::::::::......!!++++++++++++!!!!!!::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$$$$$@@$$**!!::!!!!!!!!!!!!!!++++++++++!!!!::::::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@$$++!!!!!!!!!!!!!!!!!!++++++!!!!!!::::::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$$$@@@@@@++::::::!!!!!!!!!!!!!!!!!!::::::::::::!!::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$$$@@$$!!::::::::!!::!!!!!!!!::::::::::::::::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++@@$$@@@@**::::::::::::::::::::::::::::::::::::::::!!::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::++@@$$@@$$!!::..::::::::::::::::::::::::::::!!!!::::::!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::ee@@@@$$++....::::::::::::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!**@@$$$$!!::::::::::::::::::::::::::::::::::!!::::!!::!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!**@@$$++::::::::::::::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++$$$$::....::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!++!!ee$$++  ......::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!**$$@@::........::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!++**@@$$$$....::....::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!++$$$$@@$$..::::::..::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!ee$$$$@@$$....::::::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!++$$$$$$$$$$::::..::::::::::::!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!::::**$$@@$$$$!!::::::::::::::::!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!::::..::ee$$@@$$++::::::::::::::::!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!::....::::**@@@@$$..::::::::::::::::!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!::....::::::!!**@@$$++        ..::::::!!!!!!!!!!!!::!!::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!::::::::::::!!::++@@ee          ....::::!!!!!!!!::!!::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!::::::!!!!!!!!::!!::++ee          ....::!!!!!!!!!!::!!::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!::::..++            ..::::!!!!!!!!::::::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!::::..              ::::::::!!::::!!!!::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!....::!!!!!!!!!!!!!!::::::..              ..::::::::::::::!!::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++::..::::::!!!!!!!!!!::::::::::..              ..::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!::..::::::!!!!!!!!!!::::::::::..                  ::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!::..::!!!!!!!!!!!!::::::::::::..                  ::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!..::::!!!!!!!!!!!!::::::::..::..                ::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!++!!++::::::!!!!!!!!!!!!::::::................    ....::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++!!::::::!!!!!!!!::::............::::........::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++**++++++++++++++!!::::::::::::::........::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!++++++!!!!!!::::::........::..::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!::........::..::::::::::!!!!::!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!::::..::::::::::!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!::::::::::::::!!!!!!!!!!::!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"!!!!!!!!!!!!::!!::::::::::!!!!!!!!!!++++++++++++++++++++++++++++++!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"!!!!!!!!!!!!::::::::!!::::!!!!!!!!!!++++++++++++++++++++++++++++++!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"!!!!!!!!!!!!!!::::::::::::::!!!!!!++++++++++++++++++++++++++++++++!!::::::::::::::!!!!!!!!!!!!!!!!!!!!::!!",
"++!!!!!!!!!!!!::::::::::::::!!!!!!++++++++++++++++++++++++++++++!!::::::::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++!!!!!!!!!!!!!!::::::::::::!!!!++++++++++++++++++++++++!!!!++!!::::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!::",
"++++++!!!!!!!!!!!!::::::::::!!!!++++++++++++++++++++++!!++!!!!!!::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++!!!!!!!!!!::::::::!!::!!!!++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!::::",
"++++++!!!!!!!!!!::!!!!!!!!::!!!!++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!::::::",
"++++++!!!!!!!!!!!!::!!!!::::::!!++++++++++++++++++++!!!!::!!!!!!!!!!!!::::::::::::::::::::!!!!!!!!!!!!::::",
"++++++++!!!!!!!!!!!!!!!!::!!::!!++++++****++++++++++!!!!::!!!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!::::::",
"++++++++!!!!!!!!!!!!!!!!!!!!!!!!++******++++!!!!**ee!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::!!!!!!!!::::::::",
"++++++++++++!!!!!!!!!!!!!!::::!!++******++++!!!!$$ee!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::!!!!!!::::::::",
"++++++++++++++!!!!!!!!!!!!!!::!!++****++++++!!++$$ee!!!!!!!!!!++++!!++++++!!!!++++!!!!!!!!::::!!::::::::::",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!++++++++++!!**$$$$++!!!!++++++++!!!!!!!!++++++++++!!!!!!!!!!!!!!::::::::",
"++++++++++++!!++!!!!!!!!!!!!::!!!!!!!!!!!!!!!!ee$$$$++!!!!++++::....    ..!!++++++++++!!!!!!!!!!::::::::::",
"++++++++++++++!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!$$$$$$ee!!++++..            ..!!++++++++++!!!!!!::::::::::::",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$$$$$**!!++::              !!++++++++++!!!!!!::::::::::::",
"++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!$$$$$$@@$$++++!!::::::........::++++++++++++!!::::::::::::::",
"++++++++++++++!!!!!!!!!!!!!!::!!::!!!!!!!!!!!!ee$$$$$$$$$$**!!!!::::::!!!!!!!!++++++++++!!!!::::!!::::::::",
"++++++++++++++++++++++!!!!!!!!::!!!!!!!!!!!!!!**$$$$@@$$@@$$**!!::::!!!!!!!!!!++++++++!!!!::::::::::::::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$@@@@@@@@ee!!!!::!!!!!!!!!!!!!!!!!!::::::::::::::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@$$$$$$@@**::::::::!!!!!!!!!!!!::::!!::::::::::::!!::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++$$$$$$$$$$::::::::::::::::::::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@$$@@**..::::::::::::::::::::::::::::::::::!!!!::!!::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@ee::::....::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::**@@$$**::::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++$$**::::::::::::::::::::::::::::::::::::::::!!!!::::!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++$$++......::::::::::::::::::::::::::::::::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!$$ee  ......::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!**$$++......::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!++$$$$!!::::::....::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!!!!!++$$$$$$::..::::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!ee$$$$$$::::::::::..::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!**$$$$$$$$!!::::::::::::::::::!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!++$$$$$$$$$$**::::..::::::::::!!!!!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!$$$$$$$$$$@@ee::::::::::::::::!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!::**$$$$$$$$$$$$++::!!!!!!::::::::!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!::..::**$$@@$$@@@@$$::....::::::::::!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!::......::ee@@@@@@@@$$**::..    ::::::::!!!!!!!!!!::::!!::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!::....::::!!ee$$$$$$@@**    ..  ::..::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!::::::::::::::!!$$@@$$@@**      ......::::!!!!!!!!!!::!!::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!::::::::!!!!!!::!!$$@@@@ee      ..    ::::::!!!!!!!!::::!!!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!::::::!!!!!!!!!!::::!!$$@@ee            ::::!!!!::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!::::::!!!!!!!!!!!!::::::++$$$$..          ..::::::::::!!::::!!!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!::....::!!!!!!!!!!!!!!::::::**@@++              ::::::::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!....::::::!!!!!!!!!!!!::::::::ee$$!!            ..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!::..::::!!!!!!!!!!::::::::::::::$$$$..          ..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!::..::::!!!!!!!!::::::::::::::..++$$**          ..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!!!::::!!!!!!!!!!!!::::::::::..::::$$$$..  ..  ..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++!!::::!!!!!!!!!!!!::::::........::::!!::......::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!::::!!!!!!!!!!::..........::::::::..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!::::::!!!!!!!!::......::..::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!::::::!!!!::......::::::::::::::::!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!::::::::......::..::::::::::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!::......::::::::::::!!!!!!!!!!::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"!!!!!!!!!!!!::::::::::::!!!!!!!!!!++++++++++++++++++++++++++++++!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"!!!!!!!!!!!!::::::::::::::!!!!!!!!++++++++++++++++++++++++++++++!!::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!::",
"!!!!!!!!!!::::::::::::::::!!!!!!++++++++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::",
"++!!!!!!!!!!::!!::::::::::!!!!++++++++++++++++++++++++++++++++!!::::::::::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!::",
"++!!!!!!!!!!::::::::::::::::!!!!++++++++++++++++++++++++!!++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++!!!!!!!!!!!!::::::::!!!!!!++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++!!!!!!!!!!::::::::!!::!!++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!::::::",
"++++++!!!!!!!!!!::!!::!!!!::!!++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!!!::::::",
"++++!!!!!!!!!!!!!!::!!::::::!!!!++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::!!!!!!!!!!::::::",
"++++!!++!!!!!!!!!!!!!!!!::!!!!++++++++****++++++ee++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!::::::",
"++++++++!!!!!!!!!!!!!!::!!!!!!++++******++++!!**$$++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::!!!!!!!!::::::::",
"++++++++++!!!!!!!!!!!!!!!!::!!++++******++!!!!ee@@**!!!!!!!!++++++!!++++++!!++++!!!!!!::::::!!!!!!::::::::",
"++++++++++++!!!!!!!!!!!!!!::!!++++****++++!!!!$$@@ee++!!!!!!++++++++++++++++!!++++!!!!!!!!!!!!!!::::::::::",
"++++++++++!!!!!!!!!!!!!!::!!::!!++++++++++!!++$$$$$$++!!!!++++!!::::..::!!++++++++++!!!!!!!!!!!!!!::::::::",
"++++++++++++!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!**$$$$$$**!!!!!!..            !!++++++++++!!!!!!!!!!::::::::::",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$$$$$++++!!              ..++++++++++!!!!!!!!::::::::::::",
"++++++++++++++!!!!!!!!!!!!::!!!!!!!!!!!!!!!!**$$$$$$@@ee++++::........    ..!!++++++++++!!!!::::::::::::::",
"++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!!!**$$$$$$@@@@ee++!!::::::!!::!!!!!!++++++++++!!!!::::::::::::::",
"++++++++++++++!!!!!!!!!!!!!!!!::::!!!!!!!!!!++$$$$$$@@@@$$ee++::::!!!!!!!!!!!!++++++++!!!!::::::::::::::::",
"++++++++++++++++++++!!!!!!::!!::!!!!!!!!!!!!!!$$$$$$$$$$@@$$**!!::!!!!!!!!!!!!!!++++!!!!::::::::::::::::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!**@@$$@@@@@@@@++!!::::!!!!!!!!!!!!!!::::::::::::::::::!!::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@$$@@@@$$!!::::::::::::::!!!!::::::!!::::!!!!::::!!!!::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@!!::::::::::::::::::::::::::::::::::::::!!::::!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::ee@@$$$$ee..::..::::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::$$@@$$++..::......::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++$$++::::::::::::::::::::::::::::::::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!$$::....::::::..::::::::::::::::::::::::::!!!!!!!!!!::!!!!!!",
"++++++++++++++++++++++++!!!!!!++!!!!!!!!!!!!$$++........::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!ee$$::........::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!**$$ee........::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!++++$$$$ee..::::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!++!!!!!!!!ee$$$$ee..::::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!**$$$$$$ee::::::::::..::::::!!::!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!**$$$$@@$$$$!!::::::::::::::::!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!++$$@@@@$$$$$$++::::::::::::::::!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++$$$$$$$$$$@@$$ee::!!!!++!!::::::!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!**$$@@$$$$$$$$@@$$**::::!!!!::::::!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!ee@@$$$$$$$$@@@@@@**......!!::::::!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!::::!!ee@@@@@@@@@@@@$$ee::::::..::::::!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!::....::!!$$@@$$@@$$@@$$$$..  ..::::::::!!!!!!!!!!::!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!::....::::::++$$$$$$@@@@$$ee    ::....::::!!!!!!!!!!!!::!!::!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++!!!!::..::::::::!!**@@$$@@@@@@ee      ....::::::::!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!::::::::::::!!!!!!**@@$$@@@@$$..        ..::::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!::::::::!!!!!!!!!!::!!$$@@@@@@@@++          ..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!::..::::!!!!!!!!!!!!!!::!!$$@@@@$$$$..        ..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!::..::!!::!!!!!!!!!!!!::::++$$$$$$$$**        ..  ::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!....::::!!!!!!!!!!!!::::::::ee$$@@$$$$........    ::!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!::::::!!!!!!!!!!!!::::::::::::$$$$**!!::::::......::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!::!!!!!!!!!!!!::::::::::::::++++::::::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++!!!!::!!!!!!!!!!::!!::::::::..::::::::::::....::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!::!!++!!!!!!!!!!::::..::::..::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!++!!!!!!!!!!::::......::::::::::::::!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!::::!!!!!!!!::::::..::::::::::::::!!::!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!::::!!::::..::::::..::::::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!::::........::::::::::::!!!!!!!!!!!!::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"!!!!!!!!!!!!::::::::::!!!!!!!!!!++++++++++++++++++++++++++++++!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"!!!!!!!!!!!!::::::::::!!!!!!!!++++++++++++++++++++++++++++++++!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"!!!!!!!!!!::::::::::::::!!!!!!++++++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::",
"!!!!!!!!!!!!::::::::::::!!!!!!++++++++++++++++++++++++++++++!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::",
"!!!!!!!!!!!!!!::::::::::::!!++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::",
"++!!!!!!!!!!!!::!!::::::::!!++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!::::::",
"++++!!!!!!!!!!!!::::::::!!!!++++++++++++++++++++++++!!::!!!!++!!!!!!!!!!::::::::::::::!!!!!!!!!!!!::::::::",
"++!!++!!!!!!!!!!!!!!::::::!!++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!::::::::",
"++++!!!!!!!!!!!!!!::!!::::!!++++++++++++++++++ee**!!!!!!!!!!!!!!!!!!!!!!!!::!!!!::::::::::!!!!!!!!::::::::",
"++++!!!!!!!!!!!!!!!!::!!::!!++++**++++++++!!++$$ee!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!!!!!::::::::",
"++++!!++!!!!!!!!!!!!!!!!::!!++********++!!!!ee$$ee++!!!!!!++++++!!++++++++++++++!!!!::::::!!!!!!::::::::::",
"++++++++!!!!!!!!!!!!!!!!!!!!++++++**++++!!!!$$@@$$**++!!++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::",
"++++++++!!!!!!!!!!!!!!::!!!!++++++++++++!!++$$$$@@ee++++++++!!::::....!!++++++++++!!!!!!!!!!!!::::::::::::",
"++++++!!++!!!!!!!!!!!!!!::!!!!++++++!!!!!!**@@$$@@$$**++!!..            !!++++++++++!!!!!!!!!!!!!!::::::::",
"++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@$$$$$$ee++!!..              ++++++++++++!!!!!!::::::::::::::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@$$@@@@$$**++!!..........  ..!!++++++++++!!!!!!::::::::::::::",
"++++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!!!ee@@@@$$@@@@$$++!!!!::::::!!!!!!!!++++++++++!!!!::::::::::::::::",
"++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!**$$$$$$@@@@@@$$**!!::::!!!!!!!!!!++++++++!!!!!!::::::::::::::::",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$$$$$$$$$@@ee!!::::!!!!!!!!!!!!++!!!!!!::::::::::::::::::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$$$$$$$$$@@ee!!::::::!!!!!!!!!!!!::::::::::::::::::::::::!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**@@$$$$@@@@@@++::::::::!!::::::::!!::::::::::::::::::::!!::!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@$$@@@@ee::::::::::::::::::::::::::::!!::::!!!!!!!!!!!!::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$@@$$ee!!::..::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::$$@@$$**::::....::::::::::::::::::::::::::::!!!!!!::!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!$$ee::::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!$$!!....::::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!++!!eeee........::::::::::::::::::::::::::::::::!!!!!!!!!!!!::!!!!!!",
"++++++++++++++++++++!!!!!!!!!!++!!!!!!!!**$$!!..........::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!++++!!!!!!!!!!!!!!++$$$$::......::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!++$$$$$$::::..::..::::::::::::::::::::::::::!!!!::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!++!!ee$$$$$$::::::::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++++**$$$$@@$$++::::::::::::::::!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!++$$$$$$$$$$$$**!!::::::::::::!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!++$$$$$$@@@@@@@@ee++!!!!::::::::!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!++ee$$$$@@@@@@@@@@++!!++++!!::::::!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!++!!!!ee$$@@@@@@@@@@@@@@$$!!::::++!!::::::!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!++$$$$@@$$@@@@@@@@@@@@$$!!::..!!::::::!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!ee$$$$@@$$@@@@@@@@@@@@@@**::::++!!::::!!!!!!!!!!!!::::::!!::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!++!!!!ee@@$$$$$$@@@@@@@@@@$$**  ..!!++::::::!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!::..::$$$$$$$$$$$$@@@@@@@@!!  ..**!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!::::::..!!$$@@$$@@$$$$@@@@@@!!    ::::::::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++!!::......::::!!@@@@@@@@$$@@@@@@**      ....::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!..........::::**@@@@@@@@@@@@@@$$..        ..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!::......::::::!!!!$$$$@@@@@@$$eeee::::::..    ..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!::::..::::::::!!!!!!**@@$$ee++::::::::::::....    ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!::::::::::::::!!::!!!!++!!::..::::::::::::::..    ::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!::::::::!!!!!!!!!!!!!!::::::::::::......::::::::..  ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!::::!!!!!!!!!!!!!!::::::::::::..............::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::....................::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++!!!!!!!!!!!!!!!!::::................::....::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!!!::..........::..::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!::::..........::::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!++!!!!!!!!::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!!!!!!!!!::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!!!!!!!!!::..::::::::::::::::!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"!!!!!!!!!!!!::::::::!!!!!!!!!!++++++++++++++++++++++++++++++++::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"!!!!!!!!!!!!::::::::::!!!!!!++++++++++++++++++++++++++++++++!!::::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!::::::",
"!!!!!!!!!!::::::::::!!!!!!!!++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"!!!!!!!!!!!!::::::::::::!!++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!::::::",
"!!!!!!!!!!!!!!::::::::::!!++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!::::::::",
"++!!!!!!!!!!!!::::::::!!!!!!++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!::::::::",
"++++!!!!!!!!::!!::::::::!!!!++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!::::::::",
"++!!!!!!!!!!::::!!!!!!::!!++++++++++++++++++++$$**!!!!!!!!!!!!!!!!!!!!!!!!::!!::::::::::!!!!!!!!!!::::::::",
"++!!!!!!!!!!!!!!!!!!!!::!!++++**++++++++++!!ee$$**++!!!!!!!!!!!!!!++++!!!!!!!!!!!!::::::!!!!!!!!::::::::::",
"++++!!!!!!!!!!!!!!!!::!!!!++******++++++!!++$$$$ee++!!!!!!++++!!++++++++!!++++++!!!!!!!!!!!!!!!!!!::::::::",
"++++!!++!!!!!!!!!!!!!!!!!!++++******++++!!**$$$$$$**++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::",
"++++!!!!!!!!!!!!!!!!!!!!!!!!++++++++++!!!!ee$$$$$$ee++++++!!::::....::++++++++++++!!!!!!!!!!::::::::::::::",
"++++++++!!!!!!!!!!!!!!::!!!!++++!!!!!!!!!!$$$$$$$$$$**++!!            ..++++++++++!!!!!!!!!!!!::::::::::::",
"++++++!!++!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!$$$$$$@@$$ee++!!              ::++++++++++!!!!!!!!!!!!!!::::::::",
"++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$$$$$$$@@**!!!!........    ..++++++++++!!!!!!!!::::::::::::::",
"++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@$$@@$$@@$$++!!::::!!::::::!!!!++++++++++!!!!::::::::::::!!::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$$$@@@@@@@@ee++!!::!!!!!!!!!!!!++++++++++!!!!::::::::::::::::",
"++++++++++++!!++!!!!!!!!::!!!!!!!!!!!!!!!!ee$$$$@@@@@@@@$$**!!!!!!!!!!!!!!!!++++++!!!!::::::::::::::::::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@$$@@**!!::!!!!!!!!!!!!!!!!!!::::::::::::::::::!!!!::",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$$$$$$$$$$$!!::::::::!!!!::!!!!::::::::::::::::::::!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$$$@@@@**::::::::::::::::::::::::::::::::::::!!!!!!!!::!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++!!!!$$$$$$$$ee::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$$$**::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::++$$**::::::..::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!$$::....::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!++!!!!!!$$++  ....::::::::::::::::::::::::::::!!::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!++!!++!!ee$$::........::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!++!!**@@$$............::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!++!!!!!!!!++!!++$$$$ee..::..::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!$$$$$$ee..::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!++ee$$$$@@$$!!::::::::::::::::::!!::::!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++**$$@@$$@@$$$$**!!::::::::::::!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!++**$$$$$$$$$$$$$$ee++!!::::::::!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!++++$$$$$$@@@@@@@@$$!!++++++::::::::!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!ee$$$$$$@@@@@@@@@@**::!!!!++::::::!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!ee$$$$@@@@@@@@@@@@$$@@ee::..::!!::::!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!**$$$$@@$$@@@@@@@@@@@@@@$$!!::::++::::::!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!++!!ee@@@@$$@@$$@@@@@@@@@@@@@@::..::++!!::!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!::ee@@@@$$$$$$@@@@@@@@@@$$..  ::**!!::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!..  ::ee$$$$$$@@@@$$@@@@@@ee      ++!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!::....!!ee@@@@@@@@@@@@@@@@@@::      ::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++!!!!::!!!!..::!!$$@@@@$$$$eeee**ee!!....  ::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!::......::::::**ee++!!::::::::::::::::::..    ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!::......::::::::::::::::::::::::::::::::::..    ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!::::::::::::::::!!::::::::::::::::::::::::::..  ..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!::::::::::::::!!::!!!!::::::::::..::::::::::::::....::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!::!!!!!!!!::::::!!!!::..........  ........::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!!!!!!!!!!!::::::::::..........        ......::::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!!!!!!!!!!!!!::......  ..  ..................::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++!!!!!!!!!!!!!!!!..      ........::::::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!..  ......::..::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!::......::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!!!!!!!!!!!::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!!!!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"!!!!!!!!!!::::::::::!!!!!!!!++++++++++++++++++++++++++++++++++!!::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::",
"!!!!!!!!!!::::::::::::!!!!!!++++++++++++++++++++++++++++++++!!::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::",
"!!!!!!!!!!::::::::::!!!!!!!!++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!::::::",
"!!!!!!!!!!!!::::::::::::!!++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!::::::::",
"!!!!!!!!!!!!!!::::::::::!!++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!::::::::",
"!!!!!!!!!!!!!!::::::::!!!!!!++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!::::::::",
"++!!!!!!!!!!::!!::::::::!!++++++++++++++++++++++**++!!!!!!!!++!!!!++!!!!!!!!!!!!::::::!!!!!!!!!!!!::::::::",
"++!!!!!!!!!!!!::::!!!!::!!++++++++++++++++!!++$$ee++!!!!!!!!++++++++!!!!++!!++!!!!!!::::!!!!!!!!!!::::::::",
"++++!!!!!!!!!!!!!!!!!!::!!++********++++!!!!ee$$$$**++!!!!++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::",
"++++!!!!!!!!!!!!!!!!!!!!!!++******++++++!!!!$$$$$$ee++++++!!::::::::!!++++++++++++!!!!!!!!!!!!::::::::::::",
"++++!!++!!!!!!!!!!!!!!!!!!++++++++++++++!!++$$$$@@$$**++!!..          ::++++++++++!!!!!!!!!!::::::::::::::",
"++++!!++!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!**$$@@$$@@$$++!!..            ::++++++++!!!!!!!!!!::::::::::::::",
"++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!**$$$$@@$$$$**!!::......      ..++++++++++!!!!!!!!::::::::::::::",
"++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!ee$$$$@@@@$$$$++!!!!::::::::..::!!++++++++++!!!!!!!!!!!!::::::::",
"++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@$$@@@@@@ee!!!!::!!::!!!!!!!!++++++++++!!!!::::::::::::::::",
"++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@$$**!!!!!!!!!!!!!!!!++++++++!!!!!!::!!::::::::::::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@$$@@@@@@@@ee!!!!!!!!!!!!!!!!!!!!!!!!!!::!!::!!::::::::::::",
"++++++++++++!!++!!!!!!!!::!!!!!!!!!!!!!!!!!!**$$@@@@@@@@$$**!!::::!!!!!!!!!!!!::::::::::::::::::!!::::::::",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@$$!!::::::!!!!::::::::::::::::::::::::::::::!!::!!",
"++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@$$@@@@**::::::::::::::::::::::::::::::::::::::!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$$$**::::::::::::::::::::::::::::::!!::::::!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::**@@ee!!::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::ee::..::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::++......::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++........::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!++++!!++$$::........::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!++!!++!!$$ee::......::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!++!!++!!**$$ee..::......::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!++!!!!!!!!++!!++$$$$$$::::....::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!++$$$$$$$$**::::..::::::::::::::!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!++ee$$$$@@$$@@++!!::::::::::::!!!!!!::!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++ee$$@@$$@@$$$$!!++!!::::::::!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!++**$$$$$$$$@@@@$$++..++++!!::::::!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!++$$$$@@@@@@@@@@@@$$!!....!!::::::!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!ee$$$$$$@@@@@@@@@@@@$$!!::..!!::::!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!++!!ee$$$$@@@@@@@@@@@@@@@@@@++....++!!::::!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!ee$$@@$$$$@@@@@@@@@@@@$$!!  ..++!!::::!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!++$$$$@@$$@@@@@@@@@@@@ee..  ..**!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!::::::!!$$$$@@@@@@@@@@@@@@$$..    ::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!..  ..::!!eeeeeeee******eeee!!        ..::::!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!::      ..::::::..::::::::::::::::....    ..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++!!!!!!::    ..::::::::::::::::::::::::::::..    ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!::!!!!..  ..::::::::::::::::::!!!!!!!!!!!!::    ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!!!::!!::..  ..::::::::::::::::::::::!!::::!!::..  ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!::::!!!!::..    ::::::..............::::::::::....::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!::::!!!!!!::..    ......          ..............::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!!!!!!!!!!!::::..                    ..........::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!!!!!!!!!!!!!!!!!..          ................::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!::::    ......::::::::::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++!!!!!!!!!!!!!!::::::......::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!!!!!!!!!!!::::::::::::::::::::!!::!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!::!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"!!!!!!!!!!::::::::::!!!!!!!!++++++++++++++++++++++++++++++++++++!!::!!!!::::::::::!!!!!!!!!!!!!!!!!!::::::",
"!!!!!!!!!!::::::::::::!!!!!!++++++++++++++++++++++++++!!++++++!!::!!!!::::::::::::::::!!!!!!!!!!!!!!::::::",
"!!!!!!!!!!::::::::::!!!!!!!!++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::!!::::::::!!!!!!!!!!!!!!::::::",
"!!!!!!!!!!::::::::::::!!!!!!++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!::::::::",
"!!!!!!!!!!!!!!::::::::!!!!!!++++++++++++++++++++++++++!!!!!!!!!!!!++++++++!!!!!!!!::::::!!!!!!!!!!::::::::",
"!!!!!!!!!!!!!!::::::::!!!!!!++++++++++++++++++++**ee++!!!!!!!!++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::",
"++!!!!!!!!!!::!!!!!!::::!!!!++******++++++!!!!++$$$$**++++++!!!!::!!!!++++++++++++++!!!!!!!!!!!!!!::::::::",
"++!!!!!!!!!!!!!!!!!!::::!!!!++******++++++!!!!ee$$$$$$**++!!..        ::!!++++++++++!!!!!!!!!!!!::::::::::",
"++++!!!!!!!!!!!!!!!!!!::!!!!++****++++++++!!!!$$$$@@$$ee++!!            ..!!++++++++!!!!!!!!!!!!::::::::::",
"++++!!!!!!!!!!!!!!!!!!!!!!!!++++****++++!!!!++$$$$$$$$$$++!!::            ::++++++++++!!!!!!!!::::::::::::",
"++++!!++!!!!!!!!!!!!!!!!!!!!!!++++++!!!!!!!!**$$@@@@$$@@ee++!!::::....    ::++++++++++++!!!!::::::::::::::",
"++++!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$@@$$@@@@**!!!!::!!!!::::!!++++++++++++!!!!::::::::::::::",
"++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@$$@@@@@@$$**!!!!!!!!!!!!!!++++++++++!!!!!!::::::::::::::",
"++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@$$@@@@@@**!!!!!!!!!!!!!!!!++++++!!!!!!::::!!!!::::::::",
"++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@$$$$@@@@$$**!!::!!!!!!!!!!!!!!!!!!!!::!!::::::::::::::::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**@@$$@@@@@@$$++::!!::::!!::!!!!::::::::!!::!!!!::::::::::::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$@@@@@@ee!!::::::::::::::::::::::!!::!!::!!::::::::!!!!",
"++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@++::::::::::::::::::::::::::::::::::!!!!!!!!::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**@@@@$$!!::::::::::::::::::::::::::::::::::::!!!!!!!!::!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$++::::..::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::..::::::::::::::::::::::::::::::::!!::!!::!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::  ......::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::........::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++::........::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!++++!!!!++ee::::....::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!++++++++$$ee..::::..::::::::!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!++!!++!!++!!$$$$$$::::..::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!++++!!ee$$$$$$$$!!::..::::::::::!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!++!!!!!!!!++++**$$$$@@@@$$**!!::..::::::::!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++!!++$$$$$$@@@@@@!!++!!!!::::::!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!++++$$$$$$@@$$@@@@**::!!**!!::::::!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++ee$$@@$$@@@@@@@@@@++....!!::::::!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!ee$$$$$$$$@@@@@@@@@@@@**::::!!..::!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!++$$$$@@@@@@@@@@@@@@@@@@**..::++::::::!!!!::!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@!!  ::++!!::::!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!++!!::....++**ee$$$$@@@@@@@@ee..  !!**!!::::!!!!!!!!::::!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!::          ..::!!++**ee$$$$::    ::::::::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!            ..::..::..::::++!!        ..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!..          ....::::::::::::::::::::..    ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!..          ..::::::::::::::::::::::..    ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!::          ......::::::::::::::!!!!::..  ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!!!::!!!!::..        ........::::::::::::::::::  ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!::::!!!!::::..                ........::::::..  ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!!!::::!!!!!!!!::::                      ..........::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++!!!!!!!!!!!!!!!!!!!!::::..                    ......::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!!!::::......    ................::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!::::..........::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++!!!!!!!!!!!!!!!!!!::::........::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!++++!!!!!!!!::::....::::::::::::::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++!!!!++!!++!!!!::::::::::::::::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!++!!::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!::!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"!!!!!!!!!!!!!!!!::::!!!!!!!!!!++++++++++++++++++++++++++++++++++!!!!!!!!++++++!!!!!!!!!!::::::!!!!!!!!::::",
"!!++!!!!!!!!::::::::::!!!!!!!!++++++++++++++++++++++++++++++++!!++!!++++++++++++++++!!!!!!!!::!!!!!!!!::::",
"++++!!!!!!!!!!::::::!!!!!!!!!!++++++++++++++++++++++++eeee**++!!!!!!!!!!!!++++++++++++++!!!!!!!!!!!!!!::::",
"++++!!!!!!!!!!!!::::::!!!!!!!!++++++++**++++++!!!!++ee$$$$ee++++::..    ..::++++++++++++!!!!!!!!!!!!::::::",
"++++!!!!!!!!!!!!!!::::!!!!!!!!++++******++++++!!!!**$$$$$$$$++!!::          ..!!++++++++++!!!!!!!!!!::::::",
"++++++!!!!!!!!!!!!!!!!!!!!!!!!++++******++++!!!!!!ee$$$$$$$$ee!!::            ::!!++++++++!!!!!!!!::::::::",
"++++!!++++!!!!!!!!!!!!!!!!!!!!!!++****++++++!!!!++$$$$@@@@@@$$++!!::::....      !!++++++++!!!!!!!!::::::::",
"++++++++!!++!!!!!!!!!!!!!!!!!!!!++++++++++++!!!!++$$$$@@@@$$@@$$++!!!!::::::....!!++++++++++++!!!!::::::::",
"++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!**@@$$$$@@@@@@@@ee++!!!!!!!!!!!!!!++++++++++!!!!::::::::::",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**@@@@@@@@@@@@@@$$++!!!!!!!!!!!!!!!!++++++!!!!::::::::::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**@@$$@@@@@@@@@@$$++!!!!!!!!!!!!!!!!++++!!!!::::::::::::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@ee!!::::!!!!!!!!!!!!!!!!!!::::::::::!!::::",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@@@**::::::::::::::::::::::::!!::::!!!!::::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@$$++::::::::::::::::::::::::::::!!::!!!!::::",
"++++++++++++++++!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@$$++::!!::::::::::::::::::::!!::::::!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**@@$$++!!::::::::::::::::::::::::::::::!!::::!!!!::!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**::::::::::::::::::::::::::::!!::!!::!!::!!!!!!!!!!",
"++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::......::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::  ....::::::::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::..........::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++!!::!!........::::::::::::::::::::::::::!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++!!!!**++::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!++!!!!!!++!!++++!!**$$++!!::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!++!!!!!!**$$$$$$**++::..::::::::::!!::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!++++++!!++$$$$$$$$@@$$++::::::::::!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!++!!++++++++$$$$$$@@@@$$**++!!::::::!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!++!!++++++!!$$$$$$$$$$@@$$!!++++!!::::::!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!++!!++++++++ee$$$$@@@@@@@@@@ee::::++::::::!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!++++**$$$$$$@@@@@@@@@@@@$$::..++::::!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++!!++$$$$$$$$@@@@@@@@@@@@@@!!..++::::!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++!!!!!!!!!!ee@@$$$$$$@@@@@@@@@@@@$$..  !!::::!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++!!++!!!!**$$!!....!!**$$@@@@$$++  ..!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!++!!::::::..    ....::::++ee$$ee    !!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!..  ..          ......::::++..      ..::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++!!!!..                ....::::::::::::    ..::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++!!!!::                ........::::::::::..  ..::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++!!!!!!!!!!::              ........::::::::..    ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++!!::::!!!!++::              ..........::::::..    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++!!!!..::!!!!!!!!..                  ......::....  ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!::::::!!!!!!!!::::                    ....      ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..                          ..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!::!!!!!!!!!!!!!!!!::::::                      ..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::..  ..................::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::........::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!++++!!!!!!!!!!::::::::::::::::!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++!!++++++!!!!++!!++!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!::!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++!!::::::!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++!!!!::::::::::::::::!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++****++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++++++++++++++++++++++ee$$$$$$**++!!::......!!!!++++++++++++!!!!!!!!!!!!",
"++++!!!!!!!!!!!!!!!!::!!!!!!!!!!++++++****++++++!!!!++**$$$$$$$$ee++!!          ::!!++++++++++!!!!!!!!!!::",
"++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++++******++++++!!!!++$$$$$$@@$$$$++!!..          ..++++++++++++!!!!!!!!::",
"++++++!!++!!!!!!!!!!!!!!!!!!!!!!++++******++++!!!!!!**$$$$@@@@@@$$ee++::......      !!++++++++++!!!!!!::::",
"++++++++++!!++!!!!!!!!!!!!!!!!!!!!++++**++++++!!!!!!ee@@$$@@@@@@@@$$**!!!!::::::....::++++++++++++!!!!::::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!$$$$$$$$@@@@@@@@$$++!!!!!!::!!!!!!++++++++++++!!::::::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!++++++++++!!!!::::::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@$$@@@@@@@@@@ee!!::!!!!!!!!!!!!++++++!!!!::::::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@$$**!!!!!!!!!!!!!!!!!!!!!!!!::::::::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**@@@@@@@@@@$$@@$$++!!::!!::!!!!::::::::::::::::::::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@ee!!::::::::::::::::::::::::::::!!::::",
"++++++++++++!!++++!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@$$++!!!!::::::!!::::::::::::::::!!!!!!!!",
"++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++@@@@@@$$**!!!!!!::::::::::::::::::!!::::!!!!!!::!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@ee!!::::::::::::::::::::::::::::::!!::!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++++::::::::::::::::::::::::::::!!::::::!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::......::::::::::::::::::::::::::!!!!::!!!!!!!!!!",
"++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::........::::::::::::::::::!!::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::........::::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!++!!!!++!!++!!++ee::::......::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!++++++!!!!!!++++!!**$$ee++::::::..::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!++!!!!++!!++++!!**$$$$$$ee**::::::::::::::::::::::::::!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++!!!!!!++++++**$$$$$$$$@@$$$$!!::::::!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!++++++++!!++!!++++++$$$$@@$$@@@@@@$$**::::::!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!++++++!!!!!!++!!$$$$$$$$@@@@@@ee++**++::::::!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++!!!!!!++!!ee$$$$$$$$@@@@@@$$!!!!**::::::!!!!!!!!::!!!!!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++!!++++++**$$$$$$@@@@$$@@@@@@@@++..!!::::!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++$$$$$$$$@@@@@@@@@@@@@@$$::**::::!!!!!!!!::!!::::::!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++!!++!!!!!!**$$@@$$@@@@@@@@@@@@@@@@$$..!!::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++!!!!::::ee@@$$ee**ee$$@@@@@@$$::  !!::::::!!!!!!!!::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!......!!$$$$!!!!!!!!++**$$$$!!..!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++**++++++++++++++++!!!!!!::    ..::++ee!!::......::::++++  ......::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++!!!!!!!!!!::  ....::..    ............::      ..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++**++++++++++++++++!!!!::!!!!!!!!..::..        ......::::....    ..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++!!!!....::!!!!++++..          ........::......    ..!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++!!!!....::::!!!!++!!..            ............      !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++++!!::::::::::!!!!!!!!::..            ..........      ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++!!!!!!!!!!::!!!!!!!!!!::::              ..          ..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::                      ..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::                  ..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::............  ......::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!++!!!!!!!!!!!!::......::::::::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!++!!!!!!!!!!!!!!::::::::::::::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++!!!!!!!!!!++!!!!!!!!!!!!::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++!!++++!!!!!!++++!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!::!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++!!::::!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!::::::::!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++!!!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::::::..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++****++++++++++++++++++++++++++++++!!::::!!!!!!!!::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++******++++!!!!!!ee$$$$@@@@@@@@@@**!!::          ..!!++++++++++!!!!!!",
"++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!++**++++++++!!!!!!$$$$$$@@@@@@@@$$$$++!!::......    !!++++++++++!!!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++++++++++!!!!++$$$$$$@@$$@@@@@@@@ee!!!!::::::....::++++++++++++!!!!",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$$$@@@@@@@@@@@@$$**!!!!!!!!!!!!!!++++++++++++!!!!",
"++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!!!++++++++!!!!::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**@@$$$$@@@@@@@@@@@@@@ee!!::!!!!!!!!!!!!++++++!!!!::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@@@@@@@**!!!!::!!!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@$$@@@@@@@@@@@@$$++!!::::::::!!::::!!::::::::::::",
"++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**@@@@@@@@@@@@@@@@ee!!::::::::::::::::::::::::::::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@ee!!!!!!::::::::::::::::::::::::!!::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**@@@@@@@@$$**!!!!::::::::::::::::::::::::::!!::!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@ee++::!!::::::::::::::::::::::::::!!!!!!!!",
"++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@**!!::::::::::::::::::::::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$**::::::::::::::::::::::::::::::!!!!::!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!++!!!!!!!!!!!!!!::++$$!!......::::::::::::::::::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!++!!!!!!!!!!!!!!!!!!!!$$$$::......::::::::::::::::::::::!!!!::!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!++++!!!!++!!!!++$$$$ee::::..::::::::::::::::::!!::!!::!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++!!!!!!!!!!++++!!++++++!!++$$$$$$$$++!!::::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!++++!!!!!!++++!!!!++++++$$$$$$$$@@eeee!!::::::::::::!!!!::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++!!!!++!!!!++++++$$$$$$@@@@@@@@@@$$++::::::::!!!!!!::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++!!!!++++++++$$$$$$$$@@@@@@@@@@@@$$::::::::!!!!!!!!!!::::!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++!!!!++++!!!!++++++$$$$$$$$@@@@@@@@@@eeee$$**::::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++ee$$$$$$@@$$@@@@@@@@$$++++**::::::!!!!!!!!!!!!::::::!!!!!!!!!!!!",
"++****++++++++++++++++++++++++++++!!++!!**$$$$$$$$@@@@@@@@@@@@@@@@**!!!!::::!!!!!!!!!!!!::::::!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++!!!!!!**$$$$$$$$@@@@@@@@@@@@@@@@$$!!**..::!!!!!!!!!!!!::::!!!!!!!!!!!!!!",
"******++++++++++++++++++++!!!!++++!!::::!!**$$$$@@@@$$@@@@@@@@@@@@ee!!++::::!!!!!!!!!!::::!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++!!    ::::**$$$$@@@@@@@@@@@@@@$$::::!!!!::::!!!!!!!!::!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++!!!!!!!!      ..!!ee@@@@@@@@@@@@@@@@@@!!..!!!!::::!!!!!!!!!!!!!!::!!!!!!!!!!!!",
"****++++++++++++++++++++++++++!!::!!!!!!....::!!$$$$$$**++++++$$$$**  ..::::::!!!!::!!!!!!!!!!!!!!!!!!!!!!",
"******++**++++++++++++++++++!!::..::::!!!!!!!!!!++eeee++::::::::::!!..  ..::::!!!!!!::!!!!!!!!!!!!!!!!!!!!",
"**********++++++++++++++++!!!!......::!!!!!!++!!!!**++....................::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++**++++++++++++++++!!::::::::!!!!!!!!!!!!::::      ..  ........        ::::!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++**++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!::          ..........      ..!!!!!!!!!!!!!!!!!!!!!!++!!",
"**++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::..          ......        ..!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::..                      ::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++!!::!!!!!!!!!!!!!!!!!!::!!!!::::::..                  ..::::::!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++!!!!::!!!!++!!!!!!!!!!!!!!!!!!::::::::..                ..::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++!!!!!!!!++++++!!!!!!!!!!!!!!!!!!::............    ..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++****++++++++++++++++!!::!!++!!!!!!!!!!!!!!!!!!!!::::......::::......::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++!!!!++!!!!!!!!!!!!!!!!!!!!::::..::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++!!::!!++++!!!!!!!!!!!!!!!!::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++!!++++!!::::!!++!!!!++!!++!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++!!::::::::!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++!!!!::::::::!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++****++++++++++++++++++++++++!!!!!!::::......::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++!!!!!!::::..  ::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++!!!!!!!!::....::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++**++++++++++++++++++++++++++++++!!!!!!::++!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++++ee$$ee::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++ee$$@@$$@@@@@@@@@@@@@@$$**!!::::::......!!++++++++++!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$@@$$@@@@@@@@@@@@@@$$$$++!!!!::::::::!!++++++++++++",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@$$@@@@@@@@@@@@@@@@@@**!!!!!!!!!!!!!!++++++++++!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$$$@@@@@@@@@@@@@@@@@@**++!!!!!!!!!!++++++++++++!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@$$**!!!!!!!!!!!!!!++++++!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@$$++!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@ee!!::::!!::::::!!!!::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@$$++!!!!::::::::::::::::::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@@@@@$$++!!!!!!::::::::::::::::::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!++$$@@@@@@@@$$ee++!!::!!!!::::::::::::::::::::!!!!",
"++**++**++++++++++++++++++!!!!++!!!!!!!!!!!!!!!!!!!!!!::++$$@@@@@@$$**!!!!::::::::::::::::::::::::::!!::!!",
"**++**++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++!!!!!!!!$$$$@@@@ee**!!!!!!::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++++++++!!!!!!++!!!!++!!!!!!++!!!!!!ee$$$$$$$$**++!!::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!++++++!!!!++!!!!++$$$$$$$$@@ee!!::::::::::::::::::::::::::!!!!::!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!++++!!++!!++!!**$$@@$$$$$$$$ee::::::::::::::::::::::::::::::::!!!!!!!!!!",
"++++++++**++++++++++++++++++!!++++++++++++++++**@@$$$$$$@@$$$$**!!::::::::::::::::::::::::!!!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++!!!!++++++++++$$$$@@$$@@@@@@@@$$**++::::::::::::::::!!::!!::!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++++!!++!!!!++++++$$$$@@@@$$@@@@@@@@@@@@$$ee!!::::::::!!!!::::::!!!!!!!!!!!!!!!!",
"**++++++++++**++++++++++++++!!!!++!!++!!++$$$$$$$$@@@@@@$$@@@@@@@@@@@@**::::::!!!!!!!!::::::!!!!!!!!!!!!!!",
"++**++++++++++**++++++++++++++++++!!!!!!ee@@$$$$$$@@@@@@@@@@@@@@@@@@@@$$!!::::!!!!!!!!!!!!!!::!!!!!!!!!!!!",
"**++++++++++++++++++++++++++!!!!!!!!!!::++$$$$$$@@@@@@@@@@@@@@@@@@ee**ee**::::!!!!!!!!!!::!!::::!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++!!..  ..++ee$$$$@@@@@@@@@@@@@@@@@@ee++**!!::!!!!!!!!!!!!!!::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++::......::!!ee$$@@$$@@@@@@@@@@@@@@@@****++::::!!!!!!!!!!::::::!!!!!!!!!!",
"++****++++++++++++++++++++++++++!!..::!!::::!!!!ee@@@@@@@@@@@@@@@@@@@@****++::::!!!!!!!!::::!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++::..::!!++!!!!!!!!ee@@@@@@@@@@@@@@@@ee++****::::!!!!!!!!!!::::!!!!!!!!!!!!",
"******++++++++++++++++++++++!!!!::::!!::!!!!!!!!!!++$$$$@@@@@@@@@@@@ee!!****!!::!!!!!!!!::!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**@@@@@@@@@@@@@@$$!!!!++::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++**++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@$$@@$$$$$$ee!!::::::::!!!!!!!!!!!!::!!!!!!!!!!!!",
"******++++++++++++++++++++!!!!++++++!!!!!!!!!!::::!!::!!$$ee++!!!!!!::::::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++****++**++++++++++++++!!::!!!!!!!!!!!!!!!!::::!!!!::::!!!!!!::::::..........  ::!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++++++++++++++!!::!!!!!!!!!!!!!!!!!!::!!::::::::::::..........          ::!!!!!!!!!!!!!!!!!!!!!!",
"**++++++**++++++++++++!!!!::!!!!!!++!!!!!!!!!!!!::!!!!::::::::::........          ::!!!!!!!!!!!!!!!!!!!!!!",
"**++++++**++++++++++++++!!!!::!!++!!++!!!!!!!!!!!!!!!!!!::::::::::::....          ::!!!!!!!!!!!!!!!!!!++!!",
"**++++**++++++++++++++!!!!!!::!!++++++!!!!!!!!!!!!!!!!!!!!::::!!::::..          ..!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++::::!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::..        ::::!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++!!::!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::........    ::::::!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++::::!!++++++++!!!!!!!!!!!!!!!!!!!!::....::......::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++!!::!!**++****++++++!!!!!!!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++++++!!::::!!++++++++++++!!!!!!!!::::::::::::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!::::::::::::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++!!!!::::::::!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++!!!!!!!!::........::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++!!!!!!!!..    ::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++!!!!!!::  ..::::!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!::..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++!!!!::::!!::!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++++++++++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++!!!!!!$$$$$$$$$$++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++!!!!::..$$@@$$@@@@$$ee::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++******++++++++++++++++++++++!!!!!!..  ++@@@@@@@@$$$$::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++!!!!!!::....ee@@@@@@@@$$::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++ee$$@@$$@@@@@@@@@@@@@@$$**!!::::::......!!++++++++++!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$@@$$@@@@@@@@@@@@@@$$$$++!!!!::::::::!!++++++++++++",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@$$@@@@@@@@@@@@@@@@@@**!!!!!!!!!!!!!!++++++++++!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$$$@@@@@@@@@@@@@@@@@@**++!!!!!!!!!!++++++++++++!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@$$**!!!!!!!!!!!!!!++++++!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@$$++!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@ee!!::::!!!!::::!!!!::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@$$++!!!!::::::::::::::::::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@@@@@$$++!!!!!!::::::::::::::::::::::!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!++$$@@@@@@@@$$ee++!!::::!!::::::::::::::::::::!!!!",
"++**++**++++++++++++++++++!!!!++!!!!!!!!!!!!!!!!!!!!!!::++$$@@@@@@$$**!!!!::::::::::::::::::::::::!!!!::!!",
"**++**++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++!!!!!!!!$$$$@@@@ee**!!!!!!::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++++++++!!!!!!++!!!!++!!!!!!++!!!!!!ee$$$$$$$$**++!!::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!++++++!!!!++!!!!++$$$$$$$$@@ee!!::::::::::::::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!++++!!++!!++!!**$$@@$$$$$$$$ee::::::::::::::::::::::::::::::::!!!!!!!!!!",
"++++++++**++++++++++++++++++!!++++++++++++++++**@@$$$$$$@@$$$$**!!::::::::::::::::::::::::!!!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++!!!!++++++++++$$$$@@$$@@@@@@@@$$**++::::::::::::::::!!::!!::!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++++!!++!!!!++++++$$$$@@@@$$@@@@@@@@@@@@$$ee!!::::::::!!!!::::::!!!!!!!!!!!!!!!!",
"**++++++++++**++++++++++++++!!!!++!!++!!++$$$$$$$$@@@@@@$$@@@@@@@@@@@@**::::::!!!!!!!!::::::!!!!!!!!!!!!!!",
"++**++++++++++**++++++++++++++++++!!!!!!ee@@$$$$$$@@@@@@@@@@@@@@@@@@@@$$!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++!!!!!!!!!!::++$$$$$$@@@@@@@@@@@@@@@@@@ee**ee**::::!!!!!!!!!!!!!!::::!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++!!..  ..++ee$$$$@@@@@@@@@@@@@@@@@@ee++**!!::!!!!!!!!!!!!!!::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++::......::!!ee$$@@$$@@@@@@@@@@@@@@@@****++::::!!!!!!!!!!::::::!!!!!!!!!!",
"++****++++++++++++++++++++++++++!!..::!!::::!!!!ee@@@@@@@@@@@@@@@@@@@@****++::::!!!!!!!!::::!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++::..::!!++!!!!!!!!ee@@@@@@@@@@@@@@@@ee++****::::!!!!!!!!!!::::!!!!!!!!!!!!",
"******++++++++++++++++++++++!!!!::::!!::!!!!!!!!!!++$$$$@@@@@@@@@@@@ee!!****!!::!!!!!!!!::!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**@@@@@@@@@@@@@@$$!!!!++::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++**++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@$$@@$$$$$$ee!!::::::::!!!!!!!!!!!!::!!!!!!!!!!!!",
"******++++++++++++++++++++!!!!++++++!!!!!!!!!!::::!!::!!$$ee++!!!!!!::::::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"******++**++++++++++++++!!::!!!!!!!!!!!!!!!!::::!!!!::::!!!!!!::::::........::  ::!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++++++++++++++!!::!!!!!!!!!!!!!!!!!!::!!::::::::::::..........          ::!!!!!!!!!!!!!!!!!!!!!!",
"**++++++**++++++++++++!!!!::!!!!!!++!!!!!!!!!!!!::!!!!::::::::::........          ::!!!!!!!!!!!!!!!!!!!!!!",
"**++++++**++++++++++++++!!!!::!!++!!++!!!!!!!!!!!!!!!!!!::::::::::::....          ::!!!!!!!!!!!!!!!!!!++!!",
"**++++**++++++++++++++!!!!!!::!!++++++!!!!!!!!!!!!!!!!!!!!::::!!::::..          ..!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++::::!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::..        ::::!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++!!::!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::........    ..::::!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++::::!!++++++++!!!!!!!!!!!!!!!!!!!!::....::......::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++!!::!!**++****++++++!!!!!!!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++++++!!::::!!++++++++++++!!!!!!!!::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!::::::::::::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++!!!!::::::::!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++!!!!!!!!::........::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++!!!!!!!!..    ::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++!!!!!!::  ..::::!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!::..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++!!!!::::!!::!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++++++++++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++!!!!!!$$$$$$$$$$++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++!!!!::..$$@@$$@@@@$$ee::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++******++++++++++++++++++++++!!!!!!..  ++@@@@@@@@$$$$::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++!!!!!!::....ee@@@@@@@@$$::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$@@$$@@@@@@@@@@@@@@@@ee++!!::::::..::!!++++++++++++",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@$$**++!!!!!!::!!!!++++++++++++",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@$$@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!++++++++++!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!++!!++++++++!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@**!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@$$++!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@@@@@ee!!::::::!!::::::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::++$$@@@@@@@@@@@@@@ee++!!!!::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$@@@@@@@@@@$$**!!!!!!!!::::::::::::::::::::::!!",
"++++++++++++++++++++++++!!!!!!!!++!!!!!!++!!++!!!!!!!!**$$@@@@@@@@$$ee++!!!!::::!!::::::::::::::::::!!!!!!",
"****++**++++++++++++++++++!!!!++++!!!!!!!!!!!!++!!!!**$$$$$$@@@@$$**++!!!!::::::::::::::::::::::::!!!!::!!",
"++++++++++++++++++++++++++++++++!!++!!!!!!++!!!!!!**$$$$$$$$@@$$ee++!!!!::::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++++++++++!!++++!!!!!!!!++++!!**$$$$$$$$$$$$$$++!!::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!++++!!++++++**$$$$$$$$@@@@@@ee!!::::::::::::::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!++++!!++++**$$$$@@$$@@@@$$@@**::::::::::::::::::::::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++!!!!!!!!++$$$$$$$$$$@@@@@@@@ee++!!::::::::::::::::::::::!!!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!++$$$$$$@@@@@@@@@@@@@@$$$$ee++::::::::::::::!!::!!::!!!!!!!!!!!!!!",
"**++****++++++++++++++++++++!!!!++!!!!::**@@$$@@@@@@$$@@@@@@@@@@@@@@@@**::::::::!!!!::!!::!!!!!!!!!!!!!!!!",
"++++++++++++**++++++++++++++!!!!++!!..  !!ee$$$$$$@@@@@@$$@@@@@@@@@@$$$$!!::::!!!!!!!!::::::::::!!!!!!!!!!",
"++****++++++++**++++++++++++++!!!!::  ....!!**$$@@@@@@@@@@@@@@@@@@**ee$$**::::::!!!!!!!!!!::!!::::!!!!!!!!",
"**++++++++++++++++++++++++++!!!!!!..::::!!!!!!++$$@@@@@@@@@@@@@@@@$$**++**!!::::!!!!!!!!!!!!::::!!::!!!!!!",
"++**++++++++++++++++++++++++++!!::::::!!!!++++++++ee@@@@@@@@@@@@@@@@@@**++++::::!!!!!!!!!!!!::::!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++ee@@@@@@@@@@@@@@@@**++**::::!!!!!!!!!!::::::!!!!!!!!!!",
"++****++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@ee++++++::::!!!!!!!!::!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++!!!!!!++!!!!!!!!!!::!!!!::::**@@@@@@@@@@@@!!::++++!!::!!!!!!!!!!::!!!!!!!!!!!!!!",
"******++++++++++++++++++!!!!::++++++!!!!!!!!!!!!!!::::!!ee@@@@@@@@@@++::++++::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!::::::!!ee@@@@@@ee!!::!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++**++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::!!::::!!**++!!::::::::::::::::!!!!!!!!!!::!!!!!!!!!!!!",
"****++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::!!::::::::::::::::::..    ....::!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++++++++++++++!!!!::!!!!++!!++!!!!!!!!!!!!!!!!::::::::::......          ::::!!!!!!!!!!!!!!!!!!!!",
"******++**++++++++++++++++++!!::!!++++!!!!!!!!!!!!!!!!::!!!!::::::::......        ::!!!!!!!!!!!!!!!!!!!!!!",
"**++++****++++++++++++++!!++!!::!!!!!!++!!!!++!!!!!!!!::!!::::!!!!::::::..        ::!!!!!!!!!!!!!!!!!!!!!!",
"**++++++**++++++++++++++++++++!!::!!++++++!!!!++!!!!!!!!::::!!!!!!!!!!::..        ::!!!!!!!!!!!!!!!!!!++!!",
"**++++**++++++++++++++++++++++!!::::++++++!!!!++++!!!!!!!!!!!!!!!!!!!!!!::      ..::!!!!!!!!!!!!!!!!!!!!!!",
"**++++****++++++++++++++++++++++!!::!!**++**++++++++!!!!!!!!!!!!!!!!!!::..      ..::!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++++++++::::!!!!++++++++++++++++!!!!!!!!!!::..::..  ..::::!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!++++++!!!!!!!!::::::::..::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!!!::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!::::::::::::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++!!!!!!!!::::::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++!!!!!!::    ..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++++!!!!::..  ..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++++++++!!!!!!....::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++++++!!!!..::::::!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++++++++++++++++++++!!::::::!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++!!!!!!++++++!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!++ee**++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++!!!!::..$$$$ee++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++**++++++++++++++++++++++!!++!!!!::  ++@@@@$$++!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++!!!!::..::$$@@@@$$++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++!!!!!!::::..!!@@@@$$ee::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++++++++++++++++++++++++!!!!!!!!::::::$$@@@@$$::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++!!!!!!!!!!!!::::ee$$@@$$::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@$$++!!!!::::::::!!!!++++++++++",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++ee$$$$@@@@@@@@@@@@@@@@$$ee++!!!!!!!!!!!!++++++++++++",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!++++++++++!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!!!++++!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@@@@@@@@@@@**!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@@@@@@@@@@@@@@@@@$$++!!!!::::::::!!!!!!::!!::::",
"**++++++++++++++++++++++!!!!++!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@ee!!::::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$$$@@@@@@@@@@@@$$**!!!!!!!!::::::::::::::::::::::",
"**++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@$$ee++!!::::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++!!!!!!!!++!!!!!!!!!!!!!!++$$$$$$$$@@@@@@@@ee**!!::::::::::::::::::::::::::::!!!!!!",
"****++**++++++++++++++++++!!!!++++!!!!!!!!!!!!++$$$$$$@@@@@@@@@@ee++!!::::::::::::::::::::::::::::!!!!::!!",
"++++++++++++++++++++++++++++++!!!!!!!!++!!!!++$$$$$$@@@@@@@@@@$$**!!::::::::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++++++++++!!++!!!!!!!!!!!!ee$$@@@@@@@@@@@@@@$$!!::::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!**$$@@@@$$$$@@@@@@@@ee::::::::::::::::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!::++$$$$@@@@@@@@@@@@$$@@ee!!::::::..::::::::::::::::::::::!!!!!!!!!!",
"++**++++++++++++++++++++++!!!!++++!!....**$$$$$$@@@@@@@@@@@@@@$$****!!::::::::::::::::!!::::!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!::  ..!!**$$$$$$@@@@@@@@@@@@@@@@$$ee++::::::::::::!!!!!!::!!!!!!!!!!!!!!",
"**++****++++++++++++++++++++!!!!!!..::!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@$$!!::::::!!!!!!!!::!!!!!!!!!!!!!!!!",
"++++++++++++**++++++++++++++++!!!!!!::!!!!!!++**$$@@@@@@$$@@@@@@@@$$@@@@**::::::!!!!!!::::::::::!!!!!!!!!!",
"++****++++++++**++++++++++++!!!!!!!!!!!!!!!!!!++++ee@@@@@@@@@@@@@@****eeee!!::::!!!!!!!!!!::!!::::!!!!!!!!",
"**++++++++++++++++++++++++!!!!!!!!++!!!!!!!!!!!!!!++ee@@@@@@@@@@@@@@ee!!**++::::!!!!!!!!!!!!::::::::!!!!!!",
"++**++++++++++++++++++++!!::::++++!!!!!!!!!!!!!!!!!!!!ee@@@@@@@@@@@@@@**++**::::!!!!!!!!!!!!::::!!!!!!!!!!",
"++++++++++++++++++++++++!!::::!!++++!!++!!!!!!!!!!::::++$$@@@@@@@@@@@@++++**::::!!!!!!!!!!!!::::!!!!!!!!!!",
"++****++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!::!!::++@@@@@@@@@@++::++++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++!!!!!!!!!!!!++!!!!++!!!!!!::!!::::**@@@@@@@@::::++++::::!!!!!!!!!!::!!!!!!!!!!!!!!",
"******++++++++++++++++++!!!!::!!!!!!!!!!!!!!++!!!!!!!!!!!!::**$$@@ee!!::!!::::::::!!!!!!!!::!!!!!!!!!!!!!!",
"******++++++++++++++++++!!!!::!!!!!!++++!!++++!!!!!!!!!!!!::::++++!!::::::::....::::!!!!!!!!!!!!!!!!!!!!!!",
"****++++**++++++++++++!!!!++!!::!!!!!!!!!!++++!!!!!!!!!!!!!!::::::::::::........::::!!!!!!!!::!!!!!!!!!!!!",
"****++++++++++++++++++++++++!!::::!!++!!++++!!!!!!!!!!!!!!!!::::::::::..        ..::!!!!!!!!!!!!!!!!!!!!!!",
"**********++++++++++++++++++++!!::!!!!!!!!!!!!++++!!!!!!!!!!!!!!!!::::....        ::::!!!!!!!!!!!!!!!!!!!!",
"******++**++++++++++++++++++++!!::::!!****++++++++++!!!!!!!!!!!!!!!!!!::..        ::!!!!!!!!!!!!!!!!!!!!!!",
"**++++****++++++++++++++++++++++!!::::!!++****++++++!!!!!!!!!!!!!!!!!!!!::        ::!!!!!!!!!!!!!!!!!!!!!!",
"**++++++**++++++++++++++++++++++++!!!!::::!!!!!!!!++++!!!!!!!!!!!!!!!!!!::..      ::!!!!!!!!!!!!!!!!!!!!!!",
"**++++**++++++++++++++++++++++++++!!!!!!!!::::!!++++++++++!!!!!!!!!!!!!!::..    ..::!!!!!!!!!!!!!!!!!!!!!!",
"**++++****++++++++++++++++++++++++++!!!!!!::::::::!!****++++!!!!!!!!!!::::..    ..::!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++++++++++++!!!!!!::::::!!++++++++!!!!!!!!::::::......::::!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::!!::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++!!!!!!!!::..::  ..::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++++!!!!!!::    ..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++++!!!!!!::....::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++++++!!!!!!..::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++++++++!!!!..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++!!++++++++++++!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++!!!!!!++++++!!::::::!!!!!!++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++!!!!!!::++++++!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++++++++++!!::..**ee++!!::::!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++!!!!!!::..::$$$$::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!::::..++@@::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++!!!!!!::::..ee**!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++**++++++++++++++++++++++!!++!!!!!!!!!!::!!$$ee!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++!!!!!!!!!!::::$$@@++::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++!!!!!!!!!!!!::ee$$ee::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++******++++++++++++++++++++++++!!++!!++!!!!!!::ee@@ee::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++!!!!++!!!!!!::ee@@++..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@$$**++!!!!!!::::!!!!++++**++++",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$@@$$@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!!!++++++++++",
"++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@@@@@$$@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!!!++++++++!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$@@$$$$@@@@@@@@@@@@@@@@@@ee!!!!::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::**$$$$$$@@@@@@@@@@@@@@@@@@@@@@**!!!!::::::::!!!!!!!!!!!!::",
"++++++++++++**++++++!!++!!!!!!!!!!!!!!!!!!!!::!!$$$$@@$$$$$$@@@@@@@@@@@@@@@@ee++!!::::::!!::::::!!::!!::::",
"**++++++++++++++++++++++!!!!++!!!!!!!!!!!!::!!ee$$$$$$$$$$@@@@@@@@@@@@@@@@ee++!!::::::::::::::::::::::::::",
"++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!++$$$$$$$$$$$$$$$$@@@@@@@@@@$$**++::::!!!!::::::::::::::::::::::",
"****++++++++++++++++++++!!!!!!!!!!!!!!!!**$$$$$$$$@@$$@@@@@@@@@@@@$$ee++!!::::::::::::::::::::::::::::!!!!",
"++++++**++++++++++++++++!!!!!!!!++++!!++$$$$$$$$$$$$@@$$@@@@@@@@$$**++!!!!::::::::::::::::::::::::::!!!!!!",
"****++**++++++++++++++++!!!!!!!!!!!!!!ee$$$$$$@@$$@@@@@@@@@@@@@@ee++::::::::::::::::::::::::::::::!!!!::!!",
"++++++++++++++++++++++++!!!!!!!!!!!!**$$@@@@$$@@@@@@@@@@@@@@@@$$++!!::::::::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++++!!++++!!!!::++$$$$@@@@@@$$@@@@@@@@@@@@@@ee!!::::::::::::::::::::::::::::!!!!!!!!::!!",
"++++++++++++++++++++++++!!++!!::!!$$$$$$$$$$@@@@@@@@$$@@@@@@@@ee::::::::::::::::::::::::::::!!!!::!!!!!!!!",
"++**++++++++++++++++++!!++!!::..!!ee$$$$$$$$@@@@@@@@@@@@@@$$@@ee++++!!::::::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++++++!!!!::::!!!!**ee$$@@@@@@@@@@@@@@@@@@@@@@$$$$ee++::::::::::::::!!::!!!!::!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!++**ee$$@@@@@@@@@@@@@@@@@@@@@@@@$$$$!!::::::!!!!!!!!!!::!!!!::!!!!!!!!",
"**++****++++++++++++!!!!!!!!!!!!!!!!!!++****ee$$@@@@@@@@@@@@@@@@$$$$@@@@ee::::::!!!!!!!!::::!!!!::!!!!!!!!",
"++++++++++++++++++++!!!!::!!++++++!!!!!!******ee@@@@@@@@$$@@@@@@$$++++ee$$!!::::!!!!!!!!!!::::::!!::!!!!!!",
"++****++++++++++++++!!::::!!!!++!!!!!!!!!!******ee$$@@@@@@@@@@@@@@$$++..!!++::::!!!!!!!!!!!!::::::::!!!!!!",
"**++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!++++**$$@@@@@@@@@@@@@@$$!!!!++::::!!!!!!!!!!!!::::::::!!!!!!",
"++**++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++ee@@@@@@@@@@@@$$::****!!..!!!!!!!!!!!!::::::!!!!!!!!",
"++++++++++++++++++++!!!!::!!!!!!!!!!++++!!!!!!::!!::::!!++ee@@@@@@$$**..**ee++::::!!!!!!!!!!::::!!!!!!!!!!",
"++****++++++++++++++!!!!::::!!!!!!!!++++++!!!!!!!!::!!!!::!!++$$@@**....$$**++::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++!!::!!!!!!!!++++++!!!!!!!!!!!!::!!::::!!ee$$::..++++!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++::::++!!!!++++++!!!!!!!!!!!!!!!!::::::::$$**    ::::::::!!!!!!!!::!!!!!!!!!!!!!!",
"******++++++++++++++++++++!!::!!++++!!!!!!++++!!!!++!!!!!!!!!!::::!!ee..    ::..::::!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++!!::!!!!++++++++++!!!!!!!!!!!!!!!!::::::++!!    ..  ..::!!!!!!!!::!!!!!!!!!!!!",
"****++++++++++++++++++++++++++!!::::!!!!++++++++!!!!++!!!!!!!!!!::::::!!..  ..  ..::::!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++!!!!::::!!!!!!!!!!++++!!!!!!!!!!!!::::..::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++++++++!!!!!!::::!!!!!!++++++++++!!!!!!::......::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++******++++++++++++++++++++++!!!!!!::::!!!!!!!!!!++++++!!!!!!::......::::::..::!!!!!!!!!!!!!!!!!!!!!!",
"**++++++**++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!++++!!!!!!::  ..::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++!!!!!!!!!!::!!!!!!!!!!++!!!!!!............::::::!!!!!!!!!!!!!!!!!!!!!!",
"**++++++**++++++++++++++++++++++++++++!!!!!!!!..::!!!!!!!!!!!!::::::::::......::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!::..!!!!!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++!!!!!!!!..::........::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++!!!!!!::    ....::::::::::::::!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++!!!!!!::....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++++++++++!!!!::..::::::::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++!!!!++++++++++!!!!::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++!!!!!!!!++++++++!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++!!!!!!::!!****++!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++!!!!!!::!!$$$$ee!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++!!!!!!::::$$$$ee::::::!!!!!!!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++!!!!!!::..**$$$$::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++!!++!!::::::ee$$!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!::::!!$$**!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!::::ee$$ee!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++!!!!!!!!::**$$$$ee::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!::++$$@@@@!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++!!!!!!!!!!++$$$$$$!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++!!!!!!!!!!++$$$$$$::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++******++++****++++++++++++++++++++++++!!!!++$$@@ee....::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++++++!!!!!!::**$$$$::....::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!**@@$$@@@@@@$$@@@@@@@@@@@@@@@@$$**++!!!!!!::::::!!++++++++++",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@ee**!!!!!!!!!!!!!!++++++++++",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!!!++++++++!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@$$@@@@$$@@@@@@@@@@@@@@@@@@ee!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@**!!!!::::::::!!!!!!!!!!::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++$$$$$$$$$$$$@@@@@@@@@@@@@@@@@@ee++!!::::::!!::::!!!!::!!::::",
"**++++++++++++++++++!!!!!!!!++!!!!!!!!!!!!!!ee$$$$$$@@$$$$@@@@@@@@@@@@@@@@$$++!!::::::::::::::::::::::::::",
"++++++++++++++++++++++!!++!!!!!!!!!!!!!!++**$$$$$$$$@@@@@@@@@@@@@@@@@@$$**!!::::::!!::::::::::::::::::::::",
"**++++++++++++++++++++!!!!!!!!!!!!!!!!++ee$$$$$$$$@@@@@@@@@@@@@@@@@@ee++!!!!::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!**$$$$$$$$@@@@@@@@@@@@@@@@$$ee++!!!!!!::::::::::::::::::::::::!!!!!!",
"****++++++++++++++++++!!!!!!!!!!!!!!++$$$$$$$$@@@@@@@@@@@@@@@@@@ee++!!::::::::::::::::::::::::::::!!!!::!!",
"**++++++++++++++++++++!!!!!!!!!!!!!!ee$$@@@@@@@@@@@@@@@@@@@@@@$$++!!!!::::::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++!!!!!!++!!!!!!**$$@@@@@@@@@@@@@@@@@@@@@@@@$$!!::::::::::::::::::::::::::::!!!!!!!!::!!",
"++++++++++++++++++++!!!!!!!!!!::++$$$$$$@@$$@@@@@@@@$$@@@@@@@@ee::::::::::::::::::::::::::::!!!!::!!!!!!!!",
"++**++++++++++++++++++!!!!!!::::ee$$$$$$@@@@@@@@@@@@@@@@@@$$@@ee++!!::::::::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++++++!!!!::::++ee$$@@@@@@@@@@@@@@@@@@@@@@@@$$$$ee**!!::::::::::::!!!!!!!!!!::!!!!!!!!!!",
"++++++++++++++++++++++!!!!::::!!!!++**ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$$$::::::::!!!!!!!!!!::!!::::!!!!!!!!",
"++++****++++++++++++!!!!!!!!!!!!!!!!**ee**$$@@@@@@@@@@@@@@@@@@@@$$$$@@@@**::::::!!!!!!!!!!::!!!!::!!!!!!!!",
"++++++++++++++++++++!!!!::!!++!!!!!!++******$$@@@@@@@@@@$$@@@@@@$$!!++ee$$::::::!!!!!!!!!!::::::::::!!!!!!",
"++++++++++++++++++++!!::::!!++!!!!!!!!++ee****ee@@@@@@@@@@@@@@@@@@$$!!::!!++::::!!!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!++****ee$$$$@@@@@@@@@@@@@@$$::::++::::!!!!!!!!!!!!::::::!!!!!!!!",
"++**++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!++++ee$$@@@@@@@@@@@@$$::++**!!::::!!!!!!!!!!::::::!!!!!!!!",
"++++++++++++++++++++!!!!::!!!!::!!!!!!!!!!!!::::!!!!!!**$$$$@@@@@@$$**..++**++::::!!!!!!!!!!::::!!!!!!!!!!",
"++**++++++++++++++++!!!!::::!!!!!!!!++++!!!!!!::::::::::::++ee$$@@ee....ee**++::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!::!!!!!!++++++!!!!::::!!!!!!::::::::++$$$$::..****!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++::!!++!!++++++++!!!!!!!!!!!!::::::::::++$$++    ::::::::!!!!!!!!::!!!!!!!!!!!!!!",
"******++++++++++++++++++++!!::++++++!!!!!!++!!!!!!!!!!!!!!!!::::::++$$..      ..::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++::::!!++++++++++!!!!!!!!!!!!!!!!!!::::::ee!!        ..::!!!!!!!!::!!!!!!!!!!!!",
"**++++++++++++++++++++++++++!!::::++++++++++++!!++!!!!!!!!!!!!::::..++++          ::!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++!!::::!!!!!!!!!!++++++++!!!!!!!!!!::..!!**          ..::!!!!!!!!!!!!!!!!!!!!",
"**++++++**++++++++++++++++++++++!!!!!!::::!!++****++++++++!!!!!!::..!!++::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!::::::!!++++++++++++++!!!!::..::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++**++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!::........::::..::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!::..................::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!::..::::::..........::::::::::..::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!::        ....::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++!!!!!!::    ........::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!......::::::::::::::::!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++++++++++++!!!!!!....::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++++++!!!!....::::::::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!++++++++++!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++!!!!!!!!++++++++::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!::++$$$$ee::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!::ee$$$$ee::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!::$$$$$$ee::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!::++$$$$ee::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++!!!!!!::::$$$$$$!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!::++$$@@ee++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++!!!!!!!!::ee$$@@$$++::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++****++++++++++++++++++++++++!!!!!!!!!!::**$$@@$$$$::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!++$$$$@@$$!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!$$$$$$$$++::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!$$$$$$$$::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++******++++****++++++++++++++++++++!!!!!!!!$$$$@@ee....::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++++++++!!!!++$$$$$$::..::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee@@$$@@@@@@$$@@@@@@@@@@@@@@@@ee++!!!!::::::....!!++++++++++",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!!!++++++++++",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!!!++++++++++",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**$$$$@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!!!!!!!++!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++ee@@$$@@@@@@@@@@@@@@@@@@@@@@@@**!!::::::::!!!!!!!!!!!!!!::",
"++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!++**$$$$$$$$$$@@@@@@@@@@@@@@@@@@$$++!!::::::::!!::!!!!::::::::",
"**++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++$$$$$$@@$$@@@@@@@@@@@@@@@@@@@@ee!!::::::::::::::::::::::::::",
"++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!++ee$$$$$$@@@@@@@@@@@@@@@@@@@@$$**!!::!!!!::::::::::::::::::::::",
"**++++++++++++++++++++!!!!!!!!!!!!!!!!!!++**$$$$$$@@@@@@@@@@@@@@@@@@$$**++!!!!::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!++++**$$$$@@@@@@@@@@@@@@@@@@@@ee++!!!!!!::::::::::::::::::::::::!!!!!!",
"****++++++++++++++++++!!!!!!!!!!!!!!++**$$$$$$@@@@@@@@@@@@@@@@@@$$!!!!::::!!::::::::::::::::::::::!!!!::!!",
"**++++++++++++++++++!!!!!!!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++!!!!!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@@@@@ee!!::..::::::::::::::::::::::::!!::!!!!::!!",
"++++++++**++++++++++++!!!!!!!!!!!!**$$$$@@$$@@@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::::::::!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!++$$$$$$@@@@@@@@@@@@@@@@@@$$@@ee!!::::::::::::::::::::::::::::::::!!!!!!!!",
"++****++++++++++++++++++!!!!!!::++$$$$@@$$@@@@@@@@@@@@@@@@@@@@$$++!!::::::::::::::::!!!!!!::::::::::!!!!!!",
"++++++++++++++++++++++!!!!!!::::!!++$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$ee!!!!::::::::!!!!!!!!!!::!!::::!!!!!!!!",
"++++**++++++++++++++!!!!!!!!::::!!++**ee$$$$@@@@@@@@@@@@@@@@@@@@@@$$ee++::::::::!!!!!!!!!!::!!!!::!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!**eeee$$@@@@@@@@@@$$@@@@@@$$!!++**++::::::::!!!!!!!!::::::::::!!!!!!",
"++++++++++++++++++++!!!!::!!++!!!!!!!!!!**eeee$$@@@@@@@@@@@@@@@@@@++::!!**!!::::!!!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++!!!!!!::!!++++!!!!!!!!!!++++**$$@@@@@@@@@@@@@@@@@@**....!!::::::!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!!!++**ee$$@@@@@@@@@@@@$$!!!!++!!::::!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!::!!!!!!++ee$$$$@@@@@@$$::!!**++::::!!!!!!!!!!::::::!!!!!!!!",
"++++++++++++++++++++!!!!!!::::!!!!!!!!++!!!!!!::::::::::::!!++$$@@$$!!..++****::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!::::!!!!++++++!!!!!!::!!!!!!!!::::::!!**$$::..****!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++++!!::::!!!!!!++++++!!!!!!!!!!!!!!!!!!::::::!!!!  ..!!!!::::::!!!!!!::!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++!!::!!!!!!!!!!++++!!!!!!!!!!!!!!!!!!::::::!!..    ....::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++::::!!++++++++++++!!++!!!!!!!!!!!!!!::::..::        ..::!!!!!!!!::!!!!!!!!!!!!",
"**++++++++++++++++++++++++++!!::!!!!****++++++++++++++!!!!!!!!!!!!..  !!          ::!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++!!::::!!!!!!!!!!++++++++!!!!!!!!::::....!!..        ..::!!!!!!!!!!!!!!!!!!!!",
"++++++++**++++++++++++++++++++++!!!!::::::!!!!++**++!!!!!!!!::......::::::::::::  ..!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!::::........::::::::::::::::::!!::!!!!!!!!!!!!!!!!!!",
"++++++++**++++++++++++++++++++++++!!!!!!!!::::::::::::........................::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!::......      ....................::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!++++++++++++!!!!!!::..        ........::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!::    ........::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++!!!!!!!!..  ......::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!....::::::::::::::::::::!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++++!!!!!!....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!++++++++++++!!!!..::::::::::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!++++++++++!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++!!!!!!!!++++++++::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!**ee**::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!++$$$$ee::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!::++$$$$**::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++!!!!!!!!::++@@$$**::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++!!!!!!::::$$$$$$::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!::++$$$$**!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!::ee$$$$ee!!::::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++!!!!!!!!!!::++$$@@@@ee::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!$$$$@@@@::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!$$$$$$$$!!..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!ee@@$$$$::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++******++++****++++++++++++++++++++!!!!!!!!ee$$@@**....::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++**++++++++++++++++++++++++++!!!!!!ee$$$$::..::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!ee$$$$@@@@@@$$@@@@@@@@@@@@@@@@ee++!!!!::::::....!!++++++++++",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@**++!!!!!!!!!!::!!++++++++++",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!!!!!!!!!!!!!++++++++++",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::!!!!!!!!!!!!++++++!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!++**ee$$$$$$@@@@@@@@@@@@@@@@@@@@**!!::::::::!!::!!!!::::::::",
"**++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@$$@@@@@@@@@@@@@@@@@@@@$$++::::::::::::::::::::::::::",
"++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!**ee$$@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!::::::::::::::::::::::",
"**++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++$$$$$$@@@@@@@@@@@@@@@@$$ee++::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!++!!++ee$$@@@@@@@@@@@@@@@@@@ee!!!!!!::::::::::::::::::::::::::!!!!!!",
"****++++++++++++++++++++!!!!!!!!!!!!++++++ee$$@@@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::::::!!!!::!!",
"**++++++++++++++++++++!!!!!!!!!!!!!!!!++**@@$$@@@@@@@@@@@@@@@@@@++::..::::::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++++!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@$$::::..::::::::::::::::::::::::!!::!!!!::!!",
"++++++++**++++++++++++++!!!!!!!!!!!!++$$@@$$@@@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::::::::!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!ee$$@@@@@@@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::::::::!!!!!!!!",
"++****++++++++++++++++++!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::::::::::::!!::::::::::::::!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!ee$$@@@@@@@@@@@@@@@@@@@@@@@@$$ee!!::::::::::::::!!!!!!!!::!!::::::!!!!!!",
"++++**++++++++++++++!!!!!!!!!!!!::!!**$$$$$$@@$$@@@@@@@@@@@@@@@@@@**!!::::::::::::!!!!!!!!::!!::::!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!::::!!++**ee$$@@@@@@@@@@@@$$@@@@@@$$!!++!!!!::::::::!!!!!!!!::!!::::::!!!!!!",
"++++++++++++++++++++++!!!!!!::!!!!!!!!!!****ee$$@@@@@@@@@@@@@@@@@@++::!!**!!::::::!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!******$$@@@@@@@@@@@@@@@@$$++  ..!!::::::!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++++++!!::!!!!!!!!!!!!!!!!++++++ee$$$$@@@@@@@@@@@@@@!!::!!!!::::!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++!!!!!!::!!++!!!!!!!!!!!!::::!!!!!!!!++**$$@@@@$$@@!!::++++::::!!!!!!!!!!::::::!!!!!!!!",
"++++++++++++++++++++!!!!!!::!!++!!!!!!!!!!!!!!::::::::::::::!!++$$$$++..::++++::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!::!!!!!!!!!!!!::::!!!!::::::::::::++!!  ::++!!::::::!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++!!!!!!!!!!!!!!!!!!++++!!!!!!!!!!!!!!::::::::..::....++!!::::::!!!!!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!::!!!!!!!!!!++++++!!!!!!!!!!!!!!!!::::..::..    ....::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++::::::!!!!!!!!++++!!!!!!!!!!!!!!::::::::....        ..::!!!!!!!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!!!!!::..........            ::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!++++++!!::::!!++++!!!!!!!!++!!!!::........::::::....      ..::!!!!!!!!!!!!!!!!!!!!",
"++++++++**++++++++++++++++++++++!!::::!!!!!!!!!!!!++!!::::..::::::::::::::::::::....::!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++!!!!::::::::::!!!!!!::::::::::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++**++++++++++++++++++++++++!!!!!!::::::::::::::::::::..................::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!::::....::::........................::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!++++++++++++!!!!!!::....::............::::::::....::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!::  ..........::::::..::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++!!!!!!!!......::..::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!......::::::::::::::::!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!++++++++++!!!!!!....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++++++!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!++++++++++!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++!!!!!!++++++++++::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!++**++::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!**$$**::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++!!!!!!!!!!$$$$**::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++!!!!!!::!!$$$$ee::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!::**$$$$++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!",
"++++++++++++++++++++++++++++++++!!!!!!::!!$$$$$$**!!::::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++!!!!!!!!!!!!++$$@@@@ee::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!::!!$$$$@@@@::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!::::ee$$@@$$**..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$@@++..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++****++++++++++++++++++!!!!!!!!!!++$$@@$$::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++++++!!!!!!!!**$$@@++..::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@$$@@@@@@@@@@@@@@@@@@@@@@$$++!!!!::::::....::++++++++++",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!::!!++++++++++",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!!!!!!!!!!!!!++++++++++",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++!!ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@**!!!!!!!!!!!!!!!!++++++!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::::!!!!::!!!!::::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++**ee$$$$@@@@@@@@@@@@@@@@@@@@$$**!!::::::::::::::::::::::::",
"++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@$$++!!!!!!::::::::::::::::::::::",
"**++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@ee++!!::!!::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@ee!!::::!!::::::::::::::::::::::::!!!!!!",
"****++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!ee@@$$@@@@@@@@@@@@@@$$::::..::::::::::::::::::::::::::!!!!::!!",
"**++++++++++++++++++++++!!!!!!!!!!!!!!++!!**$$@@@@@@@@@@@@@@@@@@**....::::::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++++++++!!!!!!!!!!!!++++$$@@@@@@@@@@@@@@@@@@@@++....::::::::::::::::::::::::!!::!!!!::!!",
"++++++++**++++++++++++++!!!!!!!!!!!!!!++$$$$$$@@@@@@@@@@@@@@@@$$::::::::::::::::::::::::::::::::::!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@$$!!::::::::::::::::::::::::::::::::!!!!!!!!",
"++****++++++++++++++++++!!!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@@@$$!!::::::::::::::::::!!::::::::::::::!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!$$@@$$@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::::::::!!!!!!!!::!!::::::!!!!!!",
"++++**++++++++++++++++++!!!!!!!!!!!!ee$$$$$$@@$$@@@@@@@@@@@@@@@@$$**!!::::::::::::!!!!!!!!::!!::::!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!::::++**ee$$@@@@@@@@@@@@$$@@@@@@@@++++!!!!::::::::!!!!!!!!::!!!!::::!!!!!!",
"++++++++++++++++++++++++!!!!!!!!::!!!!!!****ee$$@@@@@@@@@@@@@@@@@@**..!!****::::::!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++++++++!!!!::!!!!!!!!!!++**ee$$@@@@@@@@@@@@@@@@@@**    !!::::::!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!++++**ee$$$$@@@@@@@@@@@@@@**::::::::::!!!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!++****ee$$@@@@$$@@++..++!!::::!!!!!!!!!!::::::!!!!!!!!",
"++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!::!!!!::!!::::!!++$$$$**::  ++!!::::!!!!!!!!!!!!::!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!::::::!!!!::!!!!!!!!::::::::::::::::!!**++    !!::::::::!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!::::::::::::::..::..  ::!!::::::!!!!!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++++!!!!!!!!!!::::::::::..::..    ....::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!::!!!!!!!!!!++++!!!!!!!!!!!!::::..::......          !!!!!!!!!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++::::::!!!!!!!!!!!!!!!!!!::::................        ..::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!++++++!!::::::!!++!!!!!!!!!!!!::......::::::::::::......    ::!!!!!!!!!!!!!!!!!!!!",
"++++++++**++++++++++++++++++++++!!::::!!!!!!!!!!!!::::::::::::::::::::::::::::::::..::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!::::::::::!!!!::::!!::::::::::::::::::::::::::..::!!!!!!!!!!!!!!!!!!!!",
"++++++++**++++++++++++++++++++++++!!!!!!::::::::::::::::::::..................::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!++++++++++!!!!!!::::..::!!::::........................::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!++++++++++++!!!!!!::..::::..............::::::......::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++!!++++++++++++!!!!::::............::::::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++!!!!!!::......::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!::......::::::::::::::::!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++++++++++!!!!!!....::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++++++!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!++++++++++!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++!!++++++++++!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!++++++::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!****::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!**$$ee::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!ee$$$$::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!::**$$$$**!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!",
"++++++++++++++++++++++++++++++++!!!!!!!!++$$$$$$**!!::::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++!!!!!!!!::!!**$$@@@@$$::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!::!!$$@@@@@@**::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!**@@$$$$$$..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++!!!!!!!!!!++$$$$$$$$::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++****++++++++++++++++++!!!!!!!!!!++$$@@$$++::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++++++!!!!!!!!++$$$$$$::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!::::::::....!!++++++++",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$ee++!!!!!!!!!!!!!!++++++++",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!!!!!!!!!!!++++++++++",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++++$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$++::!!!!!!!!!!!!++++++++",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++ee@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++**@@@@@@@@@@@@@@@@@@@@@@@@@@**::::::::!!::!!!!::::::::",
"**++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++ee@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::::::::::::!!::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++**ee$$$$@@@@@@@@@@@@@@@@$$**!!!!!!::::::::::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!**ee$$@@@@@@@@@@@@@@@@ee**!!::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++$$$$$$@@@@@@@@@@@@@@++::::::::::::::::::::::::::::::!!!!!!",
"****++++++++++++++++++++++!!!!!!!!!!!!!!++!!!!**$$$$@@@@@@@@@@@@@@**....::::::::::::::::::::::::::::!!::!!",
"**++++++++++++++++++++++++!!!!!!!!!!!!!!!!++++$$$$@@@@@@@@@@@@@@$$::..::::::::::::::::::::::::::::::!!!!!!",
"++**++++++++++++++++++++++++!!!!!!!!!!++!!++ee$$@@@@@@@@@@@@@@@@ee....::::::::::::::::::::::::!!::!!!!::!!",
"++++**++**++++++++++++++++!!!!!!!!!!!!!!!!ee$$@@@@@@@@@@@@@@@@@@**::::::::::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++++++++!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@**::::::::::::::::::::::::::::::::!!!!!!!!",
"++****++++++++++++++++++++++!!!!!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@**::::::::::::::::::!!::::::::::::::!!!!!!",
"++**++++++++++++++++++++++++!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@ee!!::::::::::::::!!!!!!!!::!!::::::!!!!!!",
"++++**++++++++++++++++++++!!!!!!!!!!!!$$$$$$$$@@@@@@@@@@@@@@@@@@@@++!!::::::::::::!!!!!!!!::!!::::!!!!!!!!",
"++++++++++++**++++++++++++++!!!!!!!!!!++ee$$@@@@@@@@@@@@$$@@@@@@@@**::++!!!!::::::!!!!!!!!!!::!!::::!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::::!!++**ee$$@@@@@@@@@@@@@@@@@@$$!!..!!**!!::::!!!!!!!!!!!!::::::::!!!!",
"++++++++++++++++++++++++++++!!!!::::::!!!!++**eeee@@@@@@@@@@@@@@@@@@$$::  ..!!::::::!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++++++++++!!!!::!!!!!!!!!!!!****eeee@@@@@@@@@@@@@@@@$$!!::::::::::!!!!!!!!::::::::!!!!!!",
"++++++++++++++++++++++++!!++!!::::!!!!!!!!!!!!!!!!!!++**ee$$$$@@@@$$$$$$..::!!!!::::!!!!!!!!::::::!!!!!!!!",
"++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!::!!!!!!!!**$$@@ee::  ::!!!!::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!::::::!!!!!!::!!!!!!::::::::::::::::!!$$**..  ::!!::::::!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++!!!!::::::::!!::::::!!::::::::::::::::::!!ee..    !!::::::!!!!!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!::::::::::::::..!!**      ....::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!++!!!!!!!!++!!!!!!::::::::::..::::::++..        ..!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!::::::::::::::::::::::::::..    ::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!::::::::::::::::::::::::::!!!!::....!!!!!!!!!!!!!!!!!!!!",
"++++++++**++++++++++++++++++++++!!::::::!!!!!!!!!!::::::::::::::::::::::::!!!!!!::....::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!::::::!!!!!!!!::!!::::::::........::::::::::::..::!!!!!!!!!!!!!!!!!!!!",
"++++++++**++++++++++++++++++++++++++!!::::::::::::::::::::........        ........::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!::::::..::!!::............................::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!::::::::    ............::::......::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!::............::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++!!!!!!::..  ..::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!::......::::::::::::::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!::....::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!++++++++++++::..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!++++++++++++::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++!!!!++++++++++::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!++++++++::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++!!!!!!!!!!++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!++::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!**$$!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!++ee$$**::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!++$$$$$$!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!ee$$$$$$++!!!!::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++!!!!!!!!++$$@@@@$$**::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!++**$$@@@@@@!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!++$$$$$$@@**..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++++++++++++++++++++++++!!!!!!!!!!!!**$$@@@@++..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++****++++++++++++++++++!!!!!!!!!!!!++@@@@$$::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++++!!!!!!!!!!!!++$$@@++::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!::::::!!++++++",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!++++++**",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!!!!!!!!!!!!!++++++++",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++**@@@@@@@@@@@@@@@@@@@@@@@@@@$$**::!!::!!!!!!!!!!++!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++!!++$$@@@@@@@@@@@@@@@@@@@@@@@@$$++::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++ee@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::::!!!!::::::::",
"**++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++**ee@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++**$$$$@@@@@@@@@@@@@@$$ee++::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++**ee$$$$@@@@@@@@@@@@**!!::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++$$$$$$@@@@@@@@@@@@++::....::::::::::::::::::::::::!!!!!!",
"**++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!ee$$@@@@@@@@@@@@@@$$::......::::::::::::::::::::::::!!::!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@**....::..::::::::::::::::::::::::!!!!!!",
"++****++++++++++++++++++++++!!!!!!!!!!!!!!++++$$$$@@@@@@@@@@@@@@@@!!..::::::::::::::::::::::::!!::!!!!::!!",
"++++**++**++++++++++++++++++!!!!!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@!!..::::::::::::::::::::::::::::!!!!!!!!",
"++**++++++++++++++++++++++!!++!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@!!::::::::::::::::::::::::::::::!!!!!!!!",
"++****++++++++++++++++++++++!!!!!!!!!!!!!!$$$$@@@@@@@@@@@@@@@@@@$$++::::::::::::::::!!::::::::::::::!!!!!!",
"++**++++++++++++++++++++++++!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::::::::!!!!!!::!!::::::!!!!!!",
"++++**++++++++++++++++++++++!!!!!!!!!!!!$$$$$$@@@@@@@@@@@@@@@@@@@@$$!!!!!!::::::::::!!!!!!!!!!::::!!!!!!!!",
"++++++++++++**++++++++++++++!!!!!!!!!!++ee$$$$@@@@@@@@@@$$@@@@@@@@@@**..!!++++::::::!!!!!!!!!!::::::!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!::!!++**ee$$$$@@@@@@@@@@@@@@@@@@@@++  ::++!!::::!!!!!!!!!!::::::::::!!",
"++++++++++++++++++++++++++++!!!!!!::..::!!++****ee$$$$@@@@@@@@@@@@@@@@@@**::..!!::::!!!!!!!!!!::::::::::!!",
"++++++++++++++++++++++++++++!!!!::::::!!!!!!!!++++++++**ee$$@@@@@@@@@@$$$$..++!!::::!!!!!!!!!!::::::!!!!!!",
"++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!++eeee@@$$$$$$++  ++++!!::::!!!!!!!!::::::!!!!!!",
"++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!::!!!!!!::++$$$$++    !!::::::::!!!!!!!!!!::!!!!!!!!",
"++++++++++++++++++++++++++!!!!::::::!!!!::::!!!!::::::::::::::::::++ee++..  !!!!::::::!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++!!!!::::::::!!!!!!!!!!!!::::::::::::::::::::::::::::::..::::::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::::::::::::....::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!++!!!!!!!!!!!!!!::::::::::::::::::::::::!!::::....::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!::::::::::::::::::::!!!!!!::::::  ::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!::::::::::::::::::::::::::!!!!::::....!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!::::::!!!!!!::!!!!::::::::::..........::::::::::....!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++++!!!!::::::::..::!!!!::::::........    ..  ..........::!!!!!!!!!!!!!!!!!!",
"++++++++**++++++++++++++++++++++++++!!::::::::::::!!::............  ..  ............::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!::::::::::::..      ............::::........::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::::..        ......::::::::::::..::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!::..    ......::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!::....::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++++++++!!!!!!::....::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!::..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!++++++++++++!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++!!!!!!++++++++++!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++!!++++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!++!!!!!!!!++**::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!++++$$$$**!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!**$$$$$$**!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!++ee$$$$$$$$++::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$$$$$$$::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++**$$$$@@@@!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++$$$$@@@@++::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++!!!!!!!!!!!!++ee@@$$$$::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++++**++++++++++++++++!!!!!!!!!!!!!!!!**@@$$++::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++++!!!!!!!!!!!!!!**$$ee::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$ee++!!!!!!!!!!!!++++++",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!!!!!!!!!!!++++++++",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@**!!!!!!!!!!!!!!!!++!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++ee@@@@@@@@@@@@@@@@@@@@@@@@@@$$++::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@$$++::!!::::::::::::::::::",
"**++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++++++**$$@@@@@@@@@@@@@@@@@@@@ee++::::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++**ee$$@@@@@@@@@@@@@@$$**!!::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@$$::::....::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@ee......::::::::::::::::::::::::!!::!!",
"++++**++++++++++++++++++++++++!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@@@@@@@++......::::::::::::::::::::::::!!!!!!",
"++**++++**++++++++++++++++++!!!!!!!!!!!!!!++++ee$$@@@@@@@@@@@@@@@@@@!!..::::::::::::::::::::::!!::!!!!::!!",
"++++**++**++++++++++++++++++++!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@!!::::::::::::::::::::::::::::!!!!::!!",
"++**++++++++++++++++++++++++!!++!!!!!!!!!!!!$$$$$$@@@@@@@@@@@@@@@@@@++::::::::::::::::::!!::::::::!!!!!!!!",
"++****++++++++++++++++++++++!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@ee!!::::::::::::::!!!!::::::!!::!!!!!!",
"++**++++++++++++++++++++++++++!!!!!!!!!!!!$$@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::::::::::::!!!!!!!!!!::!!::!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!**@@$$$$@@@@@@@@@@@@@@@@@@@@@@**::++++!!::::::!!!!!!!!!!::!!!!::::",
"++++++++++++**++++++++++++++!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@$$++::++**::::::!!!!!!!!!!::::::::!!",
"++++++++++++++++++++++++++++!!!!!!!!::::!!++**ee$$@@@@@@@@@@@@@@@@@@@@@@@@**::::!!::::!!!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!::::!!!!++****ee$$@@@@@@@@@@@@@@@@@@@@$$::++++::::!!!!!!!!!!::::::::!!",
"++++++++++++++++++++++++++++!!!!!!::::::::::!!++++++++****ee$$@@@@@@@@@@@@ee..**++!!::::!!!!!!::::!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!..::!!!!!!!!!!!!!!!!!!!!!!++****ee****++....++!!!!::::!!!!!!!!!!::!!!!!!",
"++++++++++++++++++++++++++++!!!!::..!!::::::!!!!!!!!!!!!::!!::::::::::::::::::::::::::::!!!!!!!!::!!!!!!!!",
"++++++++++++++++++++++++++++!!!!::::::!!::::!!!!::::::::::::::::::::::::::::::::::..::::!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++!!!!::::::::::::!!!!!!!!::::::::::::::::::::::::::::::....::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!::::::::::::::::::::::!!!!!!::::....::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!::::::::::::::::::::::::!!!!::::::..::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!::::::::::::::::::::!!::::::::::....!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!::::!!::::::::..............::::::......!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!::::::!!!!!!::::!!::::::::......      ..  ..........::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!::::::::..::::::....  ....................  ......::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!::::........          ........::::::::......::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!::::::..          ......::::::::::..::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::::::      ........::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!::::........::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++!!!!!!::......::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++++++++++!!!!::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!::..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!++++++++++++++!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++!!++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!++!!!!!!!!++****::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!++**$$$$!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!++ee$$$$ee++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!++ee$$@@$$$$ee++::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!++**$$$$$$$$@@$$::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$@@@@@@@@!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!++**$$$$@@@@$$!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++ee$$@@@@ee..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++!!!!!!!!!!!!++**$$@@$$!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++++**++++++++++++++++!!!!!!!!!!!!!!!!++$$@@**::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++**++++++++++++++++++++++!!!!!!!!!!!!!!++$$$$!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!!!!!!!!!!!++++",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++**$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@**!!::::::!!!!!!!!::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++++ee@@@@@@@@@@@@@@@@@@@@@@@@@@ee++::::::::::!!::::!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++!!++ee$$@@@@@@@@@@@@@@@@@@@@$$ee!!::!!::::::::::::::::",
"**++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++!!++++ee$$@@@@@@@@@@@@@@@@$$ee++!!::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**ee@@@@@@@@@@@@@@@@ee!!::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++ee$$@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++ee$$$$@@@@@@@@@@@@@@!!........::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@$$::..::::::::::::::::::::::::!!::!!",
"++++**++++++++++++++++++++++++++!!!!!!!!!!!!!!++++$$$$@@@@@@@@@@@@@@@@$$::::::::::::::::::::::::::::!!::!!",
"++**++++**++++++++++++++++++++++!!!!!!!!!!++!!++$$$$$$@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::!!!!::!!",
"++++**++**++++++++++++++++++++++!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@$$++::::::::::::::!!::::::::!!!!!!!!",
"++**++++++++++++++++++++++++++++!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@@@@@$$**!!::::::::::!!!!::::::!!!!::!!",
"++****++++++++++++++++++++++++!!!!!!!!!!!!!!$$$$@@@@@@@@@@@@@@@@@@@@@@$$$$ee**!!::::::::!!!!!!!!!!::!!::!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@$$@@******++::::::!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++++++!!!!!!!!$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++++**!!::::!!!!!!!!!!!!::::::",
"++++++++++++**++++++++++++++++!!!!!!!!!!**$$@@@@@@@@@@@@$$@@@@@@@@@@@@@@@@@@$$!!!!++::::!!!!!!!!!!::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!++**ee$$@@$$@@@@@@@@@@@@@@@@@@@@@@@@@@**++**!!::!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!::!!!!**eeee$$@@@@@@@@$$$$ee********ee$$++****++::::!!!!!!!!::::::!!",
"++++++++++++++++++++++++++++++!!!!!!::::::!!++******ee$$$$ee!!::::::::::::::::::++++!!::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!::..!!!!!!!!!!!!!!++++++::::::::::::::::::::::::::..::::!!!!!!!!::!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::::::!!!!!!::!!!!!!!!!!::::::::::::::::::::::::::::::::!!!!!!::!!!!!!!!",
"++++++++++++++++++++++++++++!!!!::::::!!::::!!!!::::!!::::::::::::::::::::::::!!!!!!::::::!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++!!!!::::::::!!::::!!!!!!!!::!!::!!::::::::::::::::!!!!!!::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!::::::::::::::::::::::::!!!!!!::::..::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::!!::::::::::::....::::::!!::::....::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!::::::::::::::..............::::......::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!!!!!::..................      ............::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!::::::!!!!!!::::::..          ........................::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++::..::::!!::::::::::          ..............::......::!!!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++!!::::......::::::..      ..........::::::..::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!::::::..........    ........::::::::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++++!!!!::::::..        ......::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::::::      ..::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!::........::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!++++++++++!!!!::::....::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++!!++++++++++++::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!++++!!++::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!++!!!!++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++++**::!!!!!!!!::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++ee$$++!!!!!!::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!++ee$$$$$$**++++::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$$$@@$$@@@@++..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$$$$$$$@@@@ee..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$$$@@@@@@@@++..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!++**$$$$@@@@$$$$..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++ee$$@@$$@@!!..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++**$$@@@@ee..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++++**++++++++++++++++++!!!!!!!!!!!!!!++$$$$@@!!..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++**++++++++++++++++++++++!!!!!!!!!!!!!!++ee$$ee..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!++",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++**$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++++ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++::::::!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++**@@@@@@$$@@@@@@@@@@@@@@@@@@$$++!!::::::::::::::!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++++$$@@@@@@@@@@@@@@@@@@@@@@ee++!!::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++**$$@@@@@@@@@@@@@@@@$$$$**!!::::::::::::::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++**$$@@@@@@@@@@@@@@$$**!!::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++**ee@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++ee$$$$@@@@@@@@@@@@ee::::::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::!!::::",
"++++**++++++++++++++++++++++++!!!!!!!!!!!!!!!!++++ee$$$$@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::!!::::",
"++**++++**++++++++++++++++++++++!!!!!!!!!!++!!++ee$$$$@@@@@@@@@@@@@@@@@@++!!::::::::::::::::::!!::!!!!::::",
"++++**++**++++++++++++++++++++!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@$$ee++!!::::::::::::::::::::!!::::",
"++**++++++++++++++++++++++++++!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$ee!!::::::!!!!::!!::!!!!::::",
"++****++++++++++++++++++++++++++++!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@$$@@@@@@@@ee!!::::!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@$$@@@@ee$$$$**::::!!!!!!!!!!!!::!!::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$ee******::::::!!!!!!!!!!!!::::",
"++++++++++++**++++++++++++++++!!!!!!!!!!**$$$$@@@@@@@@@@$$@@@@@@@@@@@@@@@@@@@@**!!++!!::!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++**++::::!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++!!!!!!!!**@@$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++****!!::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@$$$$$$$$$$$$$$eeee++++ee**!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!**ee$$$$$$@@@@@@$$**++!!::::::::::!!!!::::++$$**!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::::++******ee$$$$@@**::::::::::::::::::::::::!!++!!::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!::::!!++******ee$$$$::::::::::::::::::::::::::!!::::::::::!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++!!!!::::!!!!!!++********++..::::::::::::::::::::!!!!::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!::::!!!!!!++++**++..  ..::::::::::::::::::::!!!!!!::::..::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!::..  ..::::::::::::::::::::::!!::::....::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!::!!!!!!::..  ..::::..........::::::::::....  ::::!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::++!!!!!!!!::::::::!!::..  ........        ..............::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::    ..........        ....  ....::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::..    ......................::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++::!!!!!!!!!!!!!!!!!!::::..  ........::..::::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::......::..::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!::......::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++::..::!!!!!!!!!!::::..::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!::..::!!!!!!!!::..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!::::::::!!!!..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!::::..::..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!::::    ::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!::..  ::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++++++++!!!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!++++++++++++!!::::::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!++++++++++!!..::!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!",
"++++++++++++++++++++++++++++++++!!++!!++++++++++++++**++!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!++++++++ee$$$$******::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!++**$$$$$$@@@@@@$$!!..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!++**$$$$$$@@@@@@@@@@!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!**$$$$$$$$@@@@@@@@ee..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!**$$$$$$$$@@@@@@$$!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$$$$$@@@@@@ee..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++++**++++++++++++++++++!!!!!!!!!!**$$$$$$@@@@$$::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++**++++++++++++++++++++++!!!!!!!!!!**$$$$@@@@@@**::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$ee++!!!!!!!!!!!!++",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++!!**$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++!!++**@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++::::::!!!!!!!!::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::::::::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@@@@@@@@@@@@@@@$$**!!!!::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**$$$$@@@@@@@@@@@@@@@@$$ee++!!::::::::::::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@ee++!!::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++**ee$$@@@@@@@@@@@@@@**::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++ee$$$$@@@@@@@@@@@@ee::::::::::::::::::::::::::::!!::",
"++**++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::!!::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++++ee$$$$@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::!!::::",
"++****++**++++++++++++++++++++++++!!!!!!!!++!!++**$$$$@@@@@@@@@@@@@@@@$$++!!::::::::::::::::::::::!!!!::::",
"++++**++**++++++++++++++++++++++++!!!!!!!!!!++++$$$$@@@@@@@@@@@@@@@@@@@@ee**!!::::::::::::::::::::::!!::::",
"++**++++++++++++++++++++++++++++++!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@@@@@$$$$ee**::::::::!!::::!!::!!!!::::",
"++****++++++++++++++++++++++++++++!!!!!!!!!!ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++::::::!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!++$$@@@@@@@@@@@@@@@@@@@@@@@@$$@@@@@@@@$$!!::::!!!!!!!!!!!!::!!::",
"++++++++++++++++++++++++++++++++!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee$$$$**::::!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$@@@@@@@@@@$$@@@@@@@@@@@@@@@@@@$$****++::::!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++!!!!!!!!$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!**!!::!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++**++::::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++!!!!++$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**++ee**!!::!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++++!!!!!!ee$$$$$$@@$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!**$$**!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@$$$$$$$$$$$$$$$$ee::++ee**!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!**$$$$$$$$@@@@@@@@ee**++++!!::::::!!!!!!!!::!!++!!::::::!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++!!!!!!::++**eeeeee$$$$@@**::::::::::::::::::::::::::::::::..::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!::!!!!++****eeee$$::....::::::::::::::::!!!!!!!!!!::::..::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!::::!!!!!!++**ee**!!......::::::::::::::!!!!!!!!!!!!!!::....::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!::!!!!!!!!****!!::..    ..::::::::::::::!!!!!!::!!::::..  ::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!::::      ............::::::::::::::......::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!::::::..      ....  ......................::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++::!!!!!!!!!!!!::!!::!!::..                      ........::!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!::::::            ..  ............::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::..  ................::..::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!++++++!!!!!!!!!!::::......::::::::::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!++++++!!!!!!!!!!::::....::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!::....::::::::::!!::!!!!!!!!::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++::..::!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!::..::!!!!!!!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!::::::!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::::::!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++!!!!::::......::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!..  ::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++++++++++!!!!::  ::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!",
"++++++++++++++++++++++++++++++!!++++++++++++++!!!!::..::!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!++++++++++++!!::..!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!++++++++++!!!!!!**++++ee!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!++!!++++++++++ee@@$$$$$$++::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!++++++**ee$$@@@@@@@@@@++::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!++**$$$$$$$$@@@@@@@@$$::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!**$$$$$$$$$$@@@@@@@@++::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++++**++++++++++++++!!!!!!!!++ee$$$$$$$$@@@@@@@@ee....::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++!!!!!!++**$$$$@@$$@@@@@@@@!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$**++!!!!!!!!!!!!++++",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@$$**++!!!!!!!!!!!!!!++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@$$**++!!!!!!!!!!!!!!++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++$$@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::::::!!::::::!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++**@@@@@@@@@@@@@@@@@@@@@@$$**!!!!!!::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@$$ee!!!!!!::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@$$ee**!!::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++**ee$$@@@@@@@@@@@@@@ee++!!::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++ee$$$$@@@@@@@@@@@@ee::::::::::::::::::::::::::::!!::",
"++**++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@$$::::::::::::::::::::::::::::!!::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++++ee$$$$@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::!!::::",
"++****++**++++++++++++++++++++++++!!!!!!!!!!!!++**$$$$@@@@@@@@@@@@@@@@**::::::::::::::::::::::::::!!!!::!!",
"++++**++**++++++++++++++++++++++++!!!!!!!!!!++++$$$$@@@@@@@@@@@@@@@@@@++::::::::::::::::::::::::!!::!!::::",
"++**++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@**++!!::::::::::::::::::!!::!!!!::::",
"++****++++++++++++++++++++++++++++!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@@@$$$$**!!::::::::::!!!!!!!!!!::!!!!::",
"++++++++++**++++++++++++++++++++!!!!!!!!!!++$$$$@@@@@@@@@@@@@@@@@@@@@@$$@@@@$$!!::::::!!!!!!!!!!!!!!::!!::",
"++++++++++++++++++++++++++++++++!!++!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee::::::!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!++$$$$@@@@@@@@@@$$@@@@@@@@@@@@@@@@$$$$ee!!::::!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++++::::!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++++::::::!!!!!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++!!++ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@****++::::!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++++++++!!**$$$$$$@@$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**ee**::::!!!!!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++eeee!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$!!****!!::!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++!!!!!!!!**ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@++::::::::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!::!!**eeee$$@@@@@@@@@@@@$$$$$$$$$$$$@@@@@@@@ee....::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!::!!!!++eeee$$@@@@$$ee++!!!!::::::!!!!++**ee++......::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!**eeeeee$$**::........::::::::::::::!!::::......::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!++**eeeeee....    ....::::::::::::::::::::..  ..::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!******!!        ....::::::::::::::::::....  ::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++::!!!!!!!!!!!!++**++..          ......::::::::::::..  ..::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..              ..............  ::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::!!!!::..                  ......  ..::::!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++!!!!++++!!!!!!!!!!!!::::                  ........::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!++++++!!!!!!!!!!::::..        ............::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!++!!!!!!!!!!!!!!::::........::::::::..::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!::......::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!::::::!!!!!!!!!!::....::::::::::::::::!!!!!!::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::::::!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!::....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!::....::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!",
"++++++++++++++++++++++++++++++!!++++++++++++++!!!!::..::::!!!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!++++++++++++!!::..::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!++++++++++++!!::..::!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!++++++++++++!!::!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!++++++++++!!!!!!**++++**!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!++++++******ee@@$$$$$$!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$$$$$$$@@@@@@@@$$::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++++**++++++++++++++!!!!!!!!++ee$$$$$$@@@@@@@@@@@@$$::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++!!!!!!++**$$$$@@$$@@@@@@@@@@++::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++ee$$@@@@@@@@@@@@@@@@@@@@@@ee++!!!!::::::::!!++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!++++++",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++ee@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@$$++!!::!!!!!!!!!!++++",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@$$@@@@@@@@@@@@@@@@@@**++::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@$$++!!!!::!!::!!::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@$$**!!!!!!::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@$$ee!!::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++ee$$@@@@@@@@@@@@@@@@ee++!!::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++ee$$$$@@@@@@@@@@@@ee++!!::::::::::::::::::::::::!!::",
"++****++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@$$!!::::::::::::::::::::::::::!!::::",
"++++**++++++++++++++++++++++++++!!!!!!!!!!!!!!++++ee$$$$@@@@@@@@@@@@@@++....::::::::::::::::::::::::!!::::",
"++****++**++++++++++++++++++++++++++!!!!!!!!!!++**$$$$@@@@@@@@@@@@@@$$::::::::::::::::::::::::!!::!!!!::!!",
"++++**++++++++++++++++++++++++++++!!!!!!!!!!++++$$$$@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::!!::!!!!!!",
"++**++++++++**++++++++++++++++++++!!!!!!!!!!!!ee$$@@@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::!!!!::!!",
"++****++++++++++++++++++++++++++++!!++!!++!!**$$@@@@@@@@@@@@@@@@@@@@$$++::::::::::::::!!!!!!!!!!!!::!!!!!!",
"++++++++++**++++++++++++++++++++++!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@@@@@$$**::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$**::::::::::::!!!!!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++!!!!!!++$$$$@@@@@@@@@@$$@@@@@@@@@@@@@@@@ee++::::::::!!!!!!!!!!!!::!!::::",
"++++++++++++++**++++++++++++++++!!++!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$ee**!!::::!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++++++!!!!++$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::::::!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++++++!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!!!::::::!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++++++++!!!!ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++**::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!++ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee****!!::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**++**++::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!**ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$!!++!!::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$!!..::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@**  ....::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++ee$$@@$$ee******eeee$$$$@@@@$$......::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!**ee**::::::::::::!!++**ee++::::..::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++!!!!!!++!!!!!!!!!!!!!!::......::::::::::::::........::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::............::::::::....    ::::!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++++!!!!!!!!++!!!!!!::::::::..  ......::::::::....    ..!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!::++++++++!!!!!!::::::..    ................    ::!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++!!!!!!++++++!!!!!!!!::::..        ..........  ..::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!++++++++!!!!!!!!!!::..            ..........::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!::!!!!!!!!!!!!!!!!::..              ....::::!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!::..................::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!......::::::::::..::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!::..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!::....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!::..::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::!!::!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!++++++++++++!!::!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!++++++++++++!!::!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$ee++++++!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$$$@@@@@@ee++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++**++++++++++++++!!!!!!!!!!!!++$$$$$$$$@@@@@@$$++::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++!!!!!!!!!!!!++@@$$@@@@@@@@@@ee::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++++ee$$@@@@@@@@@@@@@@@@@@@@@@ee++!!!!::::::::!!++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!++++++",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++++ee@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@$$++!!::!!!!!!!!!!++++",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@$$@@@@@@@@@@@@@@@@@@**++::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@$$++!!!!::!!::!!::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@$$**!!!!!!::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@$$ee!!::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++ee$$@@@@@@@@@@@@@@@@ee++!!::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++ee$$$$@@@@@@@@@@@@ee++!!::::::::::::::::::::::::!!::",
"++****++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@$$!!::::::::::::::::::::::::::!!::::",
"++++**++++++++++++++++++++++++++!!!!!!!!!!!!!!++++ee$$$$@@@@@@@@@@@@@@++....::::::::::::::::::::::::!!::::",
"++****++**++++++++++++++++++++++++++!!!!!!!!!!++**$$$$@@@@@@@@@@@@@@$$::::::::::::::::::::::::!!::!!!!::!!",
"++++**++++++++++++++++++++++++++++!!!!!!!!!!++++$$$$@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::!!::!!!!!!",
"++**++++++++++++++++++++++++++++++!!!!!!!!!!!!ee$$@@@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::::!!!!::!!",
"++****++++++++++++++++++++++++++++!!++!!++!!**$$@@@@@@@@@@@@@@@@@@@@$$++::::::::::::::!!!!!!!!!!!!::!!::!!",
"++++++++++**++++++++++++++++++++++!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@@@@@$$**::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$**::::::::::::!!!!!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++!!!!!!++$$$$@@@@@@@@@@$$@@@@@@@@@@@@@@@@ee++::::::::!!!!!!!!!!!!::!!::::",
"++++++++++++++**++++++++++++++++!!++!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$ee**!!::::!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++++++!!!!++$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::::::!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++++++!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!!!::::::!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++++++++!!!!ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++**::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!++ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee****!!::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**++**++::!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++!!!!!!!!!!!!!!**ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$!!++!!::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$!!..::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@**  ....::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++ee$$@@$$ee******eeee$$$$@@@@$$......::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!**ee**::::::::::::!!++**ee++::::..::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++!!!!!!++!!!!!!!!!!!!!!::......::::::::::::::........::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::............::::::::....    ::::!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++++!!!!!!!!++!!!!!!::::::::..  ......::::::::....    ..!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!::++++++++!!!!!!::::::..    ................    ::!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++!!!!!!++++++!!!!!!!!::::..        ..........  ..::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!++++++++!!!!!!!!!!::..            ..........::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!::!!!!!!!!!!!!!!!!::..              ....::::!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!::..................::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!......::::::::::..::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!::..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!::....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!::..::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::!!::!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!++++++++++++!!::!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!++++++++++!!::!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$ee++++++!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$$$@@@@@@ee++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++**++++++++++++++!!!!!!!!!!!!++$$$$$$$$@@@@@@$$++::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++!!!!!!!!!!!!++@@$$@@@@@@@@@@ee::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@$$**!!::::....  ..::++++++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!++!!!!!!!!!!++++ee$$@@@@@@@@@@@@$$**!!::::::::::::!!++++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++!!++**$$$$@@@@@@@@@@@@@@$$++!!!!!!!!!!!!++++++++++",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++**ee@@@@@@@@@@@@@@@@@@$$**!!!!!!!!!!!!++++++++++",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@$$++!!!!!!!!!!!!!!++++++++",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@$$!!::!!!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@@@**::::::!!::::::!!!!!!!!::",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++ee$$$$$$@@@@@@@@@@@@@@@@@@++::::::::::::::!!::::::!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@@@ee::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++$$$$$$$$@@@@@@@@@@@@@@$$**!!::::::::::::::::::::::!!::",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!ee$$$$@@@@@@@@@@@@@@$$ee!!::::::::::::::::::::::::::::!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!!!++$$$$$$@@@@@@@@@@@@$$!!....::::::::::::::::::::::!!!!!!!!",
"++++++****++**++++++++++++++++++++++++++!!++!!!!ee$$$$@@@@@@@@@@@@@@++....::::::::::::::::::::::::::::!!!!",
"++++**++++++++++++++++++++++++++++++++!!!!!!!!**$$$$$$@@@@@@@@@@@@$$::..::::::::::::::::::::::::::::!!!!!!",
"++**++++++++++++++++++++++++++++++++++!!!!!!!!ee$$$$$$@@@@@@@@@@@@ee......::::::::::::::::::::!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!++$$$$$$@@@@@@$$@@@@@@ee..::::::::::::::::::::::::::!!!!!!!!!!",
"++++++++++**++++++++++++++++++++++!!++!!!!!!ee@@$$@@@@@@@@@@@@@@@@ee......::::::::!!!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!$$$$@@@@@@@@@@@@@@@@@@$$::::::::::::::::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@++::::::::::::!!!!!!!!!!!!!!!!::!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!::!!!!**$$$$@@@@@@@@@@@@@@ee++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@**++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++!!!!!!!!!!!!!!!!!!++**ee$$@@@@@@@@@@@@ee++::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++!!!!::!!!!!!!!!!!!++ee$$@@@@@@@@@@@@**!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!::::::!!!!!!::!!!!**$$@@@@@@@@@@$$!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!::!!**@@@@@@@@@@ee!!!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++!!!!++++!!!!!!!!!!!!!!::::::!!ee@@@@@@@@ee::!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!++++!!::!!++++++!!!!!!::::!!$$@@@@@@$$++::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!::!!!!++!!!!!!!!::::::**@@@@@@@@ee....::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!++++++!!!!!!!!!!::!!ee$$$$@@@@::..::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!++++++++++++!!!!++++!!!!++!!!!::::::!!!!****::..::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!++****++!!!!!!!!::::........  ....::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!++****++++!!!!!!::....          ::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++!!!!!!++++++++++!!!!::..            ..!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..            ::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!::..            ::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++**++++!!!!!!::::!!!!!!!!!!::....          ::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::....          ..::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!::!!!!!!!!::..              ::::!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::..            ..::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::............  ....::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!::..  ....::::::..::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!++++++++++++++!!::  ....::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!++++++++!!....::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++!!!!!!++++++::....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!::++++++::..::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++!!!!!!!!::::++::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::ee::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++**++++++++++++++++++++++++!!!!!!!!!!!!::..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@$$**!!::....    ..!!++++++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!++!!!!!!!!!!++**$$$$@@@@@@@@@@@@$$**!!::::::....!!!!++++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++!!**$$$$$$@@@@@@@@@@@@@@ee!!!!!!!!::!!!!++++++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@$$++!!!!!!!!!!!!++++++++++",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!++!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@ee!!!!!!!!!!!!!!!!++++++++",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++$$$$$$@@@@@@@@@@@@@@@@@@**::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!ee$$$$$$@@@@@@@@@@@@@@@@@@++::::::!!!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++$$$$$$$$@@@@@@@@@@@@@@@@$$!!::::::::::!!::!!::::::!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!ee$$$$$$@@@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++$$$$$$$$@@@@@@@@@@@@@@$$++::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!ee$$$$$$@@@@@@@@@@@@$$**!!::::::::::::::::::::::::::::!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!!!++$$$$$$@@@@@@@@@@@@$$::..::::::::::::::::::::::::!!!!!!!!",
"++++++++**++**++++++++++++++++++++++++++!!++!!!!ee$$$$@@@@@@@@@@@@@@!!....::::::::::::::::::::::!!!!::!!!!",
"++++**++++++++++++++++++++++++++++++++!!!!!!!!!!$$$$@@@@@@@@@@@@@@$$::....::::::::::::::::::::::!!::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!++$$$$@@@@@@@@@@@@@@ee......::::::::::::::::::!!!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!++**$$$$@@$$@@@@@@**..::::::::::::::::::::::::::!!!!!!!!!!",
"++++++++++**++++++++++++++++++++++++++!!!!!!!!!!!!!!++**$$@@@@@@@@ee......::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::!!!!!!!!++**ee$$@@@@$$::::::::::::::!!!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++++**$$@@++::::::::::::!!!!!!!!!!!!!!!!::!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!::**$$ee::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!::!!::!!!!!!!!!!!!::::++$$ee!!::::::!!!!!!!!!!!!::!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++!!!!::!!!!!!!!!!!!::::::::::**@@ee!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++++++!!!!++!!!!!!!!::::::::::!!ee@@**::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!++++!!::!!!!!!!!!!!!::::::::::++$$**::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!::::!!++++++++!!!!::::::::eeee::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++++++++++++++!!::!!!!!!!!!!!!!!!!::::..++$$::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!++++++!!!!!!!!!!!!::::!!$$!!..::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!++++++!!!!!!!!!!::::::!!    ::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!++++++++++!!!!!!::..      ..::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!::!!!!++++**++++!!!!::..      ::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++++++!!!!::..      ..!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::!!!!++**!!!!!!::        ::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++++++++!!!!::::!!!!++!!!!!!::        ..!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++!!!!::        ..!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++!!!!::::        ..!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::..        ..!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!..::........        ::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++**++++!!!!::                  ..::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!::..      ..        ..::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++!!::    ..............::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++!!......::::::::..::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!::::++++::....::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!..::!!....::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++!!!!!!::  ....::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::  ..::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::  ..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++**++++++++++++++++++++++++++++++!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++++**++++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++++**++++++++++++!!!!!!!!::..::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++ee$$$$@@@@@@@@@@@@$$**!!!!::::..  ..!!++++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!++!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@$$++!!::::::::::!!++++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++ee$$$$@@@@@@@@@@@@@@@@@@**!!!!!!!!!!!!!!++++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!ee$$$$$$@@@@@@@@@@@@@@@@@@**!!!!!!!!!!!!!!++++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++$$@@$$@@@@@@@@@@@@@@@@@@$$!!::!!!!!!!!!!++++++++!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@@@$$::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++$$$$$$@@@@@@@@@@@@@@@@@@@@**::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@@@$$!!::::::::::!!::::::::::!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++$$$$$$$$@@@@@@@@@@@@@@@@ee!!..::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!ee$$$$$$$$@@@@@@@@@@$$ee++::::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!++$$$$@@@@@@@@@@@@@@@@++..::::::::::::::::::::::::::::::!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!!!**$$$$$$@@@@@@@@@@@@**......::::::::::::::::::::::!!!!!!!!",
"++++++++**++**++++++++++++++++++++++++++!!!!!!!!!!**ee$$@@@@@@@@@@@@::....::::::::::::::::::::::!!!!::!!!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++**ee$$@@@@@@$$......::::::::::::::::::::!!!!::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++**$$$$@@$$......::::::::::::::!!::!!!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::!!!!!!!!!!!!++++**eeee......::..::::::::::!!!!::!!!!!!!!!!!!",
"++++++++++**++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!++!!::!!    ..::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!::::::    ..::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!::::::::..  ......::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::!!++::!!::!!::::::::::..    ..::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!::!!!!!!::!!!!::::::::::..    ..::::!!!!!!!!!!!!!!::!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++!!!!::!!!!!!!!++++!!!!::::::::    ..::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++++++++!!::!!++!!!!!!!!!!!!::::::::    ..::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!++++++++++!!!!++++++!!!!!!!!::::::::..    ::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!++++++!!!!!!!!!!!!::::..    ::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!**++!!!!!!!!!!!!!!::..    ::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!++++!!++**++++!!!!::::..  ..::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++****++!!!!::..    ::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::!!!!!!ee++!!!!!!::    ..!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++**++++!!!!!!::!!!!++++++!!!!::      ::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++**++!!!!::..    ..!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!**!!++!!::..      ::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++!!++!!::..      ::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++!!!!::..  ....  ::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!::..        ..::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!++++++++!!!!!!::........          ..::!!!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!++++++++++!!::      ..          ..::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++!!..      ..          ::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!::::++!!::..  ....::::....::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!::..!!!!......::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!........::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::  ....::::::::::::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::  ..::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++!!!!!!::....::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++**++++++**++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++++**++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++++**++++++++++++++++++++!!::..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@@@$$++!!!!!!::::::!!++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@**!!!!!!!!!!!!++++++++",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@@@@@@@**!!!!!!!!!!!!++++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@++::!!!!!!!!!!++++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!**$$$$@@$$@@@@@@@@@@@@@@@@@@@@$$::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@**..::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!**$$$$$$$$@@@@@@@@@@@@@@@@@@@@$$!!::::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!$$$$$$$$@@@@@@@@@@@@@@@@@@$$ee!!::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++$$$$$$@@@@@@@@@@@@@@@@**!!::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@++....::::::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!++**ee$$@@@@@@@@@@@@ee......::::::::::::::::::::::::::::!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++**ee$$@@@@@@@@!!......::::::::::::::::::::::!!!!!!!!",
"++++++++**++**++++++++++++++++++++++++++!!!!!!!!!!!!!!++++**ee$$@@$$........::::::::::::::::::::!!!!::!!!!",
"++++**++++++++++++++++++++++++++++++++!!!!!!!!!!++++!!!!!!++******ee........::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!++**!!!!    ....::::::::::::!!::!!!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!::..    ::::::::::::!!!!!!::!!!!!!!!!!!!",
"++++++++++**++++++++++++++++++++++++++!!!!::::::::::!!!!::::::!!::::::    ..::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::::..    ..::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!::!!!!!!!!++!!!!::::::::::..  ..::::::!!!!!!!!!!!!!!::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!::!!!!!!++++++!!!!::::::::::    ::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++!!::::::::::    ::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++!!::!!!!!!!!!!!!++++!!!!::::::..  ::::::!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++++++++++!!::++!!!!!!++!!++!!!!!!::::..    ::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!++!!++**++++++!!!!!!::::    ::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!::!!!!!!!!!!**++++++!!::::    ::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!++++++!!!!::..  ..::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::!!!!++**!!++!!::..    ::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++!!++!!::..    ::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++!!++!!!!..      ::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++!!!!::..      ..!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++!!!!::::::..    !!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!....::::::..  !!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::....::::::::..::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!++++++++++!!!!!!....    ......::::..::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++!!!!!!++++++!!!!::        ............::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++++::..            ........::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::..!!!!......................::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::  ..!!......::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!..  !!::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!..  ..::::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::  ..::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++++++!!++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++**++**++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!",
"++++++++++++++**++++++++++++++++++++++++**++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!",
"++******++++++**++++++++++++++**++**++++++++++++++++++++++!!..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++++++++++++++++++++++++++!!!!....::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@**!!!!::!!::::!!++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!++++",
"++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@**!!!!!!!!!!!!!!++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++ee$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@$$!!::!!!!!!!!!!++++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@**..::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@$$!!..::::!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!++!!!!!!!!++$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@$$++::::::::::::::!!::::!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!::**$$$$@@@@@@@@@@@@@@@@@@@@$$ee**!!::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@**::..::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!::++**ee$$@@@@@@@@@@@@@@ee::......::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++**ee$$@@@@@@@@@@!!......::::::::::::::::::::::::::!!",
"++++**++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!**ee$$$$@@@@$$......::..::::::::::::::::::!!!!!!!!",
"++++++++**++**++++++++++++++++++++++++++!!::!!++!!!!!!!!!!**ee$$$$@@ee  ......::::::::::::::::::::!!::!!!!",
"++++**++++++++++++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!++$$ee****    ..::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!++++!!::    ..::::::::::::!!::!!!!::!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::!!!!::::..    ..::::::!!!!::!!::::!!!!!!!!!!",
"++++++++++**++++++++++++++++++++++++++!!!!!!::!!!!!!!!++!!!!::!!::::::::..  ..::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!++++!!!!!!::::::::::..  ..::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!::!!!!!!!!++!!++++!!!!::::::::    ::::::!!!!!!!!!!!!::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!++++!!!!::::::::  ..::::!!!!!!!!!!!!!!::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++::!!++++++++++++++!!!!!!!!::::    ::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++!!::!!!!++++++++++++++!!!!!!::..  ..::::!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++++++++++++!!::::::!!!!!!++!!!!!!!!!!!!::    ::::!!!!!!!!!!!!::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!::    ::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!++!!!!!!::    ::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::!!!!!!!!!!::    ::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!::::      ::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..::::....        ..!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::                    ::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::    ..............  ..!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::::::::::::::::::..!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::!!::::::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++++++!!!!++++++!!::::!!::::!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++++::::::::::::::::::::::..::::!!!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++++++++++!!!!!!!!::::!!..        ..............::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::  !!**............::......::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::  ..::....::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::........::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::....::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!++!!!!::....::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!++!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++****++++++++++++++++++++++++++++++++++++++++++!!++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++**++**++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++****++++++++++++++++++++++++**++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!",
"++++++++++++++++++++++**++++++++++++++++**++++++++++++++++!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++**++++++**++++++++++**++**++**++++++++++++++++++++++!!::..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++++**++++++++++++++++++++!!!!....::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++**$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!!!!!::!!!!!!++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++ee$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@**!!!!!!!!!!!!++++",
"++++++++++++++++++++++++++++++++++++!!++!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!!!!!!!!!!!++++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@++::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!++$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@**::::::!!::::!!!!::::",
"++++++++++++++++++++++++++++++++++++!!++!!!!!!!!ee@@$$$$@@@@@@@@@@@@@@@@@@@@@@$$ee++::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::++**ee$$@@@@@@@@@@@@@@@@@@@@**!!::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++!!!!::::!!++++**ee$$@@@@@@@@@@@@@@**::..::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++**eeee$$@@@@@@@@$$::......::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!++!!!!!!++**eeee$$@@@@@@**........::::::::::::::::::::::::!!",
"++++**++++++++++++++++++++++++++++++++!!::::!!++++++!!!!!!++eeee$$@@@@!!....::..::::::::::::::::::!!!!!!!!",
"++++++++**++**++++++++++++++++++++++++!!::::!!++++!!!!!!!!!!!!**eeee$$::  ....::::::::::::::::::::!!::!!!!",
"++++++++++++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!++++++..  ..::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!++!!!!!!!!!!!!!!!!!!!!!!!!::::..  ..::::::::::!!::!!!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++!!!!!!::::::::    ::::::::!!::!!!!!!!!!!!!!!!!",
"++++++++++**++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!++++++!!!!!!::::::::....::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!++++++!!!!::::::::  ..::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++**++++++++++++++++++++++++++++++++!!::!!++++++!!++++++!!!!!!!!::::..  ::::::!!!!!!!!!!::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!::!!++++++!!!!++!!!!!!!!!!::::  ..::::!!!!!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!::    ::::!!!!!!!!!!!!!!::!!",
"++++++++++++++++**++++++++++++++++++++++++++++++++!!!!::::::::::!!!!!!!!!!!!!!::    ..::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!::    ..::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::!!!!!!::....    ..::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::......            ::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::      ......      ::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::::::::::::....::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!::::..::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::!!::!!!!!!!!!!!!!!::..::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++!!::::::::::::::::!!!!!!!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::....    ........::!!::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!::              ........::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::....$$ee..      ..........  ..::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::  **!!........::::::....::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::..........::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::....::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++**++++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++****++++++**++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****++++++**++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**********++++++++++++++++**++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!",
"++++****++****++++++++++++++++++++++++++++++++++++++++++++!!!!::..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++++!!",
"++++**++++++++++++++++**++++++++++++++++**++++++++++++++++++!!....::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++++++**++++++++++**++**++**++++**++++++++++++++++!!!!..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"ee**********++++++++++++++++++++++**++++++++++++++++++++++++::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!**ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!!!!!!!!!!!++",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++**$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!!!!!!!!!!!++",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++ee$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!!!!!!!!!!!++++",
"++++++++++++++++**++++++++++++++++++++!!!!!!!!!!**$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!ee$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!++ee@@$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::::!!!!::!!",
"++++++++++++++++++++++++++++++++++++!!++!!::::++**ee$$$$@@@@@@@@@@@@@@@@@@@@$$ee++!!::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++++!!::::!!++****ee$$@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++!!!!::!!!!!!!!++**ee$$@@@@@@@@@@@@**......::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!++!!!!!!++**eeee$$@@@@@@$$::......::::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!::!!++++++!!!!!!!!++eeee$$@@@@ee........::::::::::::::::::::::::!!",
"++++**++++++++++++++++++++++++++++++++!!::::!!++!!!!!!!!!!!!!!**ee$$$$**  ..::..::::::::::::::::::!!!!!!!!",
"++++++++**++**++++++++++++++++++++++++!!::!!!!!!::!!!!!!!!!!!!!!++++++!!  ....::::::::::::::::::::!!::!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!::!!!!!!++!!!!!!!!!!!!!!!!::..  ..::::::::::::::::::!!!!!!::!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!++++!!!!::!!::::::....::::::::::::::::!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!!!++++++!!!!::::::::....::::::::!!!!!!!!!!!!!!::!!",
"++++++++++**++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!++++++++!!!!!!::::::....::::::!!!!!!!!!!!!::::!!",
"++++++++++++++++++++++++++++++++++++++++!!++++!!::!!++++++++++++++!!!!!!!!!!::..  ::::::!!!!!!!!!!!!::::!!",
"++++++++++++**++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!++!!!!!!!!!!::::  ..::::!!!!!!!!!!!!!!::!!",
"++++**++++++++++++++++++++++++++++++++++++++++++++!!::::::::::::!!!!!!!!!!!!!!::    ::::::!!!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::!!::::::!!!!!!!!!!!!::    ..::::!!!!!!!!::!!::!!",
"++++++++++++++++**++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!::::      ::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::!!::::....      ::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::............      ..::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!....::::::::::..  ..::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::::::::::::..::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::::!!!!!!!!!!::..::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!::::::::::!!!!!!!!!!::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!++++++++::..........::::!!!!!!!!!!::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!..        ......::!!!!!!!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!              ..::::!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!..++$$++                ......::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::..**ee!!..................  ..::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::..::..........::::::::......::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::..........::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!......::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!....::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!",
"******++++++++++++++++++++++++++++++++**++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++**++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++****++++++**++++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++**++++++++++++++++++++++++**++++++++++++++++++++++!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++****++****++++++++++++++++**++++++++++++++++++++++++++++!!::..::::::!!!!!!!!!!!!!!!!!!!!!!++!!!!++++!!",
"++************++++++++++++++++++++++++++++++++++++++++++++!!!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!++++!!",
"**********++++++++++++**++++++++++++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"eeee************++++++++++**++**++++++++****++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"@@@@$$eeee**************++++++++++**++++****++++++******eeee**!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!**ee$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**++!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$ee++!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**!!!!!!!!!!++++",
"++++++++++++++++**++++++++++++++++++!!!!!!!!!!**ee@@$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@++!!!!!!!!!!!!!!",
"++++++++++++++**++++++++++++++++++++++!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!::!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::::::::!!::::",
"++++++++++++++++++++++++++++++++++++!!!!!!::!!**eeee$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$$$ee!!!!!!!!::::::::::!!",
"++++++++++++++++++++++++++++++++++++++++!!::!!!!++**eeee$$@@@@@@@@@@@@@@@@@@**::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++**ee$$$$@@@@@@@@@@@@**......::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!++**ee$$$$@@@@@@$$::......::::::::::::::::::!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!::!!!!!!!!!!!!!!!!!!!!++**$$@@@@**  ....::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!::!!++ee$$!!  ..::::::::::::::::::::!!!!!!!!",
"++++++++**++**++++++++++++++++++++++++!!::::!!!!++++++!!!!!!!!::::!!!!!!::  ..::::::::::::::::::::!!::!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++!!!!!!!!!!!!!!!!::::  ..::::::::::::::::!!::!!!!::!!",
"++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!++++!!++!!!!::::::::..::::::::::::::::!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!++++!!!!++++!!!!!!::::::::::::::::::::!!!!!!!!!!!!::::",
"++++++++++**++++++++++++++++++++++++++!!!!!!!!::::::::::!!!!!!++!!!!!!!!!!!!::..::::::::::!!!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!++++!!::::::::::::!!!!!!!!!!!!!!::::  ::::..::!!!!!!!!!!::::!!",
"++++++++++++**++++++++++++++++++++++++++!!++++++!!!!::::!!::::::!!!!!!!!!!!!!!::    ::::::!!!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!::::!!::::::::!!!!!!!!!!::    ..::::!!!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::!!!!!!!!::      ::::::!!!!!!!!!!!!::",
"++++++++++++++++**++++++++++++++++++++++++!!++++++++++++!!!!!!!!::::....::::::        ..::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!++++++++++++!!!!::!!    ..::::::..      ::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!++++++++++++++!!!!::....::::::::::::    ::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::......::::::::::::..::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!::  ........::::::::....::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++!!::..    ......::!!!!::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++!!!!!!::::        ..::!!!!!!::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!eeee          ..::!!!!::..::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::$$$$$$::            ::::::....::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!$$@@$$!!  ..          ........::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!$$++................      ..::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::..........::..::........::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::............::::::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::......::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::....::::::::::!!!!::::::!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!++!!!!!!!!::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++**++++++++++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++**++++++++++++++**++++**++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++**++++++++++++++++++++**++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++********++**++++++++++++++++++++++++++++++++++++++++++++++!!..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++**++++++++++++++++++++++++**++++++++++++++++++++++!!!!..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++****++****++++++++++++++++**++++++++++++++++++++++++++++!!::::::::::!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!",
"**************++++++++++++++++++++++++++++++++++++++++++++++!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!",
"$$eeee**********++++++++++++++++++++++++++++++**++++++++++****!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"@@@@$$$$eeeeeeee****************++******************eeee$$$$$$++::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"@@@@@@@@@@@@$$$$$$$$$$eeeeeeeeeeee$$$$$$$$$$$$$$$$$$$$$$@@$$$$!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!++**$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**++!!!!::....  ::",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$@@$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**++!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!**$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!++**$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!++",
"++++++++++++++**++++++++++++++++++!!++!!!!!!++$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!++$$@@$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++::::::::!!::!!",
"++++++++++++++++++++++++++++++++++++!!!!::!!**ee$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**++!!::::::::::!!",
"++++++++++++++++++++++++++++++++++++!!!!::::!!++++++**ee$$@@@@@@@@@@@@@@@@@@@@@@$$$$ee++!!!!!!::::::::::::",
"++++++++++++++++++++++++++++++++++++!!!!::!!!!!!!!::!!!!++**$$@@@@@@@@@@@@@@ee++!!::::::::::!!::::::::::::",
"++++++++++++++++++++++++++++++++++++!!::::!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@ee::....::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++!!!!::::::!!++!!!!!!!!!!!!!!!!++ee$$@@@@::......::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!::::!!!!++++!!!!!!!!!!!!!!!!!!++$$ee..::....::::::::::::::::::::::!!",
"++++++++**++**++++++++++++++++++++++!!::::!!!!++!!++++++!!!!!!!!!!!!!!!!!!  ..::::::::::::::::::::::::!!!!",
"++++++++++++++++++++++++++++++++++++!!!!::::!!++!!++++!!!!!!!!!!!!!!!!::::....::::::::::::::::::!!!!::::!!",
"++++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!::::..::::::::::::::::!!::!!::::::",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!::::..::::::::!!!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!::::::::",
"++++++++++**++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++++++!!!!!!++++!!::::::::::::::::::::!!!!!!!!::::::::::::::!!!!!!!!!!::::!!",
"++++++++++++**++++++++++++++++++++++!!++!!++++++!!!!::::!!::::......::!!!!!!!!::  ::::::::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++++++++!!++++++++++++!!::::!!::::..  ::::!!!!!!::  ..::::::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++++++++++!!++!!++++++++!!!!!!!!!!!!..  ..::::::..**!!....::::!!!!!!!!!!!!!!",
"++++++++++++++++**++++++++++++++++++++++!!!!!!++++++++++!!!!!!!!::::      ......!!::  ..::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!++++++++++++!!!!!!!!..  ......::....    ::::!!!!!!!!::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!++++++++++++!!!!::..    ....::::::..  ::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!++++++++++++!!::..        ..::::::..::::!!!!!!!!::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++++++!!!!!!..        ..::!!::....::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++!!!!!!**::        ..::!!::..::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++ee$$**          ::!!!!::..::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::!!$$$$$$$$          ..::::::....::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::++$$$$$$$$..          ..::..    ::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::**$$$$**!!....                  ::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::++ee::................      ....::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!::..........::..::..........::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::..........::::::::::::::..::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::......::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::..::::::::::::!!::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!++!!!!!!!!::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++****++++++++**++++++**++++++++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++++++++++++++****++++++++++++**++++++++++++++++++++++!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++**++++++++++++++++++++++++++++++++++++++++++!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++******++++**++++++++++++++++++++++++++++++++++++++++++++++!!::..!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++++++++++++++++++++++++++**++++++++++++++++++++++++!!..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++************++++++++++++++++++++++++++++++++++++++++++++++!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"ee************++++++++++++++++++++++++++++++++++++++++++++++++!!::!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!",
"@@$$$$eeee******++**++**++++++++++++**++++++++++++++****ee$$$$**::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"@@@@@@@@$$$$$$eeeeeeeeee**************eeeeeeeeeeeeee$$$$$$$$$$**::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"@@@@@@@@@@@@@@@@$$@@$$$$$$$$$$$$$$$$$$$$$$$$@@@@@@$$@@$$@@@@$$!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++++!!!!++!!!!!!!!!!++ee$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++::..          !!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!**ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::::::..    ::",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!ee$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!::::!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!++ee@@$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!**$$$$@@$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**::!!::!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!::**$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::::::!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!!!!!ee$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!!!::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!++**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!::::::::::",
"++++++++++++++++++++++++++++++++++++!!::::::::::::!!!!!!++ee$$@@@@@@@@@@@@@@@@@@$$ee++!!!!!!!!!!!!::::::::",
"++++**++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!::::!!++ee$$@@@@@@@@@@@@ee++!!::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!**$$@@$$@@@@**!!::::::::::::::::::::::::!!",
"++++++++**++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!$$$$@@ee::::..::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!++@@$$!!..::::::::::::::::::::::::::!!",
"++++++++++++++++++++++++++++++++++++!!!!::::::::::::::::!!!!!!!!!!!!!!$$$$::..::::::::::::::::::::!!::::::",
"++++++++++++++++++++++++++++++++++!!!!!!!!::..............::::::::!!ee$$$$::::::::::::::::::::!!::::::::::",
"++++++++++**++++++++++++++++++++++!!!!!!!!!!::::::::::....    !!ee$$$$$$$$!!::::::::::::::::!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::**$$$$$$$$$$**::::::::::::::!!!!!!!!!!::::!!",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::++$$$$@@$$$$**..::::::::::::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++++++++!!!!++!!++++!!!!::::!!::::++$$$$++::..  ..::::::::::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++++++++!!!!++!!++++++!!!!!!!!::::::$$$$::::....    ..::::::::!!!!!!!!::::::",
"++++++++++++++++**++++++++++++++++++++++!!!!!!++++++++++!!!!!!!!::++$$::..::......    ..::::!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++++++++!!!!::++$$::............  ..::::!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++++++++!!!!++$$!!........::::..  ::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!++++++!!!!!!++$$$$**........::::::..::::!!!!!!!!::!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!ee$$$$ee......  ..::::....::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++$$$$$$ee..      ..::!!::..::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::ee$$$$$$$$::        ..::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::!!$$$$$$$$$$!!..        ::::::..::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::!!$$$$$$$$@@++..        ........  ::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!$$$$$$ee++::....                ::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!ee$$!!................          ::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++::..........::..::......    ..::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::........::::::::::::::......::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::....::::::::::::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::....::::::::::::::::::::::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!++!!!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++++++++**++++++++++++++++++++++++++++++++++++++++++!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++**++++++++++****++++++++++++**++++++++++++++++++++++!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++**++++++++++++**++++++++++++++++++++++++++++!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++********++**++**++++++++++++++++++++**++++++++++++++++++++!!::..::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++**++**++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++**++++++++++++++++++++++++++++++++++++++++++++!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"eeee**********++++++++++++++++++**++++++++++++++++++++******ee**!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"@@$$$$eeeeeeee**************++++++++****************eeee$$$$$$ee::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"@@@@@@@@$$$$$$$$$$$$$$eeeeeeeeeeeeeeeeee$$$$$$$$$$$$@@$$$$@@$$**::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"@@@@@@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@$$!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++**$$$$@@$$@@@@@@@@@@@@@@@@@@@@@@@@$$**++..          ::++++",
"++++++++++++++++++++++++++++++!!++++!!!!!!!!!!++ee$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++::            ::++",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!**$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::....        !!",
"++++++++++++++++++++++++++++++++!!++!!!!!!!!++$$@@$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!::::..  !!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!**$$$$@@$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!++ee$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!**$$$$@@@@@@@@@@@@$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::!!::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!!!!!!!::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!!!!!::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!ee@@$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!!!!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!++**$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!++****$$@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!!!++!!!!++**$$$$$$@@@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!!!!!++!!!!!!!!++**$$@@@@@@@@@@@@@@$$::::::::::::::::::!!::::::!!::::",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::!!!!++**$$$$@@@@@@@@$$::::::::::::::::!!::!!!!!!::::::",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::!!ee@@@@@@$$$$!!::::::::::::::::!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++++++!!!!!!++!!++!!!!!!!!::::!!!!$$$$@@@@@@ee++::::::::::::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++++++++!!!!++!!!!++++!!!!!!!!!!!!ee$$@@@@@@@@$$**++!!::::::!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++++++++++!!!!!!**$$$$@@@@$$eeee$$ee::::::::!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++!!++++!!!!++$$$$@@@@@@@@$$++**ee++::::::!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++!!!!!!ee$$@@$$$$@@@@@@$$!!!!++::::::!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++$$$$$$$$@@@@@@@@@@$$!!::!!::::!!!!!!!!::!!::",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!**$$$$$$$$@@$$@@@@$$$$!!!!!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!ee$$$$@@@@@@@@@@ee!!::::::....::::!!!!::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!ee$$$$@@@@@@@@$$::......    ..::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!ee@@$$$$$$@@@@$$::....      ....::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++**$$$$@@@@@@@@@@++            ..::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++$$@@@@$$$$**!!::                ::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++**$$$$**::............          ..!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++$$!!............::......      ::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++!!........::::::::::::........::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::..::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::..::::::::::::::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++++++++++++++++++!!++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++++++++**++++++++++++++**++++++++++++++++++++++++++!!!!..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!",
"************++++++++++**++++++++++++++**++++++++++++++++++++++++!!..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++**++++++++++++**++++++**++++++++++++++++++++!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++******++++++++**++++++++++++++++++++++++++++++++++++++++++!!!!::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++++++++++++++++++++++++++**++**++++++++++++++++++******!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++++++++++++++++++++++++++++++++++++****eeee$$$$ee!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"eeeeee**********++++++++++++++++++++**************eeee$$$$$$@@$$++::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"@@@@$$$$$$$$eeeeeeeeeeeeeeeeeeeeeeeeee$$$$$$$$$$@@@@@@@@@@$$$$$$!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"@@@@@@@@@@@@@@@@@@$$$$$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"@@@@@@@@@@@@@@@@@@@@@@@@$$$$$$$$@@$$@@@@@@@@@@@@@@@@@@@@@@@@$$!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++**ee@@@@@@@@@@@@@@@@@@@@@@@@$$**++!!::::::!!++**++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++**ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@**++!!        ..!!++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!**ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!            ::++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::..          !!++",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!::::::..    ::++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**!!!!!!!!!!::::!!++",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$ee++!!!!!!!!!!!!++",
"++++++++++++++++++++++++++++++++!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!**$$@@@@@@@@@@@@$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@**::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!++ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++::!!::!!!!::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::!!::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!ee$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!++$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!!!::::::::!!",
"++++++++++++++++++++++++++++++++!!!!!!ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**!!::!!::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!++@@$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!**$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$!!::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!ee$$@@$$$$$$$$@@@@@@@@@@@@@@@@@@@@**::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++++!!!!++eeee**++++++**$$$$@@@@$$@@@@@@@@!!::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!++**$$$$@@@@@@@@@@!!::::::::::::::::::::!!::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::!!!!**$$@@@@@@@@!!::::::::::::::::!!!!::!!::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::!!ee@@$$@@@@ee!!::::::::::::::!!!!!!!!::::::::",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::++@@@@@@@@@@$$**!!!!::::::::!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++!!++!!!!!!!!!!$$$$@@@@@@$$$$$$ee!!::::::::!!!!!!!!::::::",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@$$!!**eeee::::::::!!!!!!!!::::::",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++$$$$$$@@@@@@@@$$++++++++::::::!!!!!!!!::::::",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@$$@@@@$$++!!!!!!::::!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!ee$$$$$$@@@@@@@@@@@@**!!++++::!!!!!!!!::!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!++!!!!!!!!::!!$$$$$$$$@@@@@@@@@@$$++++ee++::!!!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++$$@@@@$$@@@@@@@@@@$$!!++ee**::::!!::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@@@@@::++ee++::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++$$@@$$@@@@@@@@$$@@@@**..**++!!::::::::!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++ee$$$$@@$$ee!!::!!++**::!!++!!::..::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++**$$$$$$++::..........::::::::..  ::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++ee$$!!........::::::::::....  ..::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++**......::::::::::::::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::..::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::!!!!::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********++++++++++++++++++++++++++++++++++++++++++++++++!!++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"******++++++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++++++++++++++++++**++++++++++++++++++++++++++!!!!..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++++++****++++++++++++**++++++++++++++++++++++++!!..::!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++**++++++++++++**++++++**++++++++++++++++++++!!::..::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++**++**++++++++++++++++++++++++++++++++++++++++++!!::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++++++++++++++++++++**++**++++++++++++++++++++++**!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++**++**++++++++++++++++++++++++++++++++++++**ee$$ee!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++++****++++++++++**************++****eeee$$@@@@++::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"eeee****************++**********++++**++**********ee$$$$@@$$@@$$!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"$$$$$$$$eeeeeeeeeeeeeeee********eeeeeeee$$$$$$$$@@@@@@@@@@@@@@**::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"@@@@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@$$!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@$$**++++++++++++++++++++++++++!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@ee**++++++!!!!!!++**++++++++++",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!++!!****$$@@@@@@@@@@@@@@@@@@$$**++!!..      ..!!++++++++++",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!++**ee@@@@@@@@@@@@@@@@@@@@@@ee++!!            ::++++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++**ee$$@@@@@@@@@@@@@@@@@@@@@@$$**++::            !!++++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++ee$$$$@@@@@@@@@@@@@@@@@@@@@@@@ee++!!::::....    ..++++++",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++**$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@**++!!::!!::::..::!!++++",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++**$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$**++!!!!!!!!!!!!++++++",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++!!::!!!!!!!!!!++++",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!!!!!!!!!++ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@**::::::::!!::!!::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!++**$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@$$++::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!++$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!**$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee++!!!!!!!!!!::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!::!!$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ee!!::::::::::!!::::::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!ee@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@++::::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!++@@$$@@$$@@@@@@@@@@@@@@@@@@@@@@@@**..::....::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!$$$$@@@@@@@@@@@@@@@@@@@@@@$$@@@@@@::::......::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@$$::::..::::::::::::::::::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!**$$$$$$******ee$$$$@@@@@@@@@@@@ee..::::::::::::::::::!!::::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!++++++!!!!!!!!**ee$$@@$$@@@@$$ee..::::::::::::::::!!!!!!::::::::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::!!!!!!ee@@@@@@@@$$::::::::::::::::!!!!!!::::!!::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::!!$$$$@@$$@@**!!!!::::::::::!!!!!!!!::::::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::++@@@@@@@@@@$$$$ee++::::::::!!!!!!!!::!!::::::",
"++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!@@$$$$@@@@****ee$$++::::::!!!!!!!!!!::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!++$$@@@@@@@@$$++++++ee!!::::!!!!!!!!!!::::::::",
"++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!ee$$@@$$@@@@@@$$++::::++::::!!!!!!!!!!::::::!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!**$$$$@@@@@@@@@@@@$$!!!!++!!::!!!!!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!$$$$@@@@@@@@@@@@@@$$!!****!!::!!::!!::!!!!!!!!::",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!++$$$$@@$$@@@@@@@@@@++::eeee!!::!!::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!**$$@@@@$$@@@@@@@@@@ee::$$**!!::!!::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!++!!!!++!!!!!!!!**@@@@$$@@@@@@@@$$$$$$::ee**!!::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!**$$@@@@@@@@ee!!::::!!::::!!++!!::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++**$$@@$$$$**::..........::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++ee$$$$!!......::::::::::::::..::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++++ee**....::::::::::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!++++::..::::::::::::::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!::::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::!!!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!",
"++++++**++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++**++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::..::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++++++++++++++++++++++++++!!++!!!!::..::::::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!",
"********++++++++++++++++++++++++++++++++++++++++++++++++++!!!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++++++++******++++++++++**++++++++++++++++!!!!!!::  ..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"************++++++**++++++++++++**++++++++++++++++++++++++!!!!..  ..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++******++++**++**++++++++++++++++++++++++++++++++++++!!!!++..  ::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********++++**++++++++++++++++++++**++**++++++++++++++++!!::....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**************++**++**++++++++++++++++++++**++++++++++++++!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"****************++++**++++++++++++****++++**++++++++++++++!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++++**++++++++**++++++++++++++++++++****!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"********************++**++++++**++**++++++++++++**++**$$$$$$!!::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**********************++**++++++****************ee$$$$@@@@ee::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++!!++++!!!!!!!!::!!::::::::::::!!::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!++++!!!!!!::!!!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!++++!!!!::!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!++++!!!!::!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::::::::::::::::::!!!!!!!!++!!++++++!!!!!!!!!!!!::!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::::::::::::..::::!!!!!!::......::!!!!++++++!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::  ..::!!!!::          ::!!++++++!!!!!!!!!!!!!!::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!::::::::..    ::!!!!::            ::!!++++++++!!!!!!!!!!::!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::..  ..::!!!!::..        ..!!++++++++++!!!!!!::::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::..    !!++!!::::....    ..!!++++++++++!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::....!!****!!::::::::::::!!++++++++++++!!!!::::::::::",
"++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!::::::::::....++$$$$++::!!!!!!!!!!!!++++++++++!!!!::::::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::!!::::::::::++$$$$++::!!::!!!!!!!!++++++++++!!!!::!!::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::!!::::::::++$$$$++::::!!!!!!!!!!!!!!!!!!!!!!::::::::!!::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::!!::::!!::::::::!!eeee::::::::!!!!!!!!!!!!!!!!!!!!::::::::!!!!::",
"++++++++++++++++++++!!++!!!!!!!!!!!!!!::!!::!!!!!!::::::::::++++..::::::::!!::::::!!::::::::::!!!!::!!::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::!!!!!!::::......::....::::::::::::::::::::::!!!!!!!!!!!!::!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::..............::::::::::::::::!!::::::!!::!!::!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::..    ......::::::::::::::::::::::::::!!::!!!!::::!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::..        ..::::::::::::::::::::::::::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++!!++++++!!!!!!!!!!!!!!!!!!::......        ....::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!::..              ....::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!::::....    ::!!        ......::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!::::        !!++        ....::::::!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!::          ::::        ......::::::::::!!::::!!::::!!!!!!!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!::::....    ....        ....::::::::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!::::::::  ....        ....::::::!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!::::::::::::..          ....::::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!::::::!!::..    ..::......::::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!++!!!!::::::::::::::..::**ee!!..::::!!!!!!!!!!::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++!!::::::::::!!::::**$$$$ee!!::::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++!!::::::::::!!::**@@$$$$$$::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!::::::::::::::!!ee$$@@@@!!..::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++!!::::::::::::::!!$$$$$$**!!::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++++!!::::::!!::::::::++$$$$**::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++!!!!::::!!!!!!!!::::**$$ee  ::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++!!!!::::!!!!!!::::::$$$$::..::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!!!!!::::!!!!::!!::!!$$**..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++!!::::::!!!!!!::....ee$$..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!::::..!!$$!!  ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++++!!!!!!!!++!!!!::....$$ee  ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"++++++++++++++++++++++++++!!++++++++++++++!!!!::!!**++!!!!::..**$$..  ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++++!!!!!!!!!!**!!!!!!..::!!    ..!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++++++++++++!!!!::!!++**!!!!::..        !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!++!!!!!!!!++++++++++++!!!!::!!!!**++!!::::        !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!++++++++++!!!!!!!!!!++++!!!!::..      !!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"++++++++++++++++++++!!!!!!++++!!!!++++++++++++!!!!::!!!!**!!!!!!::    ::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!++!!!!!!!!++++++++++!!!!::!!!!++++!!!!::..  ::::!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!++++++++++++++!!!!::!!!!++!!!!!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!++!!++!!!!!!!!++++++++++++!!::!!!!!!::::....::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++!!++++!!!!!!!!::!!::::::::::!!!!!!::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!++++++!!::::!!!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!++++!!!!::!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!::!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!::::::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::::::::::::::::!!!!!!!!!!++!!++++++!!!!!!!!!!!!::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::::::::::::..::::!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::::::::::::....::!!!!!!::::....::!!!!++++++!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::..  ::!!!!!!          ..!!++++++!!!!!!!!!!!!!!::::::::",
"++++++++++++++++++++++++++++++++!!!!!!!!!!::::!!::::..  ..!!!!!!..          ::!!++++++++!!!!!!!!!!::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::  ..::!!!!::..          !!++++++++++!!!!!!::::::::::",
"++++++++++++++++++++!!++++++!!!!!!!!!!!!!!!!::::::::::..  ::!!!!::::....      !!++++++++++!!!!!!!!::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::::..::****!!::::::::....!!++++++++++++!!!!::::::::::",
"++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!::::::::::::..::ee$$++::!!!!!!!!!!!!++++++++++++!!::::::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::!!!!::::::::::ee$$ee::!!::!!!!!!!!++++++++++!!!!::!!::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::!!::::::::::++$$**::::::!!!!!!!!!!!!!!!!!!!!::::::::!!::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::!!::::!!::::::::..!!ee!!::::::!!!!!!!!!!!!!!!!!!!!::::::::!!!!::",
"++++++++++++++++!!++!!++!!!!!!!!!!!!!!::!!::!!!!!!!!::........!!..::::::::!!::::::!!::::::::::!!!!::!!::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::....        ....::::::::::::::::::::::!!!!!!!!!!!!::!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::....          ........::::::::::::::::::::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..              ....::..::::::::::::::::::::::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!::....              ..::::::::::::::::::::::::::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++!!++++++!!!!!!!!!!!!::::..      ..          ....::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!::..        ..!!          ....::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!....      ..++          ....::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!::::::..    ::          ....::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!::::::::....          ....::::::::::!!::::!!::::!!!!!!!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!::::::!!::::          ..::::::::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::          ....::!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!::::::::!!::::..      ....::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!++!!::::::::::!!::::::..  ..::::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!++!!++++!!::::::::::::::::**!!  ..::::!!!!!!!!::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++++!!::::::::::::::::!!ee!!..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++++++!!::::::!!::::::::!!$$..::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++!!::::::!!!!::::::::**::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++!!!!::::::!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++!!!!::::!!!!!!!!::..  ::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++!!!!!!::!!!!++!!::..    ..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!::!!++!!!!::..    ::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!++!!!!::..    ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++++++!!!!!!!!++++!!!!::..  ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!++++!!!!!!::..  ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++++++++!!!!!!!!!!!!!!!!::..    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"++++++++++++++++++++++++++!!++!!++++++++++++++!!!!!!!!!!++++!!!!::    ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!++++++++++++!!!!!!!!!!!!**!!!!::..    !!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!++++++++++++++!!!!!!!!!!**!!!!!!::..  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!++++++++++++!!!!!!!!!!**++++!!!!..  ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!++++++++++++++!!!!!!!!++++!!!!!!....::!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"++++++++++++++++++++!!!!++++!!++!!!!!!!!++++++++++!!!!!!!!++!!!!!!!!....!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!++!!!!!!!!!!!!++++++++!!!!!!::!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!::::!!++++++!!!!!!::!!!!!!!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!++!!++!!!!!!!!::::::!!++++!!!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++!!++++!!!!!!!!::!!::::::!!!!!!!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!++++!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::!!!!++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!++!!!!::::!!!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!::::!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::!!!!::::::!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!::::::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::::::::!!::....::!!!!!!!!++!!++++++!!!!!!!!!!!!::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::....::!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++++!!!!!!!!::::::::::::::....::!!!!!!::........!!!!++++++!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::..  ..!!!!!!            !!++++++!!!!!!!!!!!!!!::::::::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!::::!!::::::....::!!!!..          ..!!++++++++!!!!!!!!!!::::::::",
"++++++++++++++++++++++++!!!!++!!!!!!!!!!!!!!::::::::::..  ..::!!::..          ::++++++++++!!!!!!!!::::::::",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::::....::::::::....      ::++++++++++!!!!!!!!::::::::",
"++++++++++++++++++++++!!++!!!!!!!!!!!!!!::::::::::::::::....!!**::::::::::::::!!++++++++++++!!::::!!::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::::::::::..!!$$++::::!!!!!!!!!!++++++++++++!!::::::::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::!!::!!!!::::::::....$$**::::!!!!!!!!!!!!++++++++!!!!::!!::::::::",
"++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!::::::!!::::::....  ++!!::::::!!!!!!!!!!!!++!!!!!!::::::::::::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::....      ......::::!!!!!!!!!!!!!!!!!!!!::::::!!!!!!::",
"++++++++++++++++!!++!!!!!!!!!!!!!!!!!!::!!::::....              ..::::::::!!::::::!!::::::::::!!!!::!!::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::..                  ......::::::::::::::::::::::!!!!!!!!!!::!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!!!::::....                  ........::::::::::::::::::::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!::::..                    ....::::::::::::::::::::::::::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!::..                    ..::::::::::::::::::::::::::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++!!++++++!!!!!!!!!!!!::....      !!          ....::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!::::::::..::            ..::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!::::::!!::::          ..  ..::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::        ......::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::!!::      ......::::::::!!::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::!!!!::        ..::::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!++!!!!++!!::::::::::::::..      ..::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!++++!!::::::::::::::::..    ....::!!!!!!!!!!::!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!++++++!!::::::!!::::::::..    ..::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!++!!++!!++++++!!!!::::!!!!::::::::    ..::!!!!!!!!::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++!!::::::!!!!!!!!::..    ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++++++++++!!::::::!!++!!::::    ::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!::::!!++!!!!::..  ..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!::!!!!!!!!!!::    ::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++++++++++++!!!!!!!!!!!!!!!!!!..  ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++!!++++++++++!!!!!!!!!!!!!!!!!!::  ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::..  ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!::!!!!!!!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++++++++++++++!!!!::::!!!!!!!!!!::..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!++++++++++++++!!!!!!::!!!!!!!!!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++!!!!++++++++++++++++!!!!!!!!++!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"++++++++++++++++++++++++++!!++!!++!!++++++++++++!!++!!!!!!!!++!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!++!!++!!!!!!!!++++++++++++!!!!!!!!!!++++!!++!!::!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!++++++++++!!!!!!!!!!++**++++!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!++++++++!!!!!!!!!!++++++++++::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!++!!!!!!!!::::!!++++++!!!!!!::!!!!!!!!++!!::::!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"++++++++++++++++++++!!!!++!!!!++!!!!!!::..::!!++++!!!!!!::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!::....::++++++!!!!::!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::  ..!!!!!!!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!++!!++!!!!!!!!!!!!::    !!!!!!!!::....::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++!!++++!!!!!!!!!!::::::::!!!!!!!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!++++!!!!::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::!!!!++++!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::!!!!!!!!!!::::!!!!!!!!!!!!!!::::::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::::::::!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::....::!!!!!!!!!!!!!!!!!!!!!!!!!!::::::!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::....::::!!!!!!++!!!!++++++!!!!!!!!!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::..  ::::!!!!!!::::::::!!!!!!++!!!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++!!++!!!!!!!!!!::::::::::::::::  ..::!!!!..        ..!!++++++!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::::::..  ::::!!            ..!!++++!!!!!!!!!!!!!!!!::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::!!::::::..  ..::::::            ::++++++++!!!!!!!!!!::::::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::..  ..::::::....      ..!!++++++++!!!!!!!!::::::::",
"++++++++++++++!!++!!!!!!!!!!!!!!::!!!!!!!!::::::::::::::::  ....::::::::::..  ..!!++++++++++!!!!!!::::::::",
"++++++++++++++!!!!!!++!!!!!!!!!!!!!!!!!!::::::::::::::::::..  ++::::::::::::::::!!++++++++++!!!!::!!::::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::::..  ++**..::::!!!!!!!!!!++++++++++!!::::::::::::",
"++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!::!!::::::::::......  ..!!..::::::!!!!!!++++++++++!!!!::!!::::::::",
"++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!::::::::::......        ....::::!!!!!!!!!!!!!!!!!!!!::::::::!!::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::....                ..::::::!!!!!!!!!!!!!!::::::!!::!!!!!!::",
"++++++++++++++++!!++!!++!!!!!!!!!!!!::::::..                    ......::::::::::::!!::::!!::::!!!!::!!::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!::::....                    ......::::::::::::::::::::!!!!!!!!!!!!::!!!!",
"++++++++++++++++++++!!!!!!!!!!!!::::..                    ..........::::::::::::::::::::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!::..          ..          ....::::::::::::::::::::::::::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!::::::::    ..::          ....::::::::::::::::::::::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++!!++++++++!!!!!!!!!!!!!!::::::::::::          ....::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!::::::!!::::            ..::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!::::!!::::..          ....::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::!!..      ......::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!++!!!!!!!!!!::::::::::::..    ......::::::!!::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!++++++!!::::::::::::::..    ....::::::::::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!++!!!!++++!!::::::::::::::::..    ..::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!++++++++!!::::::!!!!::::::    ....::!!!!!!!!::!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++++!!++++++++++++++++++++!!!!++++++++!!::::::!!!!::::::..    ..::!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!++++++++++!!::::::!!!!!!::::    ..::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++!!!!::::!!!!!!::::..  ..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++++++++++++++!!!!::::!!!!!!!!::    ::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++!!!!!!::!!!!!!!!!!..  ..!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::....!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++++++++!!!!!!::!!!!!!!!!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++++++++++++++++++!!!!!!!!!!!!!!!!!!::..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!++++++++++++!!!!!!!!!!!!!!!!!!::..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++++++++++++++++++++!!!!!!::!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!++++++++++++!!!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!++++++++++++!!!!!!!!!!++++!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++!!!!++!!++++++++++++!!!!!!!!++++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!++++++++++!!!!!!!!++**++++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!++!!++!!!!!!!!!!!!!!++++++++!!!!!!!!!!****++!!!!::!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!::::++++++++!!!!!!!!!!++**++++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!::..!!++++!!!!!!!!::!!!!++++++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::....!!!!++!!!!::::!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"++++++++++++++++++++!!++!!!!!!!!!!!!!!!!::..  ::!!!!++!!::::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::  ..!!++!!!!::..::::::::::!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..  ::!!!!::........::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::  ..::::::::..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::!!!!!!!!!!!!::::::::::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::!!!!!!!!!!::::::::!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::::!!!!!!!!!!!!!!!!::::::!!::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::::::::::!!!!!!!!!!!!!!::::::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!::::::::::::!!!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!::!!::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!::::::::::::::::....::!!!!!!!!!!!!++++++!!!!!!!!!!::::::!!!!!!!!!!::",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::::....::::!!!!!!!!!!!!!!++++!!++!!!!!!!!::!!!!!!!!::::",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::::..  ::::!!!!::::::::::++!!!!!!++!!!!!!!!!!!!!!!!::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::::::::..  ..::!!::          ..!!++!!++++!!!!!!!!!!!!::::::",
"++++++++++++++++++++++!!!!++!!!!!!!!!!::::::::::::::::::..  ::::..            ..++++++++++!!!!!!!!!!::::::",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::::::::..  ....::..            !!++++++++++!!!!!!::::::::",
"++++++++++++++++!!!!++++!!!!!!!!!!!!!!!!!!::::!!::::::::::    ....::......      ..++++++++++++++!!!!::::::",
"++++++++++++++++!!!!!!++!!!!!!!!!!!!::!!::::::!!::::::::::::    ....::::::::......!!++++++++++!!!!::::::::",
"++++++++++++++++++!!!!!!!!!!!!!!::!!!!!!::::::::::::::::::::  ..**....::::::::::::!!++++++++++!!!!::::::::",
"++++++++++++!!!!!!!!++!!!!!!!!!!!!!!!!!!::::::::::::::......    ee!!..::::::!!!!!!!!++++++++!!!!::::::::::",
"++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::::::::::....          !!::..::::::!!!!!!!!!!!!++!!!!::::::::::::",
"++++++++++++++++!!++++!!!!!!!!!!!!!!!!!!::::::....                ......::::!!!!!!!!!!!!!!::::::!!::::::::",
"++++++++++++++++++!!++!!!!!!!!!!!!!!!!::::....                    ......::::::::::::::::::::::::::::::::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!::::..      ..                ........::::::::::::::::::::!!::!!!!!!::",
"++++++++++++++++!!++!!!!!!!!!!!!!!::::                        ............::::::::::::::::::::!!!!::!!::::",
"++++++++++++++++++++!!!!!!!!!!!!!!::..        ..            ........::..::::::::::::::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++!!!!!!!!!!!!!!::::::::!!::..::            ....::::::::::::::::::::::::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::!!              ..::::::::::::::::::::::::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!::::::::::!!            ..::::::::::::::::::::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++!!++++++!!++!!!!!!!!!!!!!!!!::::::::::::..        ....::::::::::::::::::!!!!!!!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++!!++!!!!++!!!!!!::::::::::::::..      ....::::::::::::::::::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!++!!::::::::!!::::::..    ....::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!++!!!!!!!!!!++++!!::::!!!!!!::::::..    ....::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!++++++!!::::::!!!!::::::..  ....::::::::!!::::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!++++++++++!!!!::::::!!!!::::::    ..::!!!!::::!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!++++++++++!!::::!!!!++!!::::..  ..::!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!++!!++++++++!!!!!!::::!!!!!!!!::..  ::!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++!!++++++++++++++++++!!++++++++++++++++++!!!!!!::!!!!!!!!::..  ::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!++++++++++++++!!!!!!::::!!!!!!!!::....!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!++++++++++!!!!!!!!::!!!!!!!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!++!!++++++++++++++!!!!!!!!!!!!!!!!!!!!..::!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!++++++++++++++!!!!!!!!!!!!!!!!!!::::!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::..!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!++++++++++!!++!!++!!!!++++++++++++!!!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!++++!!++++++++++!!!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!!!!!++++++**++++!!++!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++!!++!!!!++++++++++!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++!!!!!!!!!!++++++++!!!!!!!!!!++++++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!++!!!!!!::!!++++++!!!!!!!!!!++++++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"++++++++++++++++++++++!!++!!++!!!!!!!!!!!!!!::!!++++++!!!!!!::!!!!++++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::..::++++!!!!!!::!!!!++++!!::!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::  ..!!!!!!!!!!::::!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!..  ::!!!!!!::..::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!::  ..!!!!!!::  ..::::::::!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!..  ::!!::::........::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++**++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::    ..::::..::::::::!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++**++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!    ..::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::..::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
,[
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::::::::!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::..::::!!!!!!!!!!!!::::::::::::::::::!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!::::::::::::::::::::::::::!!!!!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!!!!!::::::::::::::::::::..::::!!!!!!!!!!!!!!!!!!!!!!::::!!::::!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!!!!!::::::::::::::::::::..::::::!!!!!!!!++++++++++!!!!!!!!::::!!!!!!!!!!",
"++++++++++++++++++++++++!!++++!!!!!!!!::::::::::::::::::......::::!!!!!!!!!!!!++++!!!!++!!!!!!!!::!!!!!!::",
"++++++++++++++++++++!!!!!!++++!!!!!!!!::::::::::::::::::::....::::::....    ..::!!++++!!++++!!!!!!!!!!::::",
"++++++++++++++++++++++!!++!!!!!!!!!!!!::::::::::::::::::::..  ..::..            ::!!++++++++++!!!!!!!!::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::::::::::::..  ..::::              !!++++++++++!!!!!!!!::::",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::::::::::::::    ..::::..          ..!!++++++++++!!!!::::::",
"++++++++++++!!!!++++++!!!!!!!!!!!!!!!!!!::::::::::::::::::::..  ......::::....      !!++++++++++++!!::::::",
"++++++++++++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!::::!!::::::::::::..  **!!  ..::::::::::::!!++++++++++!!!!::::::",
"++!!++++++++++++!!!!!!!!!!!!!!!!!!!!::!!::::::::::::::::::....  ++$$::..::::::!!!!!!!!!!++++++++!!::::::::",
"++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!::::::::::::......  ..  ::$$++..::::::::!!!!!!++++++++!!!!::::::::",
"++!!!!++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::::....              ..$$::..::::::!!!!!!!!!!!!!!!!!!::!!::::::",
"++++++++++++!!!!!!!!!!!!!!!!!!!!!!!!!!::::..                ..    **......::::::::::!!::::::::::!!::::::::",
"++++++++++++++++++++!!!!!!!!!!!!!!::::....      ..      ......  ............::::::::::::::::::::!!::::::::",
"!!!!++++++++++++++!!++!!!!!!!!!!!!!!::        ..            ............::::::::::::::::::::::::::::::::::",
"++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::..  ..            ..............::::::::::::::::::::!!::!!::!!::",
"++++!!++++!!++++!!++!!!!!!!!!!!!!!!!!!::::::::::..            ......::....::::::::::::::::::::!!!!::!!::!!",
"++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::::::::::            ......::::::::::::::::::::!!!!::!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::::::..          ..::::::::::::::::::::::::!!::!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::::::::::::..        ....::::::::::::::::::::!!::!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::::::::::::::::    ......::::::::::::::::!!::!!!!!!!!!!!!!!!!!!",
"++++++++++++!!++++++++++++++++!!!!!!!!++!!!!::::::::!!::::::    ....::::::::::::::::!!!!::!!::!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++++++!!!!++++!!!!::::::!!!!::::::  ......::::::::::::::::!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++!!!!!!++++++!!!!!!!!++++!!::::::!!!!!!::::::    ..::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!++++++++!!!!::::!!!!!!!!::::..  ..::::::!!::!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!++!!!!++++++++++!!::::::!!!!!!!!!!::....::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!++++++++++++!!!!::!!!!!!!!!!!!::....!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!++!!!!++++++++++++++!!!!!!::::!!!!!!!!::....!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++!!!!!!!!++!!++++++++++++!!!!!!::!!!!!!!!!!::..::!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!",
"++++++!!++++++++++++++++++!!++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++!!!!!!++++++++++++!!!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++!!++!!++++++++++!!!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++!!++!!!!++++++++++!!++++++++++++!!!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!++++!!!!++++++++++!!!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++++++++++!!!!!!++++**++!!!!!!!!!!!!!!!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++++++!!!!!!++++**++++++!!!!!!!!!!!!!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++!!++++++++++!!++++++!!!!!!!!!!!!++++++++++++!!!!!!++++!!!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!++++++++!!!!!!!!++!!++!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!++++!!!!!!!!!!::::++++++++!!!!!!!!++!!++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!::..!!++++!!!!!!!!!!!!++++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::....!!++!!!!!!!!::!!++++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!!!..  ::!!++!!!!::::!!!!++!!::!!!!!!!!!!!!!!!!!!!!!!!!!!!!++",
"++++++++++++++++++++++++++!!++!!!!!!!!!!!!!!!!!!::  ..::!!!!!!::..::!!!!::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++**++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!..  ..!!!!::..    ::::::!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!::    ::::::        ..!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!!!!!..    ..........::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++++++++++++++++++++++!!!!!!!!!!!!!!!!!!::    ....::::::::::!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!",
"++++++++++++++++++++++++++++++++++!!!!++!!!!!!!!!!!!::....::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"**++++++++++++**++++++++++++++++++++++!!!!!!!!!!!!!!::::::::::::::!!!!!!!!!!!!!!!!!!!!!!!!!!++!!!!!!!!!!!!",
"**++++********++++****++++++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
"++++++++************++****++++++++++++++++++!!!!!!!!!!::::::!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"]
]
